import { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { Requisicao } from '../models';
import { DataGrid } from '@mui/x-data-grid';
import { useFindRequisicoesMutation } from '../apiSlice';

import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import SelectFilter from '../../../components/filters/selectFilter';
import { CustomNoTableRowsOverlay } from '../../../components/NoCustomRowOverlay';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  px: 1,
  py: 1,
  borderRadius: 1,
  pb: 4
};

interface Props {
  action?: (requisicao: Requisicao) => void;
  initialArgs?: Record<string, any>;
  buttonIcon?: boolean;
  disabled?: boolean;
}

export default function SearchRequisicaoModal({
  action,
  initialArgs = {},
  buttonIcon = false,
  disabled = false
}: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [searchFor, setSearchFor] = useState<string>('');

  const [data, setData] = useState<Requisicao[]>([]);
  const [findRequisicoes, status] = useFindRequisicoesMutation();

  // filtro classes
  const [filter, setFilter] = useState<string[]>([]);
  const [dataClasses, setDataClasses] = useState<any[]>([]);
  useEffect(() => {
    if (data) {
      const classes = data
        .map((el) => el.produtoServico?.classe && el.produtoServico.classe)
        .filter((el) => el !== null)
        .reduce((acc: any, cur: any) => {
          if (cur === null) return acc;
          const found = acc.find((el: any) => el?.id === cur?.id);
          if (!found) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .filter((el: any) => el);
      setDataClasses(classes ?? []);
    }
  }, [data]);

  function mapDataToGridRows(data: Requisicao[]) {
    return data
      .map((d) => ({
        id: d.id,
        tipoReq: d.tipoReq,
        createdAt: d.createdAt
          ? new Date(d.createdAt).toLocaleDateString('pt-BR')
          : '',
        solicitante: d.solicitante?.nome,
        departamento: d.departamento?.nome,
        codigoSgi: d.produtoServico?.codigoSgi,
        descricaoProdutoServico: d.descricaoProdutoServico,
        unidade: d.unidade,
        qtde: d.qtde,
        situacao: d.situacao,
        prioridade: d.prioridade,
        obs: d.obs,
        updatedAt: d.updatedAt,
        classe: d.produtoServico?.classe
      }))
      .filter((el: any) =>
        filter.length > 0 ? filter.includes(el?.classe?.id) : true
      );
  }

  function CustomPagination() {
    // const apiRef = useGridApiContext();
    // const rows = useGridSelector(apiRef, gridRowCountSelector);

    return (
      <Box
        minWidth={'96%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={2}
        mx={2}
        px={0}>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Typography fontSize={12}>Qtde: {data.length ?? 0}</Typography>
        </Box>
      </Box>
    );
  }

  const find = useCallback(async () => {
    const params = {
      take: -1,
      skip: 1,
      orderBy: '',
      sort: '1',
      body: { ...initialArgs, searchFor }
    };
    const { requisicoes } = await findRequisicoes(params).unwrap();
    if (requisicoes) {
      setData(requisicoes);
    }
  }, [findRequisicoes, initialArgs, searchFor]);

  useEffect(() => {
    if (open) {
      find();
    }
  }, [open, find]);

  return (
    <Box>
      {buttonIcon ? (
        <IconButton
          disabled={disabled}
          onClick={handleOpen}
          title="Pesquisar Requisições">
          <SearchIcon />
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          startIcon={<Add />}
          variant="contained"
          size="small"
          color="primary"
          onClick={handleOpen}>
          Requisições
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        inputMode="search"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            Consulta Requisições
          </Typography>
          <Box display="grid" gridTemplateColumns={'3fr 1fr'} gap={4} mb={1}>
            <Box display="flex" justifyContent="end">
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  fullWidth
                  multiline={false}
                  ref={ref}
                  inputRef={ref}
                  autoFocus={true}
                  placeholder="Buscar requisição…"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => setSearchFor(e.target.value)}
                  value={searchFor}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      find();
                    }
                  }}
                />
              </Search>
            </Box>
            <Box display="flex" justifyContent="end">
              <SelectFilter
                dataKey="classeId"
                value={filter ?? []}
                data={dataClasses
                  .filter((c) => c?.ativo === 1)
                  .map((el) => ({
                    id: el.id,
                    desc: el.descricao.toUpperCase()
                  }))}
                onChange={(value) => {
                  setFilter([...value.classeId]);
                  // const newBody = body;
                  // if (value.classeId) {
                  //   setBody({ ...newBody, classeId: value.classeId });

                  // } else {
                  //   delete newBody.classeId;
                  //   setBody(newBody);
                  // }
                }}
                caption="Selecionar Classes"
                width={200}
              />
            </Box>
          </Box>
          <Box height={'55vh'}>
            <Typography
              variant="body2"
              sx={{ ml: 1, fontSize: 13, color: '#ff0000' }}>
              (*) Para selecionar um requisicao, clique duas vezes sobre o
              registro.
            </Typography>
            <DataGrid
              editMode="row"
              sx={{
                '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  fontSize: 13,
                  color: '#ffffff',
                  backgroundColor: 'primary.main'
                },
                fontSize: 13,
                backgroundColor: '#ffffff'
              }}
              loading={status.isLoading}
              // paginationMode="server"
              rows={data ? mapDataToGridRows(data) : []}
              density="compact"
              columns={[
                {
                  field: 'id',
                  headerName: 'ID',
                  width: 15,
                  sortable: false,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                // {
                //   field: 'tipoReq',
                //   headerName: 'Tipo',
                //   width: 60,
                //   sortable: true,
                //   editable: false,
                //   filterable: false,
                //   hideable: false,
                //   disableColumnMenu: true,
                //   renderCell: (params) => {
                //     if (params.row.tipoReq === 'P') {
                //       return <QrCode2Icon titleAccess="Produto" />;
                //     }

                //     if (params.row.tipoReq === 'S') {
                //       return <ConstructionIcon titleAccess="Serviço" />;
                //     }
                //   }
                // },
                {
                  field: 'createdAt',
                  headerName: 'Data',
                  width: 80,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'codigoSgi',
                  headerName: 'Código SGI',
                  width: 80,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'descricaoProdutoServico',
                  headerName: 'Produto/Serviço',
                  flex: 2,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'classe',
                  headerName: 'Classe',
                  width: 100,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: true,
                  disableColumnMenu: true,
                  valueFormatter: ({ value }) => `${value?.descricao ?? ''}`
                },
                {
                  field: 'solicitante',
                  headerName: 'Solicitante',
                  flex: 1,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'departamento',
                  headerName: 'Destino',
                  flex: 1,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'unidade',
                  headerName: 'Und',
                  width: 40,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'qtde',
                  headerName: 'Qtde',
                  align: 'center',
                  width: 60,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'situacao',
                  headerName: 'Situação',
                  width: 90,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true,
                  valueFormatter: ({ value }) => `${value.nome}`,
                  renderCell: (params) => {
                    return (
                      <Typography
                        fontSize={10}
                        sx={(theme) => ({
                          color: params.row.situacao?.corLetra || '#000000',
                          background: params.row.situacao?.corFundo,
                          py: theme.spacing(0.5),
                          px: theme.spacing(1.5),
                          borderRadius: theme.shape.borderRadius
                        })}>
                        {params.row.situacao?.nome}
                      </Typography>
                    );
                  }
                },
                {
                  field: 'prioridade',
                  headerName: 'Prioridade',
                  width: 90,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true,
                  valueFormatter: ({ value }) => `${value.nome}`,
                  sortComparator: (v1, v2) =>
                    v1.prioridade.nome.localeCompare(v2.prioridade?.nome),
                  renderCell: (params) => {
                    return (
                      <Typography
                        fontSize={10}
                        sx={(theme) => ({
                          color: params.row.prioridade?.corLetra || '#000000',
                          background: params.row.prioridade?.corFundo,
                          py: theme.spacing(0.5),
                          px: theme.spacing(1.5),
                          borderRadius: theme.shape.borderRadius
                        })}>
                        {params.row.prioridade?.nome}
                      </Typography>
                    );
                  }
                },
                {
                  field: 'obs',
                  headerName: 'Obs',
                  flex: 1,
                  sortable: true,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                }
                // {
                //   field: 'id',
                //   headerName: 'Ações',
                //   width: 100,
                //   disableColumnMenu: true,
                //   sortable: false,
                //   disableExport: true,
                //   renderCell: RenderActionsCell
                // }
              ]}
              pageSize={6}
              onRowDoubleClick={(e) => {
                if (action) {
                  action(e.row as Requisicao);
                }
                setData([]);
                handleClose();
              }}
              onCellKeyDown={({ row }, { key }) => {
                if (key === 'Enter' && action) {
                  action(row as Requisicao);
                  handleClose();
                }
              }}
              paginationMode="server"
              rowCount={data ? data.length : 0}
              components={{
                NoRowsOverlay: CustomNoTableRowsOverlay,
                Pagination: CustomPagination
              }}
              hideFooterSelectedRowCount
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.primary.main, 0.25)
  // },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  },
  // marginBottom: theme.spacing(2),
  border: '1px solid #ccc',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  color: 'inherit',
  flexGrow: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width')
    // backgroundColor: theme.palette.background.default
    // [theme.breakpoints.up('sm')]: {
    //   width: '12ch',
    //   '&:focus': {
    //     width: '20ch'
    //   }
    // }
  },
  variant: 'outlined'
}));
