import { Paper, Box, Typography, Chip, alpha, IconButton } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColumnVisibilityModel,
  GridColumns,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@mui/x-data-grid';
// import { GridToolbar } from '@mui/x-data-grid/components';
import AppConfig from '../../config/app';
import { useNavigate } from 'react-router-dom';
import { Requisicao } from '../requisicao/models';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LoadingButton } from '@mui/lab';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { CustomNoTableRowsOverlay } from '../../components/NoCustomRowOverlay';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { writeFile, utils } from 'xlsx';
import { GetApp } from '@mui/icons-material';
import { useEmpresa } from '../../hooks/useEmpresa';

interface Props {
  data: Requisicao[];
  isLoading: boolean;
  count: number;
  selectedRows?: (selectedRowsData: Requisicao[]) => void;
  handlerAprovar?: () => void;
  handlerDesaprovar?: () => void;
  isAprovacao?: boolean;
}

export default function TableAprovacaoNotPaginate({
  data,
  isLoading,
  count,
  selectedRows,
  handlerAprovar,
  handlerDesaprovar,
  isAprovacao = true
}: Props) {
  const navigate = useNavigate();
  const { qtdeDigitosCampoQtde, qtdeDigitosCampoUnitário } = useEmpresa();

  function mapDataToGridRows(data: Requisicao[]) {
    return data.map((d) => ({
      id: d.id,
      tipoReq: d.tipoReq,
      createdAt: d.createdAt
        ? new Date(d.createdAt).toLocaleDateString('pt-BR')
        : '',
      solicitante: d.solicitante?.nome,
      departamento: d.departamento?.nome,
      codigoSgi: d.produtoServico?.codigoSgi,
      descricaoProdutoServico: d.descricaoProdutoServico,
      classe: d.produtoServico?.classe?.descricao,
      unidade: d.unidade,
      qtde: d.qtde,
      situacao: d.situacao,
      prioridade: d.prioridade,
      obs: d.obs,
      ultimaCompra:
        d.ultimaCompra && d.ultimaCompra?.length > 0 ? d.ultimaCompra[0] : null
    }));
  }

  const columns: GridColumns = [
    {
      field: '__check__',
      hideable: false,
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      disableExport: true,
      headerName: 'Selecionar'
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 10,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    // {
    //   field: 'tipoReq',
    //   headerName: 'Tipo',
    //   width: 40,
    //   sortable: true,
    //   editable: false,
    //   filterable: false,
    //   hideable: false,
    //   disableColumnMenu: true,
    //   renderCell: (params) => {
    //     if (params.row.tipoReq === 'P') {
    //       return <QrCode2Icon titleAccess="Produto" />;
    //     }

    //     if (params.row.tipoReq === 'S') {
    //       return <ConstructionIcon titleAccess="Serviço" />;
    //     }
    //   }
    // },
    {
      field: 'createdAt',
      headerName: 'Data',
      width: 90,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'codigoSgi',
      headerName: 'Código SGI',
      width: 80,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'descricaoProdutoServico',
      headerName: 'Produto/Serviço',
      minWidth: 300,
      flex: 1,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true

      // renderCell: (params) => (
      //   <Typography fontSize={10} color={'primary'} noWrap>
      //     {params.value}
      //   </Typography>
      // )
    },
    {
      field: 'classe',
      headerName: 'Classe',
      width: 100,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'ultimaCompra',
      headerName: 'Última Compra',
      width: 400,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      renderCell: (params: any) =>
        params.row.ultimaCompra ? (
          <Box
            display={'grid'}
            gap={1}
            gridTemplateColumns={'1fr 1fr 2fr'}
            alignItems={'center'}>
            <Chip
              icon={<CalendarMonthIcon />}
              sx={{ fontSize: 9 }}
              size="small"
              label={new Date(
                String(params.row.ultimaCompra?.entrada).replace('-', '/')
              ).toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              })}
            />

            <Chip
              sx={{ fontSize: 9 }}
              size="small"
              label={Number(params.row.ultimaCompra?.valor).toLocaleString(
                'pt-BR',
                {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: qtdeDigitosCampoUnitário,
                  maximumFractionDigits: qtdeDigitosCampoUnitário
                }
              )}
            />

            <Typography fontSize={9} color="secondary" noWrap>
              {params.row.ultimaCompra?.fornecedor?.nome}
            </Typography>
          </Box>
        ) : (
          <Typography fontSize={9}>Sem Compras</Typography>
        ),
      valueFormatter: ({ value }: { value: any }) => {
        if (!value) return 'Sem Compras';
        return new Date(String(value.entrada).replace('-', '/'))
          .toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
          .concat('-')
          .concat(
            Number(value.valor).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            })
          )
          .concat('-')
          .concat(value.fornecedor?.nome);
      }
    },
    // {
    //   field: 'id',
    //   headerName: 'Última Compra',
    //   width: 100,
    //   sortable: true,
    //   editable: false,
    //   filterable: false,
    //   hideable: true,
    //   disableColumnMenu: true,
    //   renderCell(params) {
    //     return;
    //   },
    //   valueFormatter: ({}) =>

    // },
    // {
    //   field: 'ultimaCompra',
    //   headerName: 'Último Valor',
    //   width: 100,
    //   sortable: true,
    //   editable: false,
    //   filterable: false,
    //   hideable: true,
    //   disableColumnMenu: true,
    //   valueFormatter: ({ value }) =>
    //     Number(value.valor).toLocaleString('pt-BR', {
    //       style: 'currency',
    //       currency: 'BRL'
    //     })
    // },
    // {
    //   field: 'solicitante',
    //   headerName: 'Solicitante',
    //   width: 100,
    //   sortable: true,
    //   editable: false,
    //   filterable: false,
    //   hideable: false,
    //   disableColumnMenu: true
    // },
    {
      field: 'departamento',
      headerName: 'Destino',
      width: 100,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'unidade',
      headerName: 'Und',
      width: 60,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'qtde',
      headerName: 'Qtde',
      align: 'center',
      width: 60,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      // campo:qtdeDigitosCampoQtde
      valueFormatter(params) {
        return `${params.value.toLocaleString('pt-BR', {
          minimumFractionDigits:
            qtdeDigitosCampoQtde > 2 ? qtdeDigitosCampoQtde : 0,
          maximumFractionDigits: qtdeDigitosCampoQtde || 0
        })}`;
      }
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      width: 100,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      valueFormatter: ({ value }: { value: any }) => `${value.nome}`,
      renderCell: (params: any) => {
        return (
          <Typography
            fontSize={10}
            sx={(theme) => ({
              color: params.row.situacao?.corLetra || '#000000',
              background: params.row.situacao?.corFundo,
              py: theme.spacing(0.5),
              px: theme.spacing(1.5),
              borderRadius: theme.shape.borderRadius
            })}>
            {params.row.situacao?.nome}
          </Typography>
        );
      }
    },
    {
      field: 'prioridade',
      headerName: 'Prioridade',
      width: 90,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      valueFormatter: ({ value }: { value: any }) => `${value.nome}`,
      sortComparator: (v1: any, v2: any) =>
        v1.prioridade.nome.localeCompare(v2.prioridade.nome),
      renderCell: (params: any) => {
        return (
          <Typography
            fontSize={10}
            sx={(theme) => ({
              color: params.row.prioridade?.corLetra || '#000000',
              background: params.row.prioridade?.corFundo,
              py: theme.spacing(0.5),
              px: theme.spacing(1.5),
              borderRadius: theme.shape.borderRadius
            })}>
            {params.row.prioridade?.nome}
          </Typography>
        );
      }
    }
    // {
    //   field: 'obs',
    //   headerName: 'Obs',
    //   width: 100,
    //   sortable: true,
    //   editable: false,
    //   filterable: false,
    //   hideable: false,
    //   disableColumnMenu: true
    // }
    // {
    //   field: 'id',
    //   headerName: 'Ações',
    //   width: 100,
    //   disableColumnMenu: true,
    //   sortable: true,
    //   disableExport: true,
    //   renderCell: RenderActionsCell
    // }
  ];

  function CustomPagination() {
    // const apiRef = useGridApiContext();
    // const rows = useGridSelector(apiRef, gridRowCountSelector);

    return (
      <Box
        minWidth={'96%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={2}
        mx={2}
        px={0}>
        {/* <Typography>{selectedRows?.length} Registro(s)</Typography> */}
        {!!isAprovacao && (
          <LoadingButton
            loading={isLoading}
            onClick={handlerAprovar}
            variant="contained"
            color="success"
            size="small"
            startIcon={<DoneAllIcon />}>
            Aprovar
          </LoadingButton>
        )}

        {!isAprovacao && (
          <LoadingButton
            loading={isLoading}
            onClick={handlerDesaprovar}
            variant="contained"
            color="warning"
            size="small"
            startIcon={<DoneAllIcon />}>
            Desaprovar
          </LoadingButton>
        )}

        <Typography fontSize={12}>Qtde: {count}</Typography>
      </Box>
    );
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>(
      'columnsRequisicaoAprovacaoVisibles',
      {
        id: true,
        tipoReq: true,
        createdAt: true,
        descricaoProdutoServico: true,
        codigoSgi: true,
        classe: true,
        solicitante: true,
        departamento: true,
        unidade: true,
        qtde: true,
        situacao: true,
        prioridade: true,
        acoes: true
      }
    );

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ py: 1 }}>
        <GridToolbarColumnsButton variant="text" />
        {/* <GridToolbarExport variant="text" /> */}
        <IconButton onClick={handleExport}>
          <GetApp color="primary" />
        </IconButton>
      </GridToolbarContainer>
    );
  }

  const mapToExcel = data.map((d) => {
    return {
      Requisição: d.id,
      Tipo: d.tipoReq === 'P' ? 'Produto' : 'Serviço',
      Data: d.createdAt
        ? new Date(d.createdAt).toLocaleDateString('pt-BR')
        : '',
      Solicitante: d.solicitante?.nome,
      Departamento: d.departamento?.nome,
      'Código Sgi': d.produtoServico?.codigoSgi,
      Descrição: d.descricaoProdutoServico,
      Classe: d.produtoServico?.classe?.descricao,
      Unidade: d.unidade,
      Qtde: d.qtde,
      Situação: d.situacao?.nome,
      Prioridade: d.prioridade?.nome,
      Obs: d.obs,
      'Última Compra':
        d.ultimaCompra && d.ultimaCompra?.length > 0
          ? new Date(d.ultimaCompra[0]?.entrada).toLocaleDateString('pt-BR') +
            ' | ' +
            Number(d.ultimaCompra[0]?.valor).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }) +
            ' | ' +
            d.ultimaCompra[0]?.fornecedor?.nome
          : ''
    };
  });

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'CotacaoAprovacao.xlsx');
  }

  return (
    <Paper>
      <Box height="78vh">
        <DataGrid
          sx={(style) => ({
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              fontSize: 11,
              backgroundColor: style.palette.secondary.main,
              color: style.palette.secondary.contrastText
            },
            '& .MuiDataGrid-columnSeparator': {
              color: style.palette.secondary.contrastText
            },
            fontSize: 13,
            overflow: 'hidden',
            '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
              color: style.palette.secondary.contrastText,
              borderRadius: 0
            },
            '& .MuiDataGrid-columnHeaders': {
              borderRadius: 0
            },
            '& .super-app-theme--true': {
              bgcolor: (theme) => alpha(theme.palette.success.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.success.main
              }
            },
            '& .super-app-theme--false': {
              bgcolor: (theme) => alpha(theme.palette.warning.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.warning.main
              }
            },
            '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
              opacity: 1,
              color: style.palette.secondary.contrastText
            }
          })}
          loading={isLoading}
          paginationMode="server"
          rows={data ? mapDataToGridRows(data) : []}
          density="compact"
          columns={columns}
          disableColumnFilter
          disableDensitySelector
          disableColumnMenu
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
            // LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoTableRowsOverlay
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                delimiter: ';',
                utf8WithBom: true,
                fileName: 'sistema-cotação'
              }
            },
            footer: {
              sx: {
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText',
                '& .MuiPaginationItem-root': {
                  fontSize: 12
                },
                '& *': {
                  color: 'secondary.contrastText'
                }
              }
            }
          }}
          localeText={{
            toolbarColumnsLabel: 'Colunas',
            toolbarColumns: '',
            toolbarExport: '',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Exportar para CSV',
            toolbarExportPrint: 'Imprimir',
            detailPanelToggle: 'Expandir',
            columnsPanelHideAllButton: 'Ocultar tudo',
            columnsPanelShowAllButton: 'Mostrar tudo',
            columnsPanelTextFieldLabel: 'Localizar coluna',
            columnsPanelTextFieldPlaceholder: 'Título da coluna'
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          hideFooterSelectedRowCount
          rowCount={count}
          checkboxSelection
          disableSelectionOnClick
          isRowSelectable={(params: GridRowParams) =>
            isAprovacao
              ? params.row.situacao.id === 1
              : params.row.situacao.id === 2
          }
          onSelectionModelChange={(ids) => {
            if (!selectedRows) return;
            const sets = ids.map((id) => Number(id));
            const selectedIDs = new Set(sets);
            if (!data) return;
            const selectedRowsData = data.filter((row) => {
              if (row.id) return selectedIDs.has(row.id);
            });

            selectedRows(selectedRowsData);
          }}
          onRowDoubleClick={(params: GridRowParams) =>
            navigate(AppConfig.routes.cadRequisicaoEdit(params.id as string), {
              state: { goBack: AppConfig.routes.cadAprovacao }
            })
          }
          onCellKeyDown={(
            params: GridCellParams,
            event: React.KeyboardEvent
          ) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              navigate(
                AppConfig.routes.cadRequisicaoEdit(params.id as string),
                {
                  state: { goBack: AppConfig.routes.cadAprovacao }
                }
              );
            }
          }}
        />
      </Box>
    </Paper>
  );
}
