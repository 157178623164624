import { NavigateNext } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { SolicitanteForm } from './form';
import {
  formSolicitanteValidationSchemaInsert,
  SOLICITANTE_INITIAL_STATE
} from './models';
import { useCreateSolicitanteMutation } from './apiSlice';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function SolicitanteInsert() {
  const { enqueueSnackbar } = useSnackbar();
  const [createCategory, status] = useCreateSolicitanteMutation();

  const formik = useFormik({
    initialValues: SOLICITANTE_INITIAL_STATE,
    validationSchema: formSolicitanteValidationSchemaInsert,
    onSubmit: (values) => {
      delete values.id;
      createCategory(values);
    }
  });

  useEffect(() => {
    if (status.isSuccess) {
      enqueueSnackbar('Solicitante criado com sucesso', { variant: 'success' });
      formik.resetForm();
    }
    if (status.error) {
      enqueueSnackbar('Error ao criar Solicitante', { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, status.error, status.isSuccess]);

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadSolicitante}>
              <Typography>Solicitantes</Typography>
            </Link>
            <Typography>Inserir</Typography>
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadSolicitante}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <SolicitanteForm isLoading={status.isLoading} formik={formik} />
        </Box>
      </form>
    </Inner>
  );
}
