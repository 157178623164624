import { NavigateNext } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useNavigate } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { FornecedorForm } from './form';
import {
  formFornecedorValidationSchemaInsert,
  Fornecedor,
  FORNECEDOR_INITIAL_STATE
} from './models';
import { useCreateFornecedorMutation } from './apiSlice';
import { useSnackbar } from 'notistack';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function FornecedorInsert() {
  const { enqueueSnackbar } = useSnackbar();
  const [createFornecedor, status] = useCreateFornecedorMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: FORNECEDOR_INITIAL_STATE,
    validationSchema: formFornecedorValidationSchemaInsert,
    onSubmit: (values) => {
      const newValue: Record<string, any> = { ...values };
      delete newValue.id;
      delete newValue?.createdAt;
      delete newValue?.updatedAt;
      if (!newValue.email) {
        delete newValue.email;
      }

      if (!newValue.telefone) {
        delete newValue.telefone;
      }

      createFornecedor(newValue as Fornecedor)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Fornecedor inserido com sucesso', {
            variant: 'success'
          });
          navigate(AppConfig.routes.cadFornecedor);
        })
        .catch((error) => {
          enqueueSnackbar(error.data.message, {
            variant: 'error'
          });
        });
    }
  });

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadFornecedor}>
              <Typography>Fornecedors</Typography>
            </Link>
            <Typography>Inserir</Typography>
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadFornecedor}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <FornecedorForm isLoading={status.isLoading} formik={formik} />
        </Box>
      </form>
    </Inner>
  );
}
