import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Switch,
  TextField
} from '@mui/material';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { User } from './models';
import { FormikProps } from 'formik';
// import MaskedTextField from '../../components/MaskedField';
import { MuiTelInput } from 'mui-tel-input';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../auth/authSlice';
import Permissoes from './permissoes';
import ModalAlrerarSenha from './modalAlrerarSenha';
import { useUpdatePasswordMutation } from './usersSlice';
import { useSnackbar } from 'notistack';

type Props = {
  formik: FormikProps<User>;
  isLoading?: boolean;
  reset: () => void;
  applyAll: () => void;
};

export function UserForm({
  formik,
  reset,
  applyAll,
  isLoading = false
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(selectCurrentUser);
  function isAdmin() {
    return user.perfil === 'ADMIN';
  }

  const [updatePassword] = useUpdatePasswordMutation();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  function handleUpdatePassword(senha: string) {
    const { id, email, updatedAt } = formik.values;
    if (!id) {
      return;
    }
    updatePassword({
      data: {
        id,
        email,
        senha,
        updatedAt: updatedAt as unknown as string
      },
      type: isAdmin() ? 'admin' : 'user'
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Senha alterada com sucesso!', {
          variant: 'success'
        });
      })
      .catch((err) => {
        enqueueSnackbar('Erro ao alterar senha!', {
          variant: 'error'
        });
      });
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={3}>
          {/* nome completo */}
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.nome && formik.errors.nome)}>
              <TextField
                required
                name="nome"
                label="Nome Completo"
                disabled={isLoading}
                inputProps={{ 'data-testid': 'nome' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nome}
              />
              {formik.touched.nome && formik.errors.nome && (
                <FormHelperText error id="error-nome">
                  {formik.errors.nome}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* email */}
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.email && formik.errors.email)}>
              <InputLabel htmlFor="teste">Email *</InputLabel>
              <OutlinedInput
                required={true}
                defaultValue=""
                type="email"
                label="email *"
                autoComplete="false"
                inputProps={{ 'aria-label': 'description' }}
                value={formik.values.email}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => formik.setFieldValue('email', e.target.value)}
                onBlur={formik.handleBlur}
              />

              {/* <TextField
                required
                defaultValue=""
                name="loginEmail"
                autoComplete="false"
                inputProps={{
                  role: 'presentation',
                  'aria-autocomplete': 'none',
                  autoComplete: 'off'
                }}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              /> */}
              {formik.touched.email && formik.errors.email && (
                <FormHelperText error id="error-email">
                  {formik.errors.email}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* telefone/celular */}
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={Boolean(
                formik.touched.telefone && formik.errors.telefone
              )}>
              {/* <InputLabel htmlFor="cel">Celular</InputLabel> */}
              <MuiTelInput
                required
                id="telefone"
                name="telefone"
                label="Telefone"
                placeholder="Telefone"
                // mask={[{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }]}
                disabled={isLoading}
                onChange={(newValue, info) =>
                  formik.setFieldValue('telefone', info.numberValue)
                }
                onBlur={formik.handleBlur}
                value={formik.values.telefone}
                onlyCountries={['BR']}
                defaultCountry={'BR'}
              />
              {formik.touched.telefone && formik.errors.telefone && (
                <FormHelperText error id="error-telefone">
                  {formik.errors.telefone}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* senha */}
          {formik?.values?.id ? (
            <Grid item xs={12}>
              <ModalAlrerarSenha action={handleUpdatePassword} />
            </Grid>
          ) : (
            <>
              {/* inserir senha usuario novo */}
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  required
                  error={Boolean(formik.touched.senha && formik.errors.senha)}>
                  <InputLabel htmlFor="senha">Senha</InputLabel>
                  <OutlinedInput
                    id="senha"
                    name="senha"
                    label="Senha"
                    inputProps={{ autoComplete: 'new-passord' }}
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.senha}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.senha && formik.errors.senha && (
                    <FormHelperText error id="error-senha">
                      {formik.errors.senha}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth>
                  <InputLabel htmlFor="senha">Confirmar Senha</InputLabel>
                  <OutlinedInput
                    id="senha2"
                    name="senha2"
                    label="Confirmar Senha"
                    inputProps={{ autoComplete: 'new-passord' }}
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    value={formik.values.senha2}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.senha && formik.errors.senha && (
                    <FormHelperText error id="error-senha">
                      {formik.errors.senha}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </>
          )}

          {/* perfil e permissoes */}
          {isAdmin() && (
            <>
              {/* perfil */}
              <Grid
                display={'flex'}
                justifyContent={'space-between'}
                item
                xs={12}>
                {isAdmin() && (
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.perfil && formik.errors.perfil
                    )}>
                    <FormLabel id="perfil">Perfil</FormLabel>
                    <RadioGroup
                      aria-label="perfil"
                      name="perfil"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.perfil}
                      aria-labelledby="perfil"
                      defaultValue="USER">
                      <FormControlLabel
                        disabled={isLoading}
                        value="USER"
                        control={<Radio />}
                        label="Usuário"
                      />
                      <FormControlLabel
                        disabled={isLoading}
                        value="ADMIN"
                        control={<Radio />}
                        label="Administrador"
                      />
                    </RadioGroup>
                    {formik.touched.perfil && formik.errors.perfil && (
                      <FormHelperText error id="error-perfil">
                        {formik.errors.perfil}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isAtivo"
                        color="primary"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.isAtivo}
                        inputProps={{ 'aria-label': 'controlled' }}
                        data-testid="isAtivo"
                        disabled={isLoading}
                      />
                    }
                    label="Ativo"
                  />
                </FormGroup>
              </Grid>
              {/* permissoes */}
              <Grid display={'flex'} justifyContent={'center'} item xs={12}>
                <Permissoes formik={formik} reset={reset} applyAll={applyAll} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Paper>
  );
}
