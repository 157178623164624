/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
  Select,
  TextField,
  MenuItem,
  TextareaAutosize,
  Paper
} from '@mui/material';
import { Requisicao } from '../models';
import { FormikProps } from 'formik';
import { useGetSolicitantesQuery } from '../../solicitante/apiSlice';
import { useGetDepartamentosQuery } from '../../departamento/apiSlice';
import { useGetSituacaosQuery } from '../../situacao/apiSlice';
import { useGetPrioridadesQuery } from '../../prioridade/apiSlice';
import { Produto } from '../../produto/models';
import SearchProdutoModal from '../../produto/searchProdutoModal';
import { useEffect, useRef, useState } from 'react';

type Props = {
  formik: FormikProps<Requisicao>;
  isLoading?: boolean;
  findProdutoAction: (produto: Produto, local: string) => void;
};

export function RequisicaoFormInsert({
  formik,
  isLoading = false,
  findProdutoAction
}: Props) {
  const { data: dataSolicitante } = useGetSolicitantesQuery();
  const { data: dataDepartamento } = useGetDepartamentosQuery();
  const { data: dataSituacao } = useGetSituacaosQuery();
  const { data: dataPrioridade } = useGetPrioridadesQuery();
  const [openModal, setOpenModal] = useState(false);
  const [render, setRender] = useState(0);

  function setInitialfocus() {
    if (ref.current) {
      ref.current.focus();
      console.log('setInitialfocus', ref.current);
    }
  }
  // ajusta o focus
  useEffect(() => {
    if (render > 0) setInitialfocus();
  }, [render]);

  const ref = useRef<HTMLInputElement>(null);
  const tipoReq = formik.values.tipoReq;

  return (
    <Paper>
      <Box p={2}>
        {/* <div>{JSON.stringify(formik.errors)}</div> */}
        <Grid container spacing={3}>
          {/* descicao servico/material e tipo*/}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.tipoReq && formik.errors.tipoReq
                  )}>
                  <InputLabel shrink id="demo-simple-select-label">
                    Tipo
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    name="tipoReq"
                    label="Tipo"
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'tipoReq' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tipoReq}>
                    <MenuItem value={'P'}>Produto</MenuItem>
                    <MenuItem value={'S'}>Serviço</MenuItem>
                  </Select>
                  {formik.touched.tipoReq && formik.errors.tipoReq && (
                    <FormHelperText error id="error-tipoReq">
                      {formik.errors.tipoReq}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={7} display={'flex'} gap={1} alignItems={'center'}>
                <FormControl
                  fullWidth
                  focused
                  error={Boolean(
                    formik.touched.descricaoProdutoServico &&
                      formik.errors.descricaoProdutoServico
                  )}>
                  <TextField
                    tabIndex={0}
                    autoFocus
                    name="descricaoProdutoServico"
                    label="Produto/Serviço"
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'descricaoProdutoServico' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.descricaoProdutoServico}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                        setOpenModal(true);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <SearchProdutoModal
                          textSearch={formik.values.descricaoProdutoServico}
                          buttonIcon
                          action={findProdutoAction}
                          openModal={openModal}
                          onClose={() => {
                            setOpenModal(false);
                            setRender(render + 1);
                          }}
                          tipoReq={tipoReq}
                        />
                      )
                    }}
                  />
                  {formik.touched.descricaoProdutoServico &&
                    formik.errors.descricaoProdutoServico && (
                      <FormHelperText error id="error-descricaoProdutoServico">
                        {formik.errors.descricaoProdutoServico}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              {/* codigo */}
              <Grid item xs={2}>
                <FormControl focused fullWidth>
                  <TextField
                    tabIndex={1}
                    name="codigoSgi"
                    label="Cód."
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'codigoSgi' }}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    value={formik?.values?.codigoSgi ?? ''}
                  />
                </FormControl>
              </Grid>
              {/* local */}
              <Grid item xs={1}>
                <FormControl focused fullWidth>
                  <TextField
                    name="local"
                    label="Local"
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'codigoSgi' }}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    value={
                      formik.values?.local?.replace(
                        RegExp(/'|\(|\)/, 'g'),
                        ''
                      ) ?? ''
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* unidade/quantidade */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  focused
                  error={Boolean(
                    formik.touched.unidade && formik.errors.unidade
                  )}>
                  <TextField
                    name="unidade"
                    label="Unidade"
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'unidade' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.unidade}
                  />
                  {formik.touched.unidade && formik.errors.unidade && (
                    <FormHelperText error id="error-unidade">
                      {formik.errors.unidade}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={Boolean(formik.touched.qtde && formik.errors.qtde)}>
                  <TextField
                    name="qtde"
                    label="Quantidade"
                    type={'number'}
                    inputRef={ref}
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'qtde' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.qtde}
                  />
                  {formik.touched.qtde && formik.errors.qtde && (
                    <FormHelperText error id="error-qtde">
                      {formik.errors.qtde}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* solicitante/departamento */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.solicitante && formik.errors.solicitante
                  )}>
                  <InputLabel shrink id="solicitante">
                    Solicitante
                  </InputLabel>

                  <Select
                    id="solicitante"
                    name="solicitante"
                    label="Solicitante"
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'solicitante' }}
                    onChange={(e) =>
                      formik.setFieldValue('solicitante.id', e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    labelId={'solicitante'}
                    value={formik.values?.solicitante?.id ?? ''}>
                    {dataSolicitante &&
                      dataSolicitante.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.nome}
                        </MenuItem>
                      ))}
                  </Select>

                  {formik.touched.solicitante && formik.errors.solicitante && (
                    <FormHelperText error id="error-solicitante">
                      {formik.errors.solicitante}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  hiddenLabel
                  error={Boolean(
                    formik.touched.departamento && formik.errors.departamento
                  )}>
                  <InputLabel shrink id="departamento">
                    Destino
                  </InputLabel>

                  <Select
                    id="departamento"
                    name="departamento"
                    label="Departamento"
                    placeholder="Departamento"
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'departamento' }}
                    onChange={(e) =>
                      formik.setFieldValue('departamento.id', e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values?.departamento?.id || ''}>
                    {dataDepartamento &&
                      dataDepartamento.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.nome}
                        </MenuItem>
                      ))}
                  </Select>

                  {formik.touched.departamento &&
                    formik.errors.departamento && (
                      <FormHelperText error id="error-departamento">
                        {formik.errors.departamento}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* tipo/situacao/prioridade */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.situacao && formik.errors.situacao
                  )}>
                  <InputLabel shrink id="situacao">
                    Situação
                  </InputLabel>

                  <Select
                    id="situacao"
                    name="situacao"
                    label="Situação"
                    disabled={true}
                    inputProps={{ 'data-testid': 'situacao' }}
                    onChange={(e) =>
                      formik.setFieldValue('situacao.id', e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.situacao?.id ?? null}>
                    {dataSituacao &&
                      dataSituacao.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.nome}
                        </MenuItem>
                      ))}
                  </Select>

                  {formik.touched.situacao && formik.errors.situacao && (
                    <FormHelperText error id="error-situacao">
                      {formik.errors.situacao}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.prioridade && formik.errors.prioridade
                  )}>
                  <InputLabel shrink id="prioridade">
                    Prioridade
                  </InputLabel>

                  <Select
                    id="prioridade"
                    name="prioridade"
                    label="Prioridade"
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'prioridade' }}
                    onChange={(e) =>
                      formik.setFieldValue('prioridade.id', e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.prioridade?.id ?? null}>
                    {dataPrioridade &&
                      dataPrioridade.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.nome}
                        </MenuItem>
                      ))}
                  </Select>

                  {formik.touched.prioridade && formik.errors.prioridade && (
                    <FormHelperText error id="error-prioridade">
                      {formik.errors.prioridade}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* obs */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.descricaoProdutoServico &&
                      formik.errors.descricaoProdutoServico
                  )}>
                  {/* <TextField
                  name="descricaoProdutoServico"
                  label="Produto/Serviço"
                  disabled={isLoading}
                  inputProps={{ 'data-testid': 'descricaoProdutoServico' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.obs}
                /> */}
                  <TextareaAutosize
                    style={{
                      borderRadius: '15px',
                      padding: '15px',
                      background: '#ffffff07'
                    }}
                    name="obs"
                    color="primary"
                    minRows={5}
                    placeholder="Observações"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.obs}
                  />
                  {formik.touched.descricaoProdutoServico &&
                    formik.errors.descricaoProdutoServico && (
                      <FormHelperText error id="error-descricaoProdutoServico">
                        {formik.errors.descricaoProdutoServico}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
