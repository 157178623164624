import { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Check, ExitToApp } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1
};

interface Props {
  action: () => void;
  icon: React.ReactElement;
  className?: string;
  key?: number;
  label: string;
  descriptions?: ReactNode;
}

export default function GridActionConfirmModal({
  action,
  icon,
  className,
  label,
  descriptions
}: Props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <GridActionsCellItem
        icon={icon}
        label={label}
        className={className}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Typography
            bgcolor={'primary.main'}
            color={'primary.contrastText'}
            px={2}
            py={1}
            id="modal-modal-title"
            variant="h6"
            component="h4">
            {label}
          </Typography>
          <Box p={2}>{descriptions}</Box>
          <Box p={2} display={'flex'} justifyContent={'space-between'}>
            <Button
              startIcon={<ExitToApp />}
              variant="contained"
              color="inherit"
              onClick={() => {
                handleClose();
              }}>
              Fechar
            </Button>
            <Button
              variant="contained"
              startIcon={<Check />}
              color="success"
              onClick={() => {
                action();
                handleClose();
              }}>
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
