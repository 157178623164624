import { ExitToApp, Check } from '@mui/icons-material';
import {
  Box,
  Modal,
  Typography,
  Button,
  styled,
  InputBase
} from '@mui/material';
import { createElement, useEffect, useRef, useState } from 'react';
import { isNumeric } from '../../../utils/functions';
import { useSnackbar } from 'notistack';
import accounting from '../../../utils/accouting';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  color: 'inherit',
  flexGrow: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0.2)})`,
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden'
    // [theme.breakpoints.up('sm')]: {
    //   width: '12ch',
    //   '&:focus': {
    //     width: '20ch'
    //   }
    // }
  },
  variant: 'outlined'
}));

interface ChangeQtdeProps {
  component: JSX.Element;
  value: number;
  action: (newQtde: number) => void;
  title: string;
  description?: string;
}

export default function ChangeQtdeModal({
  component,
  action,
  title,
  description,
  value
}: ChangeQtdeProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const ref = useRef<HTMLInputElement>(null);

  const [newQtde, setNewQtde] = useState<string>();

  useEffect(() => {
    if (value) {
      setNewQtde(value.toString());
    } else {
      setNewQtde('');
    }
    if (open) {
      ref.current?.focus();
      ref.current?.select();
    }
  }, [open, value]);

  const send = () => {
    if (!newQtde) return;

    let num = 0;
    // const num = accounting.formatNumber(_value);
    // console.log('🚀 ~ file: changeQtdeModal.tsx:104 ~ _value:', {
    //   _value,
    //   num
    // });

    if (isNumeric(newQtde)) {
      num = Number(newQtde);
    } else {
      num = accounting.unformat(newQtde.replace(',', '.'));
    }

    if (!num) {
      enqueueSnackbar('Informe um valor válido', { variant: 'warning' });
      return;
    }

    action(Number(num));
    handleClose();
  };

  return (
    <Box>
      {createElement(component.type, {
        ...component.props,
        onClick: handleOpen
      })}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Typography
            bgcolor={'primary.main'}
            color={'primary.contrastText'}
            px={2}
            py={1}
            id="modal-modal-title"
            variant="h6"
            component="h4">
            {title}
          </Typography>
          {description && (
            <Box p={2} display={'flex'} justifyContent={'space-between'}>
              <Typography sx={{ flex: 1 }}>{description}</Typography>
            </Box>
          )}
          <Box
            p={2}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={2}>
            <Typography sx={{ flex: 1 }}>Quantidade:</Typography>
            <StyledInputBase
              inputRef={ref}
              autoFocus={true}
              value={newQtde}
              onChange={(e) => {
                const rawValue = e.target.value;
                //passar rawValue string to number
                setNewQtde(rawValue);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  send();
                }
              }}
            />
          </Box>
          <Box p={2} display={'flex'} justifyContent={'space-between'}>
            <Button
              type="button"
              startIcon={<ExitToApp />}
              variant="contained"
              color="inherit"
              onClick={() => {
                handleClose();
              }}>
              Fechar
            </Button>
            <Button
              type="button"
              variant="contained"
              startIcon={<Check />}
              color="success"
              onClick={send}>
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
