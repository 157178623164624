import {
  Box,
  Modal,
  Button,
  Grid,
  Typography,
  TextField,
  TextareaAutosize
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (data: Record<string, any>) => void;
  disabled?: boolean;
  selectesRows?: boolean;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
};

const initialColumns = {
  previsao: null,
  obs: null
};

export default function ModalPreencherLote({
  action,
  disabled = false,
  selectesRows = true
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (!selectesRows) {
      enqueueSnackbar('Selecione ao menos um item', { variant: 'warning' });
      return;
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [columns, setColumns] = useState<Record<string, string | null>>({
    ...initialColumns
  });

  function handleAction() {
    action(columns);
    handleClose();
  }

  useEffect(() => {
    // reset dados
    if (!open) {
      setColumns({ ...initialColumns });
    }
  }, [open]);

  return (
    <Box>
      <Button
        disabled={disabled}
        variant="contained"
        color="secondary"
        onClick={handleOpen}>
        Preencher em lote
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box sx={style}>
          {/* titulo */}
          <Box color={'primary.contrastText'} bgcolor={'primary.main'}>
            <Typography
              variant="h6"
              component="h2"
              sx={(theme) => ({
                fontSize: 16,
                fontWeight: 'bold',
                px: 2,
                py: 1
              })}>
              Preencher itens selecionados em lote
            </Typography>
          </Box>
          {/* conteudo */}
          <Box
            sx={{
              flex: 1,
              overflow: 'auto',
              p: 2,
              minHeight: '300px'
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" component="span">
                  Preencha os campos que deseja preencher em lote
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={'flex'}
                flexDirection={'column'}
                gap={2}>
                <Grid
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{
                    backgroundColor: 'common.white',
                    borderRadius: 1,
                    px: 1,
                    py: 1
                  }}>
                  <Typography variant="body2" component="span">
                    Previsão de entrega
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="date"
                    value={columns.previsao}
                    onChange={(e) =>
                      setColumns({
                        ...columns,
                        previsao: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'start'}
                  flexDirection={'column'}
                  sx={{
                    backgroundColor: 'common.white',
                    borderRadius: 1,
                    px: 1,
                    py: 1
                  }}>
                  <Typography variant="body2" component="span">
                    Observações
                  </Typography>
                  <TextareaAutosize
                    style={{ width: '100%' }}
                    minRows={6}
                    value={columns.obs ?? ''}
                    onChange={(e) =>
                      setColumns({
                        ...columns,
                        obs: e.target.value
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'space-between'}
            px={2}
            py={1}>
            <Button variant="contained" color="error" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="contained" color="success" onClick={handleAction}>
              Preencher em lote
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}
