// import { useSnackbar } from 'notistack';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchForProdutoMutation } from './apiSlice';
import { ProdutoCadastro } from './models';
import { useSnackbar } from 'notistack';
import { useParams, useSearchParams } from 'react-router-dom';
import { Info } from '../users/models';
import { last } from 'lodash';

export type TProdutoParams = {
  take: number;
  page: number;
  // searchFor: string;
};

type TType = 'filter' | 'search';

interface IContext {
  loading: boolean;
  produtos: ProdutoCadastro[];
  type: TType;
  setType: (type: TType) => void;
  searchFor: string;
  setSearchFor: (searchFor: string) => void;
  take: number;
  page: number;
  qtdePag: number;
  qtdeReg: number;
  //tipo de TProdutoParams
  getParams: (param: Record<string, string | number>) => TProdutoParams;
}

interface IProvider {
  children: React.ReactNode;
}

const Context = createContext<IContext>({} as IContext);

const Provider: React.FC<IProvider> = ({ children }: IProvider) => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const [produtos, setProdutos] = useState<ProdutoCadastro[]>([]);
  const query = useParams();
  const [searchFor, setSearchFor] = useState<string>(query.searchFor ?? '');
  const [searchForProduto, { data, error, isLoading: loading }] =
    useSearchForProdutoMutation();

  // tipo de busca: filtro ou pesquisa
  // const typeCurrent = useRef<'filter' | 'search'>('filter');
  const [type, setType] = useState<TType>('filter');

  const [take, setTake] = useState<number>(10);
  // const [lastTake, setLastTake] = useState<number>(10);

  const [page, setPage] = useState<number>(1);
  // const [lastPage, setLastPage] = useState<number>(1);

  const [qtdeReg, setQtdeReg] = useState<number>(0);
  const qtdePag = useMemo(() => Math.ceil(qtdeReg / take), [qtdeReg, take]);

  useEffect(() => {
    searchForProduto({
      searchFor: searchFor,
      skip: searchParams.has('page')
        ? Number(searchParams.get('page')) > 1
          ? Number(searchParams.get('page')) - 1
          : 0
        : page,
      take: searchParams.has('take') ? Number(searchParams.get('take')) : take
    })
      .unwrap()
      .then((response) => {
        setProdutos(response.produtos);
        const info = response.info;
        setTake(info.qtdePorPagina);
        // setLastTake(info.qtdePorPagina);
        setPage(info.pagina);
        // setLastPage(info.pagina);
        setQtdeReg(info.qtdeTotal);
      })
      .catch((error) => {
        console.error('Error:', error);
        // enqueueSnackbar('Erro ao buscar produtos', { variant: 'error' });
      });
  }, [enqueueSnackbar, searchFor, searchForProduto, searchParams]);

  const getParams = (
    param: Record<string, string | number>
  ): TProdutoParams => {
    const newParams = {
      take: param.take ? Number(param.take) : take,
      page: param.page ? Number(param.page) : page
      // searchFor: param.searchFor ? String(param.searchFor) : searchFor
    };
    return newParams;
  };

  return (
    <Context.Provider
      value={{
        loading,
        produtos: data?.produtos as ProdutoCadastro[],
        type,
        setType,
        searchFor,
        setSearchFor,
        take,
        page,
        qtdePag,
        qtdeReg,
        getParams
      }}>
      {children}
    </Context.Provider>
  );
};

const useProduto = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useProduto, Provider as ProdutoProvider };
