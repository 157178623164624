import { Box } from '@mui/material';
import SearchFornecedorModal from '../../fornecedor/searchFornecedorModal';
import TableFornecedor from '../common/tableFornecedor';
import { Fornecedor } from '../../fornecedor/models';
import SuggestFornecedorModal from '../../fornecedor/suggestFornecedorModal';

interface Props {
  values: Fornecedor[];
  onChange: (values: Fornecedor[]) => void;
}

const StepFornecedores = ({ values, onChange }: Props) => {
  return (
    <Box
      sx={{
        height: '70vh',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <SearchFornecedorModal
          action={(fornecedor: Fornecedor) => {
            if (values.some((f) => f.id === fornecedor.id)) return;
            const newValues = [...values, fornecedor];
            onChange(newValues);
          }}
        />
        {/* <SuggestFornecedorModal codRefs={[]} /> */}
      </Box>
      <Box sx={{ height: '75vh' }}>
        <TableFornecedor
          data={values ?? []}
          actionDelete={(id: string | number) => {
            const newValues = values.filter((f) => f.id! !== Number(id));
            onChange(newValues);
          }}
        />
      </Box>
    </Box>
  );
};

export default StepFornecedores;
