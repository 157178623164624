/* eslint-disable prettier/prettier */
import type { CellKeyDownArgs, CellKeyboardEvent } from 'react-data-grid';
import columns from './columns';

type CellNavigationMode =
  | 'NONE'
  | 'CHANGE_ROW'
  | 'LOOP_OVER_ROW'
  | 'LOOP_OVER_COLUMN'
  | 'NO_TAB';

export function handleCellKeyDown(
  args: CellKeyDownArgs<any>,
  event: CellKeyboardEvent,
  rows: any[],
  cellNavigationMode: CellNavigationMode,
  showRowId?: (rowIdx: number) => void
) {
  if (args.mode === 'EDIT') return;
  const { column, rowIdx, selectCell } = args;
  const { idx } = column;
  const { key, shiftKey, ctrlKey } = event;

  const preventDefault = () => {
    event.preventGridDefault();
    event.preventDefault();
  };

  const loopOverNavigation = () => {
    if (
      (key === 'ArrowRight' || (key === 'Tab' && !shiftKey)) &&
      idx === columns.length - 1
    ) {
      selectCell({ rowIdx, idx: 0 });
      preventDefault();
    } else if (
      (key === 'ArrowLeft' || (key === 'Tab' && shiftKey)) &&
      idx === 0
    ) {
      selectCell({ rowIdx, idx: columns.length - 1 });
      preventDefault();
    } else if (key === 'Enter' && ctrlKey && showRowId) {
      console.log('showRowId');
      showRowId(rowIdx);
      preventDefault();
    }
  };

  const changeRowNavigation = () => {
    if (key === 'ArrowRight' && idx === columns.length - 1) {
      if (rows.length === 0) return;
      if (rowIdx === -1) {
        selectCell({ rowIdx: 0, idx: 0 });
      } else {
        if (rowIdx === rows.length - 1) return;
        selectCell({ rowIdx: rowIdx + 1, idx: 0 });
      }
      preventDefault();
    } else if (key === 'ArrowLeft' && idx === 0) {
      if (rowIdx === -1) return;
      selectCell({ rowIdx: rowIdx - 1, idx: columns.length - 1 });
      preventDefault();
    } else if (key === 'Enter' && ctrlKey && showRowId) {
      showRowId(rowIdx);
      preventDefault();
    }
  };

  const loopOverColumnNavigation = () => {
    let newRowIdx: number;
    if (rowIdx === -1) {
      newRowIdx = shiftKey ? rows.length - 1 : 0;
    } else {
      newRowIdx = shiftKey
        ? rowIdx - 1
        : rowIdx === rows.length - 1
        ? -1
        : rowIdx + 1;
    }
    selectCell({ rowIdx: newRowIdx, idx });
    preventDefault();
  };

  if (cellNavigationMode === 'LOOP_OVER_ROW') {
    loopOverNavigation();
  } else if (cellNavigationMode === 'CHANGE_ROW') {
    changeRowNavigation();
  } else if (cellNavigationMode === 'LOOP_OVER_COLUMN' && key === 'Tab') {
    loopOverColumnNavigation();
  } else if (cellNavigationMode === 'NO_TAB' && key === 'Tab') {
    // Need to allow default event to focus the next element
    event.preventGridDefault();
  }
}
