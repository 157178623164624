// import { useSnackbar } from 'notistack';
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';
import { useFindCotacoesMutation } from './apiSlice';
import { Cotacao, ICotacaoFilters, COTACAO_INITIAL_FILTERS } from './models';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { isEqual } from 'lodash';

export type TCotacaoParams = {
  take: number;
  skip: number;
  orderBy: string;
  sort: string;
};

export const INITIAL_PARAMS: TCotacaoParams = {
  take: 10,
  skip: 0,
  orderBy: '',
  sort: '1'
};

interface IContext {
  loading: boolean;
  data: Cotacao[];
  // filtro
  filterCotacoes: () => void;
  filters: ICotacaoFilters;
  setFilters: (filters: ICotacaoFilters) => void;
  filterParams: TCotacaoParams;

  // pesquisa
  searchCotacoes: (serachFor: string | undefined) => void;
  searchFor: string;
  searchParams: TCotacaoParams;

  // filtro e pesquisa
  setParams: (params: TCotacaoParams) => void;
  paramsQtdeRows: number;
  paramsQtdePage: number;

  // tipo de busca: filtro ou pesquisa
  type: 'filter' | 'search';
  setType: (type: 'filter' | 'search') => void;
}

interface IProvider {
  children: React.ReactNode;
}

const Context = createContext<IContext>({} as IContext);

const Provider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [findCotacao] = useFindCotacoesMutation({
    fixedCacheKey: 'cotacao'
  });
  // const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const data = useRef<Cotacao[]>([]);

  // tipo de busca: filtro ou pesquisa
  const typeCurrent = useRef<'filter' | 'search'>('filter');
  const setType = (_type: 'filter' | 'search') => {
    typeCurrent.current = _type as 'filter' | 'search';
    if (_type === 'filter') {
      searchParamsCurrent.current = {
        ...searchParamsCurrent.current,
        skip: 0
      };
    } else {
      filterParamsCurrent.current = {
        ...filterParamsCurrent.current,
        skip: 0
      };
    }
  };

  // filtros
  const [filters, setFilters] = useLocalStorage<ICotacaoFilters>(
    'cotacao-filtros',
    COTACAO_INITIAL_FILTERS
  );
  const lastFilters = useRef<ICotacaoFilters>(COTACAO_INITIAL_FILTERS);

  // pesquisa
  const searchFor = useRef<string>('');
  const lastSearchFor = useRef<string>('');

  // parâmetros do filtro
  const filterParamsCurrent = useRef<TCotacaoParams>({
    ...INITIAL_PARAMS
  });
  const filterParamsQtdeRows = useRef<number>(0);
  const filterParamsQtdePage = useRef<number>(0);

  // parâmetros da pesquisa
  const searchParamsCurrent = useRef<TCotacaoParams>({
    ...INITIAL_PARAMS
  });
  const searchParamsQtdeRows = useRef<number>(0);
  const searchParamsQtdePage = useRef<number>(0);

  // seta os parâmetros de acordo com o tipo de busca
  const setParams = (params: TCotacaoParams) => {
    if (typeCurrent.current === 'filter') {
      filterParamsCurrent.current = {
        ...filterParamsCurrent.current,
        ...params
      };
      filterCotacoes();
    } else {
      searchParamsCurrent.current = {
        ...searchParamsCurrent.current,
        ...params
      };
      searchCotacoes();
    }
  };

  // busca cotações de acordo com o filtro
  const filterCotacoes = useCallback(async () => {
    try {
      setLoading(true);
      typeCurrent.current = 'filter';
      // reset data e parâmetros
      data.current = [];
      filterParamsQtdeRows.current = 0;
      filterParamsQtdePage.current = 0;

      const entries = Object.entries(filters);

      const args = {};
      entries.forEach(([key, value]) => {
        if (value && value.length > 0) {
          if (key === 'searchFor' || key === 'createdAt') {
            return;
          }

          if (key === 'created_at') {
            if (!value[0] && !value[1]) return;

            const ini = value[0];
            const end = value[1];

            Object.assign(args, {
              [key]: [ini, end]
            });
            return;
          }

          Object.assign(args, { [key]: value });
        }
      });

      let fParams = filterParamsCurrent.current;
      if (!isEqual(lastFilters.current, filters)) {
        fParams = {
          ...filterParamsCurrent.current,
          skip: 0
        };
      }
      filterParamsCurrent.current = fParams;

      const params = { ...fParams, body: args };
      const result = await findCotacao(params);
      if (!result) {
        data.current = [];
        filterParamsQtdeRows.current = 0;
        filterParamsQtdePage.current = 0;
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rows = (result as any).data.cotacoes;
      const qtdeRegistros = (result as any).data.info.registros;
      const qtdePorPagina =
        Number((result as any).data?.info?.qtdePorPagina) ?? 0;
      const pages = Math.ceil(qtdeRegistros / qtdePorPagina);
      filterParamsQtdeRows.current = qtdeRegistros;
      filterParamsQtdePage.current = pages;
      data.current = rows as Cotacao[];
      lastFilters.current = filters;
      // reseta a pesquisa
      searchFor.current = '';
    } catch (error) {
      data.current = [];
    } finally {
      setLoading(false);
    }
  }, [filters, findCotacao]);

  // busca cotações de acordo com alteração do filtro
  // useEffect(() => {
  //   if (isEqual(lastFilters.current, filters)) {
  //     return;
  //   }

  //   filterCotacoes();
  // }, [filterCotacoes, filters]);

  // busca cotações de acordo com a pesquisa
  const searchCotacoes = useCallback(
    async (_searchFor: string | undefined = undefined) => {
      try {
        setLoading(true);
        // reseta data e parâmetros
        data.current = [];
        typeCurrent.current = 'search';
        searchParamsQtdeRows.current = 0;
        searchParamsQtdePage.current = 0;

        let searchText;
        if (_searchFor) {
          searchText = _searchFor;
          searchFor.current = searchText;
        } else {
          searchText = searchFor.current;
        }

        let fParams = searchParamsCurrent.current;
        if (!isEqual(lastSearchFor.current, searchText)) {
          fParams = {
            ...searchParamsCurrent.current,
            skip: 0
          };
        }

        const params = {
          ...fParams,
          body: {
            searchFor: searchText
          }
        };

        const result = await findCotacao(params as any);
        if (!result) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rows = (result as any).data.cotacoes;
        const qtdeRegistros = (result as any).data?.info?.registros ?? 0;
        const qtdePorPagina =
          Number((result as any).data?.info?.qtdePorPagina) ?? 0;
        const pages = Math.ceil(qtdeRegistros / qtdePorPagina);
        searchParamsQtdeRows.current = qtdeRegistros;
        searchParamsQtdePage.current = pages;
        data.current = rows as Cotacao[];
        lastSearchFor.current = searchFor.current;
        searchParamsCurrent.current = fParams;
        // reseta o filtro
        // setFilters(COTACAO_INITIAL_FILTERS);
      } catch (error) {
        data.current = [];
      } finally {
        setLoading(false);
      }
    },
    [findCotacao]
  );

  // busca cotações de acordo com alteração da pesquisa
  // useEffect(() => {
  //   if (isEqual(lastSearchFor.current, searchFor)) {
  //     return;
  //   }

  //   searchCotacoes();
  // }, [searchFor, searchCotacoes]);

  return (
    <Context.Provider
      value={{
        loading,
        data: data.current,
        // filtro
        filterCotacoes,
        filters,
        setFilters,
        filterParams: filterParamsCurrent.current,

        // pesquisa
        searchCotacoes,
        searchFor: searchFor.current,
        searchParams: searchParamsCurrent.current,

        // filtro e pesquisa
        setParams,
        paramsQtdeRows:
          typeCurrent.current === 'filter'
            ? filterParamsQtdeRows.current
            : searchParamsQtdeRows.current,
        paramsQtdePage:
          typeCurrent.current === 'filter'
            ? filterParamsQtdePage.current
            : searchParamsQtdePage.current,

        // tipo de busca: filtro ou pesquisa
        type: typeCurrent.current,
        setType
      }}>
      {children}
    </Context.Provider>
  );
};

const useCotacao = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useCotacao, Provider as CotacaoProvider };
