/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createElement, useState } from 'react';
import { Save } from '@mui/icons-material';
import { Box, Modal, Typography, Button } from '@mui/material';
import ReactQuill from 'react-quill';
// @ts-ignore
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import './style.css';
// https://github.com/zenoamaro/react-quill

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '95vh',
  bgcolor: '#fff',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1
};

interface EditHtmlModalProps {
  component: JSX.Element;
  html?: string;
  action: (value: string, base64: string, html: string) => void;
}

export default function EditHtmlModal({
  component,
  html,
  action
}: EditHtmlModalProps) {
  const fontSizeArr = [
    '9px',
    '10px',
    '12px',
    '14px',
    '16px',
    '20px',
    '24px',
    '32px',
    '42px',
    '54px'
    // '68px',
    // '84px',
    // '98px'
  ];
  const Size = ReactQuill.Quill.import('attributors/style/size');
  Size.whitelist = fontSizeArr;
  ReactQuill.Quill.register(Size, true);

  // react quill image resize
  const ReactQuillWithResize = ReactQuill.Quill;
  ReactQuillWithResize.register('modules/imageResize', ImageResize);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = useState(html ?? '');

  const modules = {
    toolbar: [
      // [{ header: [1, 2, false] }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      [
        {
          size: fontSizeArr
        }
      ],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image']
    ],
    imageResize: {
      parchment: ReactQuill.Quill.import('parchment')
      // See optional "config" below
    }
  };

  const handleAction = () => {
    const doc = new DOMParser().parseFromString(value, 'text/html');
    const img = doc.querySelector('img');
    const base64 = img?.getAttribute('src') ?? '';
    img?.setAttribute('src', 'cid:minhaimagem');
    const html = doc.body.innerHTML;
    console.log({ value, base64, html });
    action(value, base64, html);
    handleClose();
  };

  return (
    <Box>
      {createElement(component.type, {
        ...component.props,
        onClick: handleOpen
      })}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box
          sx={{
            ...style,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'auto'
          }}>
          <Typography
            bgcolor={'primary.main'}
            color={'primary.contrastText'}
            px={2}
            py={1}
            id="modal-modal-title"
            variant="h6"
            component="h4">
            {'Criar novo template'}
          </Typography>
          <Box
            p={2}
            display={'flex'}
            justifyContent={'space-between'}
            justifyItems={'center'}>
            {/* <Button
              startIcon={<ExitToApp />}
              variant="contained"
              color="inherit"
              onClick={() => {
                handleClose();
              }}>
              Fechar
            </Button> */}
            <Typography align="center" sx={{ flex: 1 }}>
              (Trocar endereço do link por{' '}
              <code
                style={{
                  color: 'red'
                }}>
                $link$
              </code>
              )
            </Typography>
            <Button
              variant="contained"
              startIcon={<Save />}
              color="success"
              onClick={handleAction}>
              Fechar
            </Button>
          </Box>
          <ReactQuill
            style={{ height: '75%' }}
            theme="snow"
            value={value}
            onChange={setValue}
            modules={modules}
          />
        </Box>
      </Modal>
    </Box>
  );
}
