import { Box, Paper, Typography, Stack } from '@mui/material';
import { CotacaoFornecedor, CotacaoItem } from '../models';
// import CotacaoExpiradaImg from '../../../assets/svg/cotacao_expirada.min.svg';
import { useLocation, useParams } from 'react-router-dom';
import DataGridItemsRequisicaoEsterno2 from '../edit/dataGridItensRequisaoExterno';
import { useGetCotacaoExternoQuery } from '../apiSlice';
import { useEffect, useState } from 'react';

export default function CotacaoExpirada() {
  const location = useLocation();
  const params = useParams<{ uuid: string }>();

  const { data, isLoading, isFetching } = useGetCotacaoExternoQuery({
    uuid: params.uuid ?? ''
  });

  const [itemsCotacao, setItemsCotacao] = useState<CotacaoItem[]>([]);
  const [cotacaoId, setCotacaoId] = useState<number>(0);

  const { fornecedorCotacao } = location.state as {
    fornecedorCotacao: CotacaoFornecedor;
  };

  useEffect(() => {
    if (data) {
      setCotacaoId(data.id);
      setItemsCotacao(data.itens);
    }
  }, [data]);

  return (
    <Box width={'98vw'} margin={'0 auto'}>
      <Paper sx={{ p: 2, my: 1 }}>
        {/* fornecedor */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography fontSize={12} variant="h6" component="span" gutterBottom>
            Fornecedor
          </Typography>
        </Box>
        {/* expiracao */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Stack gap={0}>
            <Typography
              fontSize={16}
              fontWeight={'bold'}
              variant="h6"
              component="span"
              gutterBottom>
              {fornecedorCotacao?.fornecedor.nome}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={'bold'}
              variant="h6"
              component="span"
              gutterBottom>
              CNPJ: {fornecedorCotacao?.fornecedor.cnpj} -{' '}
              {fornecedorCotacao?.fornecedor.cidade}/
              {fornecedorCotacao?.fornecedor.uf}
            </Typography>
          </Stack>

          <Stack gap={0}>
            <Typography
              fontSize={16}
              fontWeight={'bold'}
              variant="h6"
              color={'error'}
              component="span"
              gutterBottom>
              [Apenas Leitura]
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={'bold'}
              variant="h6"
              component="span"
              gutterBottom>
              Número da Cotação: {data?.id}
            </Typography>
          </Stack>
        </Box>
      </Paper>
      <Paper sx={{ p: 2, my: 1 }}>
        {/* itens */}
        <DataGridItemsRequisicaoEsterno2
          height="calc(100vh - 310px)"
          loading={isLoading || isFetching}
          items={itemsCotacao}
          cotacaoId={cotacaoId}
          disabled={true}
        />
      </Paper>
    </Box>
  );
}
