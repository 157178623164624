import React from 'react';
import { RequisicaoFormEdit } from './formEdit';
import { Box } from '@mui/material';

interface Props {
  value: number;
  index: number;
  formik: any;
  isFetching?: boolean;
}
export default function TabPanelHistory({
  value,
  index,
  formik,
  isFetching = false
}: Props) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      <Box sx={{ height: `78vh` }}></Box>
    </div>
  );
}
