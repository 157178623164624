import { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Popover,
  TextField,
  Button
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit';
import { useUpdateCotacaoMutation } from '../apiSlice';
import { useSnackbar } from 'notistack';
import { Cotacao } from '../models';
import { Save } from '@mui/icons-material';

interface Props {
  value: number;
  index: number;
  cotacaoId: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  obs: string;
  qtdeRequisicoes: number;
  qtdeFornecedores: number;
}

export default function TabPanelInfo({
  index,
  value,
  cotacaoId,
  createdAt,
  updatedAt,
  status,
  obs,
  qtdeRequisicoes,
  qtdeFornecedores
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateCotacao] = useUpdateCotacaoMutation();
  const [obsEdit, setObsEdit] = useState<string>(obs);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setObsEdit(obs);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-edit' : undefined;

  function handleUpdateObs() {
    if (!obsEdit) {
      enqueueSnackbar('Descrição não pode ser vazia!', {
        variant: 'error'
      });
      return;
    }
    const data = {
      id: cotacaoId,
      obs: obsEdit,
      updated_at: updatedAt
    };
    updateCotacao(data as unknown as Cotacao)
      .unwrap()
      .then(() => {
        setObsEdit('');
        enqueueSnackbar('Descrição atualizada com sucesso!', {
          variant: 'success'
        });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar('Erro ao atualizar descrição!', {
          variant: 'error'
        });
        handleClose();
      });
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} columns={2}>
            {/* data */}
            <Grid item xs={1}>
              <Typography>Data: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{createdAt}</Typography>
              </Box>
            </Grid>

            {/* status */}
            <Grid item xs={1}>
              <Typography>Status: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{status}</Typography>
              </Box>
            </Grid>

            {/* descrição */}
            <Grid item xs={1}>
              <Typography>Descrição: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                })}>
                <Typography>{obs}</Typography>
                <IconButton onClick={handleClick} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Box>
            </Grid>

            {/* qtde Fornecedores */}
            <Grid item xs={1}>
              <Typography>Qtde Fornecedores: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{qtdeFornecedores}</Typography>
              </Box>
            </Grid>

            {/* qtde Requisições */}
            <Grid item xs={1}>
              <Typography>Qtde Requisições: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{qtdeRequisicoes}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <Box p={1} gap={1} display={'flex'} flexDirection={'row'}>
          <TextField
            size="small"
            sx={{ minWidth: 400 }}
            label="Nova Descrição"
            variant="outlined"
            value={obsEdit}
            onChange={(e) => setObsEdit(e.target.value)}
          />
          <Button
            size="small"
            startIcon={<Save />}
            onClick={handleUpdateObs}
            variant="contained"
            color="success">
            Salvar
          </Button>
        </Box>
      </Popover>
    </div>
  );
}
