import { NavigateNext, Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Chip, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useNavigate, useParams } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { CategoriaForm } from './form';
import {
  formCategoriaValidationSchemaEdit,
  CATEGORIA_INITIAL_STATE
} from './models';
import {
  useGetCategoriaQuery,
  useUpdateCategoriaMutation,
  useDeleteCategoriaMutation
} from './apiSlice';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../auth/authSlice';
import { EnumPermissao } from '../auth/models';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function CategoriaEdit() {
  const id = useParams().id as string;
  const { data: categoria, isFetching } = useGetCategoriaQuery({ id });
  const { enqueueSnackbar } = useSnackbar();
  const [updateCategoria, status] = useUpdateCategoriaMutation();
  const [deleteCategoria, statusDel] = useDeleteCategoriaMutation();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const formik = useFormik({
    initialValues: CATEGORIA_INITIAL_STATE,
    validationSchema: formCategoriaValidationSchemaEdit,
    onSubmit: (values) => {
      console.log('🚀 ~ CategoriaEdit ~ values:', values);
      updateCategoria(values);
    }
  });

  const user = useSelector(selectCurrentUser);
  function authorization(action: string) {
    return user.permissoes[EnumPermissao.CATEGORIA].includes(action);
  }

  const handleDelete = () => {
    // confirmar permiisao
    if (!authorization('d')) {
      enqueueSnackbar('Você não tem permissão para excluir', {
        variant: 'error'
      });
      return;
    }
    if (id) {
      confirm({
        title: 'Tem certeza que deseja excluir esta categoria?',
        description: 'Confirmar exclusão de categoria',
        confirmationText: 'Sim',
        cancellationText: 'Não'
      }).then(() => {
        deleteCategoria({ id });
        navigate(AppConfig.routes.cadCategoria);
      });
    }
  };

  useEffect(() => {
    if (categoria) {
      console.log('🚀 ~ useEffect ~ categoria:', categoria);
      formik.setValues(categoria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoria]);

  useEffect(() => {
    if (status.isSuccess || statusDel.isSuccess) {
      enqueueSnackbar('Categoria Alterado', { variant: 'success' });
    }
    if (status.error || statusDel.error) {
      enqueueSnackbar('Error ao Alterar Categoria', { variant: 'error' });
    }
  }, [
    enqueueSnackbar,
    status.error,
    status.isSuccess,
    statusDel.error,
    statusDel.isSuccess
  ]);

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadCategoria}>
              <Typography>Categorias</Typography>
            </Link>
            <Typography>Alterar</Typography>
            <Chip label={formik.values.descricao} />
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadCategoria}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            {id && (
              <Button
                onClick={handleDelete}
                startIcon={<Delete />}
                color="error"
                type="button"
                variant="contained">
                Excluir
              </Button>
            )}
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <CategoriaForm isLoading={isFetching} formik={formik} />
        </Box>
      </form>
    </Inner>
  );
}
