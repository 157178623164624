import { Add, Edit, NavigateNext } from '@mui/icons-material';
import { Box, Button, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';

import { Link, useNavigate } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { User } from './models';
import { useGetUsersQuery } from './usersSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function Users() {
  const navigate = useNavigate();
  const { isLoading, data } = useGetUsersQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    {},
    {
      refetchOnMountOrArgChange: true
    }
  );

  function mapDataToGridRows(data: User[]) {
    return data.map((d) => ({
      id: d.id,
      nome: d.nome,
      email: d.email,
      perfil: d.perfil,
      isAtivo: d.isAtivo
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function RenderActionsCell(params: GridRenderCellParams) {
    // const { enqueueSnackbar } = useSnackbar();
    // const [updateUser] = useUpdateUserMutation();

    // const [deleteUser, status] = useDeleteUserMutation();
    // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //   setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //   setAnchorEl(null);
    // };

    // const handleDelete = () => {
    //   deleteUser({ id: params.id as string });
    // };

    // const open = Boolean(anchorEl);

    // // password
    // const [passwordEdit, setPasswordEdit] = useState<string>('');
    // const [anchorElPassword, setAnchorElPassword] =
    //   useState<HTMLButtonElement | null>(null);

    // const handleClickPassword = (
    //   event: React.MouseEvent<HTMLButtonElement>
    // ) => {
    //   setAnchorElPassword(event.currentTarget);
    // };

    // const handleClosePassword = () => {
    //   setAnchorElPassword(null);
    // };

    // const openPassword = Boolean(anchorElPassword);
    // ('');

    // function handleUpdatePassword() {
    //   if (!passwordEdit) {
    //     enqueueSnackbar('Defina uma senha', { variant: 'error' });
    //     return;
    //   }
    //   updateUser({ id: params.id as string, senha: passwordEdit } as User)
    //     .unwrap()
    //     .then(() => {
    //       setPasswordEdit('');
    //       handleClosePassword();
    //       enqueueSnackbar('Senha alterada com sucesso', { variant: 'success' });
    //     })
    //     .catch(() => {
    //       enqueueSnackbar('Erro ao alterar senha', { variant: 'error' });
    //     });
    // }

    // useEffect(() => {
    //   if (status.isSuccess) {
    //     enqueueSnackbar('Usuário Excluído!', { variant: 'success' });
    //   }
    //   if (status.error) {
    //     enqueueSnackbar('Error ao Excluir Usuário', { variant: 'error' });
    //   }
    // }, [enqueueSnackbar, status.error, status.isSuccess]);

    // const id = open ? 'delete-popover' : undefined;
    // const idPassword = open ? 'password-popover' : undefined;
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* editar */}
        <IconButton
          component={Link}
          color="secondary"
          to={AppConfig.routes.cadUserEdit(params.id as string)}
          aria-label="edit"
          data-testid="edit-button"
          title="Editar">
          <Edit />
        </IconButton>

        {/* alterar senha */}
        {/* <>
          <IconButton
            onClick={handleClickPassword}
            color="secondary"
            aria-describedby={id}
            aria-label="password"
            title="Alterar Senha">
            <PasswordIcon />
          </IconButton>
          <Popover
            id={idPassword}
            open={openPassword}
            anchorEl={anchorElPassword}
            onClose={handleClosePassword}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}>
            <Box p={1} gap={1} display={'flex'} flexDirection={'row'}>
              <TextField
                size="small"
                type="password"
                label="Nova Senha"
                variant="outlined"
                value={passwordEdit}
                onChange={(e) => setPasswordEdit(e.target.value)}
              />

              <Button onClick={handleUpdatePassword} variant="text">
                Alterar
              </Button>
            </Box>
          </Popover>
        </> */}
        {/* excluir */}
        {/* <>
          <IconButton
            onClick={handleClick}
            color="error"
            aria-describedby={id}
            aria-label="delete"
            data-testid="delete-button"
            title="Excluir">
            <Delete />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}>
            <Button onClick={handleDelete} variant="text">
              Excluir?
            </Button>
          </Popover>
        </> */}
      </Box>
    );
  }

  return (
    <Inner>
      <Box
        sx={{ my: 1 }}
        display="flex"
        justifyContent="space-between"
        alignItems={'center'}>
        <Breadcrumbs
          sx={{ py: 1 }}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography>Cadastro</Typography>
          <Typography fontWeight={'bold'}>Usuários</Typography>
        </Breadcrumbs>
        <Button
          startIcon={<Add />}
          component={Link}
          variant="contained"
          to={AppConfig.routes.cadUserInsert}>
          Inserir
        </Button>
      </Box>
      <Paper>
        <Box height="78vh">
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: 12
              },
              fontSize: 12
            }}
            loading={isLoading}
            paginationMode="server"
            rows={data ? mapDataToGridRows(data.usuarios) : []}
            columns={[
              {
                field: 'nome',
                headerName: 'Nome',
                flex: 1,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                }
              },
              {
                field: 'email',
                headerName: 'E-mail',
                width: 200,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                }
              },
              {
                field: 'perfil',
                headerName: 'Perfil',
                width: 100,
                sortable: false,
                editable: false,
                filterable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                }
              },
              {
                field: 'isAtivo',
                headerName: 'Ativo',
                width: 100,
                type: 'boolean',
                sortable: false,
                editable: false,
                filterable: false,
                hideable: false,
                disableColumnMenu: true
              }
              // {
              //   field: 'id',
              //   headerName: 'Ações',
              //   width: 150,
              //   disableColumnMenu: true,
              //   sortable: false,
              //   renderCell: RenderActionsCell
              // }
            ]}
            pageSize={10}
            onRowDoubleClick={(params: GridRowParams) =>
              navigate(AppConfig.routes.cadUserEdit(params.id as string))
            }
          />
        </Box>
      </Paper>
    </Inner>
  );
}
