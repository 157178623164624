import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  alpha
} from '@mui/material';
import { EmailConfig } from './models';
import { FormikProps } from 'formik';
import EditHtmlModal from '../../components/EditHtmlModal';
import { Edit } from '@mui/icons-material';

interface Props {
  value: number;
  index: number;
  formik: FormikProps<Partial<EmailConfig>>;
  isLoading?: boolean;
}
export default function TabEmail({ value, index, formik, isLoading }: Props) {
  return (
    <div
      role="tabpanel"
      style={{ overflow: 'hidden', overflowY: 'auto' }}
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      {value === index && (
        <Box
          sx={{
            p: 2,
            gap: 2,
            display: 'flex',
            flexDirection: 'column'
          }}>
          {/* from */}
          <Grid container spacing={2} columns={2}>
            <Grid item xs={1}>
              <Typography>FROM: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <FormControl
                  fullWidth
                  error={Boolean(formik.touched.from && formik.errors.from)}>
                  <TextField
                    name="from"
                    size="small"
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.from}
                  />
                  {formik.touched.from && formik.errors.from && (
                    <FormHelperText error id="error-from">
                      {formik.errors.from}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {/* assunto */}
          <Grid container spacing={2} columns={2}>
            <Grid item xs={1}>
              <Typography>Assunto: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.assunto && formik.errors.assunto
                  )}>
                  <TextField
                    name="assunto"
                    size="small"
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.assunto}
                  />
                  {formik.touched.assunto && formik.errors.assunto && (
                    <FormHelperText error id="error-assunto">
                      {formik.errors.assunto}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {/* corpo */}
          <Grid
            container
            columns={1}
            display={'flex'}
            justifyContent={'space-between'}>
            <Typography>Corpo: </Typography>
            <EditHtmlModal
              component={
                <Button startIcon={<Edit />} variant="contained" type="button">
                  Editar Corpo
                </Button>
              }
              html={formik.values.corpoView || ''}
              action={(value, base64, html) => {
                formik.setValues({
                  ...formik.values,
                  corpo: html || '',
                  corpoView: value || '',
                  imagem: base64 || ''
                });
              }}
            />
          </Grid>
          <Divider />
          <Grid container columns={1}>
            <div
              style={{
                height: '38vh',
                width: '100%',
                overflow: 'hidden',
                overflowY: 'auto'
              }}
              dangerouslySetInnerHTML={{
                __html: formik.values.corpoView || ''
              }}
            />
          </Grid>
        </Box>
      )}
    </div>
  );
}
