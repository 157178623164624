import {
  Paper,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch
} from '@mui/material';
import { Fornecedor } from './models';
import { FormikProps } from 'formik';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect, useState } from 'react';
import MaskedTextField from '../../components/MaskedField';

type Props = {
  formik: FormikProps<Fornecedor>;
  isLoading?: boolean;
};

export function FornecedorForm({ formik, isLoading = false }: Props) {
  const [tipoPessoa, settipoPessoa] = useState('F');

  useEffect(() => {
    if (formik.values.tipoPessoa) {
      settipoPessoa(formik.values.tipoPessoa);
    }
  }, [formik.values.tipoPessoa]);

  return (
    <Paper>
      <Box
        sx={{
          p: 2,
          height: '78vh'
        }}>
        {/* linha 1 */}
        <Grid
          sx={{ mb: 2, display: 'flex', alignItems: 'end' }}
          container
          spacing={2}>
          {/* tipo pessoa */}
          <Grid item xs={3}>
            <Grid
              display={'flex'}
              justifyContent={'space-between'}
              item
              xs={12}>
              <FormControl
                fullWidth
                error={Boolean(
                  formik.touched.tipoPessoa && formik.errors.tipoPessoa
                )}>
                <FormLabel id="tipoPessoa">Tipo de Pessoa</FormLabel>
                <RadioGroup
                  aria-label="tipoPessoa"
                  sx={{ display: 'flex', flexDirection: 'row' }}
                  name="tipoPessoa"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tipoPessoa}
                  aria-labelledby="tipoPessoa">
                  <FormControlLabel
                    disabled={isLoading}
                    value="F"
                    control={<Radio />}
                    label="P. Física"
                  />
                  <FormControlLabel
                    disabled={isLoading}
                    value="J"
                    control={<Radio />}
                    label="P. Jurídica"
                  />
                </RadioGroup>
                {formik.touched.tipoPessoa && formik.errors.tipoPessoa && (
                  <FormHelperText error id="error-tipoPessoa">
                    {formik.errors.tipoPessoa}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {/* data cadastro */}
          <Grid item xs={3}>
            {formik.values.id && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.createdAt && formik.errors.createdAt
                  )}>
                  <DatePicker
                    label="Data Cadastro"
                    openTo="day"
                    readOnly
                    onChange={formik.handleChange}
                    value={formik.values.createdAt}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    inputFormat="DD/MM/YYYY"
                  />
                </FormControl>
              </LocalizationProvider>
            )}
          </Grid>
          {/* cnpj */}
          <Grid item xs={4}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.cnpj && formik.errors.cnpj)}>
              {/* <MaskedTextField
                mask={
                  tipoPessoa === 'F' ? '999.999.999-99' : '99.999.999/9999-99'
                }
                radix="_"
                name="cnpj"
                label="CNPJ/CPF"
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cnpj.replace(/\D/g, '')}
              /> */}
              <TextField
                name="cnpj"
                label="CNPJ/CPF"
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cnpj}
                size="small"
              />
              {formik.touched.cnpj && formik.errors.cnpj && (
                <FormHelperText error id="error-cnpj">
                  {formik.errors.cnpj}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {/* ativo */}
          <Grid item xs={2}>
            <FormControlLabel
              sx={{
                display: 'flex',
                justifyContent: 'end'
              }}
              control={
                <Switch
                  name="isActive"
                  color="primary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.isActive}
                  inputProps={{ 'aria-label': 'controlled' }}
                  data-testid="isAtivo"
                  disabled={isLoading}
                />
              }
              label="Ativo"
            />
          </Grid>
        </Grid>
        {/* linha 2 */}
        <Grid sx={{ mb: 2 }} container spacing={3}>
          {/* nome razao */}
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.nome && formik.errors.nome)}>
              <TextField
                name="nome"
                label="Razão Social/Nome"
                disabled={isLoading}
                inputProps={{ 'data-testid': 'nome' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nome}
              />
              {formik.touched.nome && formik.errors.nome && (
                <FormHelperText error id="error-nome">
                  {formik.errors.nome}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {/* apelido */}
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.apelido && formik.errors.apelido)}>
              <TextField
                name="apelido"
                label="Apelido/Fantasia"
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.apelido}
              />
              {formik.touched.apelido && formik.errors.apelido && (
                <FormHelperText error id="error-apelido">
                  {formik.errors.apelido}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        {/* linha 3 */}
        <Grid sx={{ mb: 2 }} container spacing={3}>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.cel && formik.errors.cel)}>
              {/* <InputLabel htmlFor="cel">Celular</InputLabel> */}
              <TextField
                id="cel"
                name="cel"
                label="Celular"
                // mask={[{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }]}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cel}
              />
              {formik.touched.cel && formik.errors.cel && (
                <FormHelperText error id="error-cel">
                  {formik.errors.cel}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.fone && formik.errors.fone)}>
              {/* <InputLabel htmlFor="fone">Celular</InputLabel> */}
              <TextField
                id="fone"
                name="fone"
                label="Fone 1"
                // mask={[{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }]}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fone}
              />
              {formik.touched.fone && formik.errors.fone && (
                <FormHelperText error id="error-fone">
                  {formik.errors.fone}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.fone && formik.errors.fone)}>
              {/* <InputLabel htmlFor="fone">Celular</InputLabel> */}
              <TextField
                id="fone2"
                name="fone2"
                label="Fone 2"
                // mask={[{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }]}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fone2}
              />
              {formik.touched.fone2 && formik.errors.fone2 && (
                <FormHelperText error id="error-fone">
                  {formik.errors.fone2}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        {/* linha 4 */}
        <Grid sx={{ mb: 2 }} container spacing={3}>
          {/* email */}
          <Grid item xs={4}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.email && formik.errors.email)}>
              <TextField
                name="email"
                label="E-mail"
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email || ''}
              />
              {formik.touched.email && formik.errors.email && (
                <FormHelperText error id="error-email">
                  {formik.errors.email}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {/* cidade */}
          <Grid item xs={4}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.cidade && formik.errors.cidade)}>
              <TextField
                name="cidade"
                label="Cidade"
                disabled={isLoading}
                inputProps={{ 'data-testid': 'cidade' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cidade}
              />
              {formik.touched.cidade && formik.errors.cidade && (
                <FormHelperText error id="error-cidade">
                  {formik.errors.cidade}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {/* apeuflido */}
          <Grid item xs={4}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.uf && formik.errors.uf)}>
              <TextField
                name="uf"
                label="UF"
                disabled={isLoading}
                inputProps={{ 'data-testid': 'uf' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.uf}
              />
              {formik.touched.uf && formik.errors.uf && (
                <FormHelperText error id="error-uf">
                  {formik.errors.uf}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
