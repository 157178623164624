import { boolean, object, string } from 'yup';
import { INITIAL_PERMISSAO } from '../auth/models';

export const USER_INITIAL_STATE: User = {
  id: '',
  nome: '',
  email: '',
  senha: '',
  senha2: '',
  telefone: '',
  perfil: 'USER',
  isAtivo: true,
  permissoes: INITIAL_PERMISSAO
};

export const formUserValidationSchemaInsert = object<User>({
  nome: string().required('Campo Requerido'),
  email: string().email('E-mail inválido.').required(),
  senha: string().required('Campo Requerido'),
  senha2: string().required('Campo Requerido'),
  telefone: string().required('Campo Requerido'),
  perfil: string<'user' | 'admin'>().required('Campo Requerido'),
  isAtivo: boolean()
});

export const formUserValidationSchemaEdit = object<User>({
  nome: string().required('Campo Requerido'),
  email: string().email('E-mail inválido.').required(),
  telefone: string().required('Campo Requerido'),
  perfil: string<'user' | 'admin'>().required('Campo Requerido'),
  isAtivo: boolean()
});

export interface UserResults {
  info: Info;
  usuarios: User[];
}

export interface Info {
  pagina: number;
  qtdePorPagina: number;
  clienteId: null;
  qtdeTotal: number;
}

export interface User {
  id?: string;

  nome: string;

  email: string;

  senha?: string;
  senha2?: string;

  telefone: string;

  isAtivo: boolean;

  // confirmado: boolean;

  perfil: string; // 'USER' | 'ADMIN'

  permissoes: string[];

  // contador: number;

  // version?: number;

  createdAt?: Date;

  updatedAt?: Date;
}
