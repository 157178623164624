import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import { Brightness4, Brightness7 } from '@mui/icons-material';
import { useAppSelector } from '../app/hooks';
import { selectEmpresa } from '../features/empresa/storeSlice';
import { Empresa } from '../features/empresa/models';
import Profile from './profile';
import { alpha } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';

type Props = {
  open: boolean;
  handleDrawerOpen: () => void;
  theme: string;
  toggleTheme?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Header({
  handleDrawerOpen,
  open,
  theme,
  toggleTheme
}: Props) {
  const empresa: Empresa = useAppSelector(selectEmpresa);
  return (
    <Toolbar
      sx={(t) => ({
        height: 50,
        mb: 1,
        background: alpha(t.palette.secondary.main, 1),
        color: alpha(t.palette.secondary.contrastText, 0.8),
        zIndex: 10
        // borderRadius: t.shape.borderRadius
      })}>
      {/* <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{
          marginRight: 5,
          ...(open && { display: 'none' })
        }}>
        <MenuIcon />
      </IconButton> */}
      <Typography variant="h6" noWrap component="span" fontWeight={'bold'}>
        {empresa.apelido}
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      {/* perfil usuario */}
      {toggleTheme && (
        <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
          {theme === 'dark' ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
      )}
      <Profile menuOpen={true} />
    </Toolbar>
  );
}
