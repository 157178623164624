import { apiSlice } from '../api/apiSlice';
import { Fornecedor, FornecedorSGI } from './models';

interface FindFornecedoresSGI {
  parametrosPesquisa: {
    searchFor: string;
    pesquisarNosCampos?: string;
  };
}

const endpointUrl = '/fornecedores';

const endpointUrlSGI = '/fornecedores/sgi/find';

function getFornecedores() {
  return `${endpointUrl}`;
}

function findFornecedoresSGI(params: FindFornecedoresSGI) {
  params.parametrosPesquisa.pesquisarNosCampos =
    params.parametrosPesquisa?.pesquisarNosCampos ??
    'cp.codigo, cp.nome, cp.apelido, cp.cnpj, cp.cgf';
  return { url: endpointUrlSGI, method: 'POST', body: params };
}

function getFornecedor({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function createFornecedor(user: Fornecedor) {
  return { url: endpointUrl, method: 'POST', body: user };
}

function deleteFornecedor({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

function updateFornecedor(user: Fornecedor) {
  return {
    url: `${endpointUrl}/${user.id}`,
    method: 'PUT',
    body: user
  };
}

export const fornecedorApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getFornecedores: query<Fornecedor[], void>({
      query: getFornecedores,
      providesTags: ['Fornecedores']
    }),
    getFornecedor: query<Fornecedor, { id: string }>({
      query: getFornecedor,
      providesTags: ['Fornecedores']
    }),
    createFornecedor: mutation<Fornecedor, Fornecedor>({
      query: createFornecedor,
      invalidatesTags: ['Fornecedores']
    }),
    findFornecedoresSGI: mutation<
      { fornecedores: FornecedorSGI[] },
      FindFornecedoresSGI
    >({
      query: findFornecedoresSGI,
      invalidatesTags: ['Fornecedores']
    }),
    deleteFornecedor: mutation<Fornecedor, { id: string }>({
      query: deleteFornecedor,
      invalidatesTags: ['Fornecedores']
    }),
    updateFornecedor: mutation<Fornecedor, Fornecedor>({
      query: updateFornecedor,
      invalidatesTags: ['Fornecedores']
    })
  })
});

export const {
  useGetFornecedoresQuery,
  useGetFornecedorQuery,
  useCreateFornecedorMutation,
  useFindFornecedoresSGIMutation,
  useUpdateFornecedorMutation,
  useDeleteFornecedorMutation
} = fornecedorApiSlice;
