import { useCallback, useEffect } from 'react';
import {
  Paper,
  Box,
  Typography,
  LinearProgress,
  Pagination,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
  alpha,
  IconButton
} from '@mui/material';
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid/components';
import AppConfig from '../../config/app';
import { Requisicao } from './models';
import ConstructionIcon from '@mui/icons-material/Construction';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { useNavigate } from 'react-router-dom';
import { TRequisicaoParams, useRequisicao } from './context';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { CustomNoTableRowsOverlay } from '../../components/NoCustomRowOverlay';
import { writeFile, utils } from 'xlsx';
import { GetApp } from '@mui/icons-material';
import { useEmpresa } from '../../hooks/useEmpresa';

interface Props {
  data: Requisicao[];
  isLoading: boolean;
  selectedRows?: (selectedRowsData: Requisicao[]) => void;
  handlerDesaprovar?: () => void;
}

export default function Table({
  data,
  isLoading,
  selectedRows,
  handlerDesaprovar
}: Props) {
  const navigate = useNavigate();
  const {
    filterParams,
    searchParams,
    setParams,
    paramsQtdeRows,
    paramsQtdePage,
    type
  } = useRequisicao();

  const { qtdeDigitosCampoQtde } = useEmpresa();

  const [pageSize, setPageSize] = useLocalStorage(
    'table-requisicao-pagesize',
    10
  );

  function pageSizeChange(newPageSize: number) {
    updateParams({ take: newPageSize, skip: 0 });
    setPageSize(newPageSize);
  }

  function mapDataToGridRows(data: Requisicao[]) {
    return data.map((d) => ({
      id: d.id,
      tipoReq: d.tipoReq,
      createdAt: d.createdAt
        ? new Date(d.createdAt).toLocaleDateString('pt-BR')
        : '',
      solicitante: d.solicitante?.nome,
      departamento: d.departamento?.nome,
      descricaoProdutoServico: d.descricaoProdutoServico,
      codigoSgi: d?.produtoServico?.codigoSgi,
      classe: d?.produtoServico?.classe?.descricao,
      unidade: d.unidade,
      qtde: d.qtde,
      situacao: d.situacao,
      prioridade: d.prioridade,
      obs: d.obs
    }));
  }

  const updateParams = useCallback(
    (param: Partial<TRequisicaoParams>) => {
      setParams({ ...filterParams, ...param });
    },
    [filterParams, setParams]
  );

  useEffect(() => {
    if (type === 'filter') {
      if (pageSize !== filterParams.take) updateParams({ take: pageSize });
    } else {
      if (pageSize !== searchParams.take) updateParams({ take: pageSize });
    }
  }, [filterParams.take, pageSize, searchParams.take, type, updateParams]);

  function CustomPagination() {
    // const apiRef = useGridApiContext();
    // const rows = useGridSelector(apiRef, gridRowCountSelector);

    return (
      <Box
        minWidth={'96%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={2}
        mx={2}
        px={0}>
        {/* <Typography>{selectedRows?.length} Registro(s)</Typography> */}
        {/* <Button
          onClick={handlerDesaprovar}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DoneAllIcon />}>
          Desaprovar
        </Button> */}

        {type === 'filter' ? (
          <Chip
            sx={{
              backgroundColor: 'primary.main',
              color: 'secondary.contrastText'
            }}
            icon={<FilterAltIcon />}
            label="Por Filtro"
            size="small"
          />
        ) : (
          <Chip
            sx={{
              backgroundColor: 'primary.main',
              color: 'secondary.contrastText'
            }}
            icon={<SearchIcon />}
            label="Por Texto"
            size="small"
          />
        )}
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Typography fontSize={12}>Qtde/página:</Typography>
          <RadioGroup
            sx={{ display: 'flex', flexDirection: 'row' }}
            name="controlled-radio-buttons-group"
            value={pageSize.toString()}
            onChange={(e) => {
              pageSizeChange(Number(e.target.value));
            }}>
            <FormControlLabel
              value="10"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="10"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
            <FormControlLabel
              value="25"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="25"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
            <FormControlLabel
              value="50"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="50"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
          </RadioGroup>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Pagination
            sx={{
              '.MuiPaginationItem-rounded': {
                color: '#fff'
              }
            }}
            shape="rounded"
            color="primary"
            count={paramsQtdePage}
            page={
              type === 'filter' ? filterParams.skip + 1 : searchParams.skip + 1
            }
            onChange={(event, value) => {
              let params: Partial<TRequisicaoParams> = {};
              if (type === 'filter') {
                params = {
                  ...filterParams,
                  skip: value - 1,
                  take: pageSize
                };
              } else {
                params = {
                  ...searchParams,
                  skip: value - 1,
                  take: pageSize
                };
              }
              setParams(params as TRequisicaoParams);
            }}
            showFirstButton
            showLastButton
          />
          <Typography fontSize={12}>Qtde: {paramsQtdeRows}</Typography>
        </Box>
      </Box>
    );
  }

  // function handlePageChange(page: number, details: GridCallbackDetails<any>) {
  //   console.log('handlePageChange', page, details);
  // }

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ py: 1 }}>
        <GridToolbarColumnsButton variant="text" />
        <IconButton onClick={handleExport}>
          <GetApp color="primary" />
        </IconButton>
      </GridToolbarContainer>
    );
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>('columnsRequisicaoVisibles', {
      id: true,
      tipoReq: true,
      createdAt: true,
      descricaoProdutoServico: true,
      codigoSgi: true,
      classe: true,
      solicitante: true,
      departamento: true,
      unidade: true,
      qtde: true,
      situacao: true,
      prioridade: true,
      acoes: true
    });

  const mapToExcel = data.map((d) => ({
    Req: d.id,
    Tipo: d.tipoReq,
    Data: d.createdAt ? new Date(d.createdAt).toLocaleDateString('pt-BR') : '',
    Solicitante: d.solicitante?.nome,
    Departamento: d.departamento?.nome,
    Descricao: d.descricaoProdutoServico,
    'Código-sgi': d?.produtoServico?.codigoSgi,
    Classe: d?.produtoServico?.classe?.descricao,
    Unidade: d.unidade,
    Qtde: d.qtde,
    Situação: d.situacao?.nome,
    Prioridade: d.prioridade?.nome,
    Obs: d.obs
  }));

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'CotacaoRequisicoes.xlsx');
  }

  return (
    <Paper>
      <Box height="80vh">
        <DataGrid
          sx={(style) => ({
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              fontSize: 11,
              backgroundColor: style.palette.secondary.main,
              color: style.palette.secondary.contrastText
            },
            '& .MuiDataGrid-columnSeparator': {
              color: style.palette.secondary.contrastText
            },
            fontSize: 13,
            overflow: 'hidden',
            '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
              color: style.palette.secondary.contrastText,
              borderRadius: 0
            },
            '& .MuiDataGrid-columnHeaders': {
              borderRadius: 0
            },
            '& .super-app-theme--true': {
              bgcolor: (theme) => alpha(theme.palette.success.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.success.main
              }
            },
            '& .super-app-theme--false': {
              bgcolor: (theme) => alpha(theme.palette.warning.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.warning.main
              }
            },
            '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
              opacity: 1,
              color: style.palette.secondary.contrastText
            }
          })}
          localeText={{
            toolbarColumnsLabel: 'Colunas',
            toolbarColumns: '',
            toolbarExport: '',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Exportar para CSV',
            toolbarExportPrint: 'Imprimir',
            detailPanelToggle: 'Expandir',
            columnsPanelHideAllButton: 'Ocultar tudo',
            columnsPanelShowAllButton: 'Mostrar tudo',
            columnsPanelTextFieldLabel: 'Localizar coluna',
            columnsPanelTextFieldPlaceholder: 'Título da coluna'
          }}
          loading={isLoading}
          // paginationMode="server"
          rows={data ? mapDataToGridRows(data) : []}
          density="compact"
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 10,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'tipoReq',
              headerName: 'Tipo',
              width: 40,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              renderCell: (params) => {
                if (params.row.tipoReq === 'P') {
                  return <QrCode2Icon titleAccess="Produto" />;
                }

                if (params.row.tipoReq === 'S') {
                  return <ConstructionIcon titleAccess="Serviço" />;
                }
              }
            },
            {
              field: 'createdAt',
              headerName: 'Data',
              width: 90,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'codigoSgi',
              headerName: 'Código SGI',
              width: 80,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'descricaoProdutoServico',
              headerName: 'Produto/Serviço',
              flex: 1,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'classe',
              headerName: 'Classe',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'solicitante',
              headerName: 'Solicitante',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'departamento',
              headerName: 'Destino',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'unidade',
              headerName: 'Und',
              width: 60,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'qtde',
              headerName: 'Qtde',
              align: 'center',
              width: 60,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              // campo:qtdeDigitosCampoQtde
              valueFormatter(params) {
                return `${params.value.toLocaleString('pt-BR', {
                  minimumFractionDigits:
                    qtdeDigitosCampoQtde > 2 ? qtdeDigitosCampoQtde : 0,
                  maximumFractionDigits: qtdeDigitosCampoQtde || 0
                })}`;
              }
            },
            {
              field: 'situacao',
              headerName: 'Situação',
              width: 100,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              valueFormatter: ({ value }) => `${value.nome}`,
              renderCell: (params) => {
                return (
                  <Typography
                    fontSize={10}
                    sx={(theme) => ({
                      color: params.row.situacao?.corLetra || '#000000',
                      background: params.row.situacao?.corFundo,
                      py: theme.spacing(0.5),
                      px: theme.spacing(1.5),
                      borderRadius: theme.shape.borderRadius
                    })}>
                    {params.row.situacao?.nome}
                  </Typography>
                );
              }
            },
            {
              field: 'prioridade',
              headerName: 'Prioridade',
              width: 90,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              valueFormatter: ({ value }) => `${value.nome}`,
              sortComparator: (v1, v2) =>
                v1.prioridade.nome.localeCompare(v2.prioridade.nome),
              renderCell: (params) => {
                return (
                  <Typography
                    fontSize={10}
                    sx={(theme) => ({
                      color: params.row.prioridade?.corLetra || '#000000',
                      background: params.row.prioridade?.corFundo,
                      py: theme.spacing(0.5),
                      px: theme.spacing(1.5),
                      borderRadius: theme.shape.borderRadius
                    })}>
                    {params.row.prioridade?.nome}
                  </Typography>
                );
              }
            }
            // {
            //   field: 'obs',
            //   headerName: 'Obs',
            //   width: 100,
            //   sortable: true,
            //   editable: false,
            //   filterable: false,
            //   hideable: true,
            //   disableColumnMenu: true
            // }
            // {
            //   field: 'id',
            //   headerName: 'Ações',
            //   width: 100,
            //   disableColumnMenu: true,
            //   sortable: true,
            //   disableExport: true,
            //   renderCell: RenderActionsCell
            // }
          ]}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          // disableColumnFilter
          // disableColumnSelector
          disableDensitySelector
          // disableColumnMenu
          components={{
            // Toolbar: GridToolbar,
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoTableRowsOverlay
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              showColumnsButton: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                delimiter: ';',
                utf8WithBom: true,
                fileName: 'sistema-cotação'
              }
            },
            footer: {
              sx: {
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText',
                '& .MuiPaginationItem-root': {
                  fontSize: 12
                },
                '& *': {
                  color: 'secondary.contrastText'
                }
              }
            }
          }}
          pageSize={pageSize}
          rowCount={paramsQtdeRows}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginationMode="server"
          // onPageChange={handlePageChange}
          hideFooterSelectedRowCount
          // checkboxSelection
          disableSelectionOnClick
          // isRowSelectable={(params: GridRowParams) =>
          //   params.row.situacao.id === 2
          // }
          onSelectionModelChange={(ids) => {
            if (!selectedRows) return;
            const sets = ids.map((id) => Number(id));
            const selectedIDs = new Set(sets);
            if (!data) return;
            const selectedRowsData = data.filter((row) => {
              if (row.id) return selectedIDs.has(row.id);
            });

            selectedRows(selectedRowsData);
          }}
          onRowDoubleClick={(params: GridRowParams) =>
            navigate(AppConfig.routes.cadRequisicaoEdit(params.id as string), {
              state: { goBack: AppConfig.routes.cadRequisicao }
            })
          }
        />
      </Box>
    </Paper>
  );
}
