import { Fornecedor } from '../fornecedor/models';
import { Requisicao } from '../requisicao/models';
import { Situacao } from '../situacao/models';

export interface ICotacaoFilters {
  situacaoId?: number[];
  created_at?: [string | null, string | null];
}

export const COTACAO_INITIAL_FILTERS: ICotacaoFilters = {
  situacaoId: [],
  created_at: [null, null]
};
export interface Cotacao {
  id: number;
  situacao: Situacao;
  obs: string;
  fornecedores: CotacaoFornecedor[];
  requisicoes: CotacaoRequisicao[];
  itens: CotacaoItem[];
  createdAt: string;
  updatedAt: string;
}

export interface CotacaoFornecedor {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  fornecedor: Fornecedor;
  email?: string;
  emailEnviado?: boolean;
  fone: string;
  isAtivo: boolean;
  path: string;
  validade: string;
  uuid: string;
  pedidoGerado: boolean;
}

export interface CotacaoRequisicao {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  requisicao: Requisicao;
}

export interface CotacaoItem {
  id: number;
  situacao: Situacao;
  cotacao: Cotacao;
  fornecedor: Fornecedor;
  requisicao: Requisicao;
  qtde: number;
  preco: number;
  desconto: number;
  vDesconto?: number;
  precoLiquido: number;
  icms: number;
  vIcms: number;
  ipi: number;
  vIpi: number;
  frete: number;
  vFrete: number;
  outrasDespesas: number;
  vOutrasDespesas?: number;
  precoComImposto: number;
  totalItem: number;
  seuCodigo: string;
  previsao: string | null;
  obs: string;
  isSelected: boolean;
  createdAt: Date;
  updatedAt: Date;
  isMenorPreco: boolean;

  // id: number;
  // fornecedor: Fornecedor;
  // requisicao: Requisicao;
  // situacao: Situacao;
  // qtde: number;
  // preco: number;
  // precoComImposto: number;
  // previsao: Date;
  // total: number;
  // obs: string;
  // frete: number;
  // icms: number;
  // ipi: number;
  // outrasDespesas: number;
  // seuCodigo: string;
  // isSelected: boolean;
  // createdAt: Date;
  // updatedAt: Date;
}

export function calculateTotalItem(item: CotacaoItem) {
  const icms = item.preco * (item.icms / 100);
  const ipi = item.preco * (item.ipi / 100);
  const frete = item.preco * (item.frete / 100);
  const outrasDespesas = item.preco * (item.outrasDespesas / 100);
  const precoComImposto = item.preco + icms + ipi + frete + outrasDespesas;
  return Math.round(precoComImposto * item.qtde * 100) / 100;
}
