import { object, string } from 'yup';

export interface Fornecedor {
  id: number | undefined;
  tipoPessoa: string; // 'F' | 'J'
  nome: string;
  apelido: string;
  fone: string;
  fone2: string;
  cel: string;
  email: string;
  cnpj: string;
  cidade: string;
  uf: string;
  isActive: boolean;
  isVisible: boolean;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

export interface FornecedorSGI {
  codigo: string;
  nome: string;
  apelido: string;
  tipopessoa: string;
  endereco: string;
  logrcomplemento: string;
  bairro: string;
  cidade: string;
  cep: string;
  uf: string;
  fone1: null;
  fone2: null;
  cel: null;
  cnpj: string;
  cgf: string;
  email?: string | null;
  grupo: string;
  formapgto: string;
  condicaopagamento: string;
  codigopalm: number;
  cpsimplesnacional: string;
}

export const FORNECEDOR_INITIAL_STATE: Fornecedor = {
  id: undefined,
  nome: '',
  apelido: '',
  tipoPessoa: 'F',
  cidade: '',
  uf: '',
  fone: '',
  fone2: '',
  cel: '',
  cnpj: '',
  email: '',
  isActive: true,
  isVisible: true
};

export const formFornecedorValidationSchemaInsert = object({
  nome: string().required('Campo Requerido'),
  tipoPessoa: string(),
  cidade: string().optional(),
  email: string().optional(),
  uf: string().optional(),
  cnpj: string().required('Campo Requerido'),
  fone: string().optional()
});

export const formFornecedorValidationSchemaEdit = object({
  nome: string().required('Campo Requerido'),
  tipoPessoa: string(),
  cidade: string().optional(),
  email: string().optional(),
  uf: string().optional(),
  cnpj: string().required('Campo Requerido'),
  fone: string().optional()
});
