import { object, string } from 'yup';

export const SOLICITANTE_INITIAL_STATE: Solicitante = {
  id: undefined,
  nome: '',
  // numeroSerie: '',
  ordenacao: 1,
  isActive: true,
  isVisible: true
};

export const formSolicitanteValidationSchemaInsert = object({
  nome: string().required('Campo Requerido')
});

export const formSolicitanteValidationSchemaEdit = object({
  nome: string().required('Campo Requerido')
});

export interface Solicitante {
  id?: number;
  nome: string;
  // numeroSerie: string;
  ordenacao: number;
  isActive: boolean;
  isVisible: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}
