/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { IconButton, Stack, alpha } from '@mui/material';
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridColumns,
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@mui/x-data-grid';
import { GetApp } from '@mui/icons-material';
import { writeFile, utils } from 'xlsx';
import { CustomNoTableRowsOverlay } from '../../../components/NoCustomRowOverlay';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

interface Props {
  data: any[];
  columns: GridColumns<any>;
  isLoading?: boolean;
  selectedRows?: any[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
  headerElements?: React.ReactNode[];
}

function CustomToolbar(data: any, elements: React.ReactNode[]) {
  function handleExport() {
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'CotacaoRequisicoes.xlsx');
  }

  return (
    <GridToolbarContainer sx={{ py: 1, display: 'flex' }}>
      <Stack direction="row" spacing={1} sx={{ flexGrow: 1, gap: 1 }}>
        <GridToolbarColumnsButton variant="text" />
        <IconButton onClick={handleExport}>
          <GetApp color="primary" />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        sx={{ flexGrow: 1, gap: 1, justifyContent: 'end' }}>
        {elements}
      </Stack>
    </GridToolbarContainer>
  );
}

export default function DataGridRequisicao({
  data,
  isLoading,
  columns,
  selectedRows,
  setSelectedRows,
  headerElements = []
}: Props) {
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>(
      'columnsRequisicaoAprovacaoVisibles',
      columns.reduce((acc, column) => {
        acc[column.field as string] = true;
        return acc;
      }, {} as GridColumnVisibilityModel)
    );

  return (
    <DataGrid
      sx={(style) => ({
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          fontSize: 13,
          backgroundColor: style.palette.secondary.main,
          color: style.palette.secondary.contrastText
        },
        '& .MuiDataGrid-columnSeparator': {
          color: style.palette.secondary.contrastText
        },
        fontSize: 11,
        overflow: 'hidden',
        '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
          color: style.palette.secondary.contrastText,
          borderRadius: 0
        },
        '& .MuiDataGrid-columnHeaders': {
          borderRadius: 0
        },
        '& .super-app-theme--true': {
          bgcolor: (theme) => alpha(theme.palette.success.light, 0.3),
          '&:hover': {
            bgcolor: (theme) => theme.palette.success.main
          }
        },
        '& .super-app-theme--false': {
          bgcolor: (theme) => alpha(theme.palette.warning.light, 0.3),
          '&:hover': {
            bgcolor: (theme) => theme.palette.warning.main
          }
        },
        '& .super-app-theme--gray': {
          bgcolor: (theme) => theme.palette.grey[300],
          color: (theme) => theme.palette.grey[600],
          '&:hover': {
            bgcolor: (theme) => theme.palette.grey[400],
            color: (theme) => theme.palette.grey[600]
          }
        },
        '& .super-app-theme--parcial': {
          bgcolor: (theme) => alpha(theme.palette.warning.light, 0.3),
          '&:hover': {
            bgcolor: (theme) => theme.palette.warning.main
          }
        },
        '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
          opacity: 1,
          color: style.palette.secondary.contrastText
        }
      })}
      loading={isLoading}
      rowCount={data?.length ?? 0}
      getRowClassName={(params) => {
        if (params.row.situacaoId === 5) {
          return 'super-app-theme--gray';
        }
        if (params.row.situacaoId === 8) {
          return 'super-app-theme--parcial';
        }
        return '';
      }}
      paginationMode="server"
      // isRowSelectable={(params) => params.row.situacaoId !== 5}
      rows={data ?? []}
      density="compact"
      columns={[
        {
          field: '__check__',
          hideable: false,
          sortable: false,
          width: 50,
          disableColumnMenu: true,
          disableExport: true,
          headerName: 'Selecionar'
        },
        ...columns
      ]}
      disableColumnFilter
      disableDensitySelector
      disableColumnMenu
      components={{
        Toolbar: () => CustomToolbar(data, headerElements),
        // LoadingOverlay: LinearProgress,
        NoRowsOverlay: CustomNoTableRowsOverlay
      }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          showColumnsButton: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: {
            delimiter: ';',
            utf8WithBom: true,
            fileName: 'sistema-cotação'
          }
        },
        footer: {
          sx: {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            '& .MuiPaginationItem-root': {
              fontSize: 12
            },
            '& *': {
              color: 'secondary.contrastText'
            }
          }
        }
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) =>
        setColumnVisibilityModel(newModel)
      }
      // rowsPerPageOptions={[10, 25, 50, 100]}
      // paginationMode="server"
      // onPageChange={handlePageChange}
      checkboxSelection={!!setSelectedRows}
      disableSelectionOnClick
      // isRowSelectable={(params: GridRowParams) =>
      //   params.row.situacao.id === 3
      // }
      selectionModel={selectedRows?.map((row) => row.id) ?? []}
      onSelectionModelChange={(ids) => {
        if (!selectedRows) return;
        const selectedIDs = new Set(ids);
        if (!data) return;
        const selectedRowsData = data.filter((row) => {
          if (row.id) return selectedIDs.has(row.id);
        });

        if (setSelectedRows) setSelectedRows(selectedRowsData);
      }}
      // onRowDoubleClick={(params: GridRowParams) => {
      //   navigate(AppConfig.routes.cadPendenciaEdit(params.id as string));
      // }}
      hideFooter
      localeText={{
        toolbarColumnsLabel: 'Colunas',
        toolbarColumns: '',
        toolbarExport: '',
        toolbarExportLabel: 'Exportar',
        toolbarExportCSV: 'Exportar para CSV',
        toolbarExportPrint: 'Imprimir',
        detailPanelToggle: 'Expandir',
        columnsPanelHideAllButton: 'Ocultar tudo',
        columnsPanelShowAllButton: 'Mostrar tudo',
        columnsPanelTextFieldLabel: 'Localizar coluna',
        columnsPanelTextFieldPlaceholder: 'Título da coluna'
      }}
    />
  );
}
