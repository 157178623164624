import { CotacaoFornecedor } from '../models';
import { Box, Paper, Typography, Stack } from '@mui/material';
import CotacaoCanceladaImg from '../../../assets/svg/cotacao_cancelada.min.svg';
import { useLocation } from 'react-router-dom';

export default function CotacaoCancelada() {
  const location = useLocation();

  const { fornecedorCotacao } = location.state as {
    fornecedorCotacao: CotacaoFornecedor;
    limitTime: number;
  };

  return (
    <Box width={'98vw'} margin={'0 auto'}>
      <Paper sx={{ p: 2, my: 1 }}>
        {/* fornecedor */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography fontSize={12} variant="h6" component="span" gutterBottom>
            Fornecedor
          </Typography>
          <Typography fontSize={12} variant="h6" component="span" gutterBottom>
            Validade
          </Typography>
        </Box>
        {/* expiracao */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Stack gap={0}>
            <Typography
              fontSize={16}
              fontWeight={'bold'}
              variant="h6"
              component="span"
              gutterBottom>
              {fornecedorCotacao?.fornecedor.nome}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={'bold'}
              variant="h6"
              component="span"
              gutterBottom>
              CNPJ: {fornecedorCotacao?.fornecedor.cnpj} -{' '}
              {fornecedorCotacao?.fornecedor.cidade}/
              {fornecedorCotacao?.fornecedor.uf}
            </Typography>
          </Stack>
        </Box>
      </Paper>
      <Paper sx={{ p: 2, my: 1 }}>
        <Box
          flex={1}
          height="calc(100vh - 230px)"
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}>
          <Stack gap={2} alignItems={'center'}>
            <img src={CotacaoCanceladaImg} height={'300vh'} width={'100%'} />
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}
