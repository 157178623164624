import { Add, NavigateNext } from '@mui/icons-material';
import { Box, Button, Switch, TextField, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';

import { Link, useNavigate } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { Categoria } from './models';
import { useGetCategoriasQuery } from './apiSlice';
import Paper from '@mui/material/Paper';
import { useCallback, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function Categorias() {
  const { isLoading, data } = useGetCategoriasQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    {},
    {
      refetchOnMountOrArgChange: true
    }
  );
  const navigate = useNavigate();

  const [filterCateg, setFilterCateg] = useState<string>('');

  const mapDataToGridRows = useCallback(
    (data: Categoria[]) => {
      return data
        .map((d) => ({
          id: d.id,
          descricao: d.descricao,
          ativo: d.ativo
        }))
        .filter((d) =>
          d.descricao.toLowerCase().includes(filterCateg.toLowerCase())
        );
    },
    [filterCateg]
  );

  return (
    <Inner>
      <Box
        sx={{ my: 1 }}
        display="flex"
        justifyContent="space-between"
        alignItems={'center'}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography>Cadastro</Typography>
          <Typography fontWeight={'bold'}>Categorias</Typography>
        </Breadcrumbs>
        <Box display="flex" gap={4} width={600}>
          <TextField
            fullWidth
            label="Filtro"
            size="small"
            placeholder="Digite o nome da categoria"
            value={filterCateg}
            onChange={(e) => setFilterCateg(e.target.value)}
          />
          <Button
            style={{ width: 140 }}
            startIcon={<Add />}
            component={Link}
            variant="contained"
            to={AppConfig.routes.cadCategoriaInsert}>
            Inserir
          </Button>
        </Box>
      </Box>
      <Paper>
        <Box height="78vh">
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: 12
              },
              fontSize: 12
            }}
            loading={isLoading}
            rows={data ? mapDataToGridRows(data) : []}
            columns={[
              {
                field: 'descricao',
                headerName: 'Nome',
                flex: 1,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                },
                disableColumnMenu: true
              },
              {
                field: 'ativo',
                headerName: 'Ativo',
                width: 100,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                renderCell(params: GridRenderCellParams) {
                  return <Switch checked={params.value} />;
                },
                disableColumnMenu: true
              }
              // {
              //   field: 'id',
              //   headerName: 'Ações',
              //   width: 100,
              //   disableColumnMenu: true,
              //   sortable: false,
              //   renderCell: RenderActionsCell
              // }
            ]}
            // pageSize={10}
            onRowDoubleClick={(params: GridRowParams) =>
              navigate(AppConfig.routes.cadCategoriaEdit(params.id as string))
            }
          />
        </Box>
      </Paper>
    </Inner>
  );
}
