import { useEffect, useMemo, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  LinearProgress,
  Pagination,
  FormControlLabel,
  Radio,
  RadioGroup,
  alpha,
  IconButton
} from '@mui/material';
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@mui/x-data-grid';
import AppConfig from '../../../config/app';
import { ProdutoCadastro } from '../models';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { CustomNoTableRowsOverlay } from '../../../components/NoCustomRowOverlay';
import { writeFile, utils } from 'xlsx';
import { GetApp } from '@mui/icons-material';
import { useProduto } from '../context';
import ProdutoColumns from './columns';

interface Props {
  data: ProdutoCadastro[];
  isLoading: boolean;
}

// campos da tabela
// id: number;
// codigoSgi: string;
// descricao: string;
// descricaoCompleta: null;
// referencia: string;
// unidade: string;
// preco: number;
// peso: number;
// exibeNaPesquisa: number;
// classe: Classe;

export default function Table({ isLoading, data }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // const { qtdeDigitosCampoQtde } = useEmpresa();
  const { take, page, qtdePag, qtdeReg, getParams } = useProduto();
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [currentPageSize, setCurrentPageSize] = useState<number>(take);

  useEffect(() => {
    const params = getParams({ page: currentPage });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSearchParams(params as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setTimeout(() => {
      const params = getParams({ take: currentPageSize, page: 1 });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setSearchParams(params as any);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSize]);

  function CustomPagination() {
    // const apiRef = useGridApiContext();
    // const rows = useGridSelector(apiRef, gridRowCountSelector);

    return (
      <Box
        minWidth={'96%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={2}
        mx={2}
        px={0}>
        {/* <Typography>{selectedRows?.length} Registro(s)</Typography> */}
        {/* <Button
          onClick={handlerDesaprovar}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DoneAllIcon />}>
          Desaprovar
        </Button> */}

        {/* {type === 'filter' ? (
          <Chip
            sx={{
              backgroundColor: 'primary.main',
              color: 'secondary.contrastText'
            }}
            icon={<FilterAltIcon />}
            label="Por Filtro"
            size="small"
          />
        ) : (
          <Chip
            sx={{
              backgroundColor: 'primary.main',
              color: 'secondary.contrastText'
            }}
            icon={<SearchIcon />}
            label="Por Texto"
            size="small"
          />
        )} */}
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Typography fontSize={12}>Qtde/página:</Typography>
          <RadioGroup
            sx={{ display: 'flex', flexDirection: 'row' }}
            name="controlled-radio-buttons-group"
            value={take}
            onChange={(e, value) => {
              setCurrentPageSize(Number(value));
            }}>
            <FormControlLabel
              value="10"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="10"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
            <FormControlLabel
              value="25"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="25"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
            <FormControlLabel
              value="50"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="50"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
          </RadioGroup>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Pagination
            sx={{
              '.MuiPaginationItem-rounded': {
                color: '#fff'
              }
            }}
            shape="rounded"
            color="primary"
            count={qtdePag}
            defaultPage={1}
            page={currentPage}
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
            showFirstButton
            showLastButton
          />
          <Typography fontSize={12}>Qtde: {qtdeReg ?? 0}</Typography>
        </Box>
      </Box>
    );
  }

  // function handlePageChange(page: number, details: GridCallbackDetails<any>) {
  //   console.log('handlePageChange', page, details);
  // }

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ py: 1 }}>
        <GridToolbarColumnsButton variant="text" />
        <IconButton onClick={handleExport}>
          <GetApp color="primary" />
        </IconButton>
      </GridToolbarContainer>
    );
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>('columnsProdutoVisibles', {
      id: true,
      classe: true,
      codigo: true,
      descricao: true,
      unidade: true,
      referencia: true,
      preco: true,
      peso: true
      // exibeNaPesquisa: true
    });

  const dataMap = useMemo(() => {
    if (!data) return [];
    return data.map((d) => ({
      id: d.id,
      codigo: d?.codigoSgi ?? '',
      classe: d?.classe?.descricao ?? '',
      descricao: d?.descricao ?? '',
      descricaoCompleta: d?.descricaoCompleta ?? '',
      unidade: d?.unidade ?? '',
      referencia: d?.referencia ?? '',
      preco: d?.preco ?? 0,
      peso: d?.peso ?? 0,
      exibeNaPesquisa: d.exibeNaPesquisa === 1 ? true : false
    }));
  }, [data]);

  function handleExport() {
    const ws = utils.json_to_sheet([...dataMap]);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'CotacaoProdutos');
    writeFile(wb, 'CotacaoProdutos.xlsx');
  }

  return (
    <Paper>
      <Box height="80vh">
        <DataGrid
          sx={(style) => ({
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              fontSize: 11,
              backgroundColor: style.palette.secondary.main,
              color: style.palette.secondary.contrastText
            },
            '& .MuiDataGrid-columnSeparator': {
              color: style.palette.secondary.contrastText
            },
            fontSize: 13,
            overflow: 'hidden',
            '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
              color: style.palette.secondary.contrastText,
              borderRadius: 0
            },
            '& .MuiDataGrid-columnHeaders': {
              borderRadius: 0
            },
            '& .super-app-theme--true': {
              bgcolor: (theme) => alpha(theme.palette.success.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.success.main
              }
            },
            '& .super-app-theme--false': {
              bgcolor: (theme) => alpha(theme.palette.warning.light, 0.3),
              '&:hover': {
                bgcolor: (theme) => theme.palette.warning.main
              }
            },
            '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
              opacity: 1,
              color: style.palette.secondary.contrastText
            }
          })}
          localeText={{
            toolbarColumnsLabel: 'Colunas',
            toolbarColumns: '',
            toolbarExport: '',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Exportar para CSV',
            toolbarExportPrint: 'Imprimir',
            detailPanelToggle: 'Expandir',
            columnsPanelHideAllButton: 'Ocultar tudo',
            columnsPanelShowAllButton: 'Mostrar tudo',
            columnsPanelTextFieldLabel: 'Localizar coluna',
            columnsPanelTextFieldPlaceholder: 'Título da coluna'
          }}
          loading={isLoading}
          // paginationMode="server"
          rows={dataMap}
          density="compact"
          columns={ProdutoColumns()}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          // disableColumnFilter
          // disableColumnSelector
          disableDensitySelector
          // disableColumnMenu
          components={{
            // Toolbar: GridToolbar,
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoTableRowsOverlay
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              showColumnsButton: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                delimiter: ';',
                utf8WithBom: true,
                fileName: 'sistema-cotação'
              }
            },
            footer: {
              sx: {
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText',
                '& .MuiPaginationItem-root': {
                  fontSize: 12
                },
                '& *': {
                  color: 'secondary.contrastText'
                }
              }
            }
          }}
          pageSize={currentPageSize}
          rowCount={qtdeReg}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginationMode="server"
          hideFooterSelectedRowCount
          onRowDoubleClick={(params: GridRowParams) =>
            navigate(AppConfig.routes.cadProdutoEdit(params.id as string), {
              state: { goBack: AppConfig.routes.cadProduto }
            })
          }
        />
      </Box>
    </Paper>
  );
}
