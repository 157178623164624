import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  alpha
} from '@mui/material';
import { Empresa } from '../empresa/models';
import { useEffect, useState } from 'react';
import { FormikProps } from 'formik';

interface Props {
  value: number;
  index: number;
  data: Empresa;
  formik: FormikProps<Partial<any>>;
}
export default function TabEmpresa({ value, index, data, formik }: Props) {
  const [colors, setColors] = useState({
    primary: '',
    secondary: ''
  });

  useEffect(() => {
    // replace scpace at vazio
    const lightTheme = JSON.parse(data.themeLigth || '{}');
    // const darkTheme = JSON.parse(data.themeDark) || {};
    const newTheme = {
      primary: lightTheme?.colors?.primary ?? '',
      secondary: lightTheme?.colors?.secondary ?? ''
    };
    setColors(newTheme);
  }, [data]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} columns={2}>
            {/* nome */}
            <Grid item xs={1}>
              <Typography>Empresa: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{data.nome}</Typography>
              </Box>
            </Grid>

            {/* apelido */}
            <Grid item xs={1}>
              <Typography>Nome Fantasia: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{data.apelido}</Typography>
              </Box>
            </Grid>

            {/* endereço */}
            <Grid item xs={1}>
              <Typography>Endereço: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{data.endereco}</Typography>
              </Box>
            </Grid>

            {/* cor primaria */}
            {
              <>
                <Grid item xs={1}>
                  <Typography>Cor Principal: </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Box
                    sx={(theme) => ({
                      background:
                        colors?.primary ??
                        alpha(theme.palette.background.default, 1),
                      py: 1,
                      px: 2,
                      borderRadius: theme.shape.borderRadius
                    })}>
                    <Typography>{colors?.primary ?? ''}</Typography>
                  </Box>
                </Grid>
              </>
            }

            {/* cor secundaria */}
            <Grid item xs={1}>
              <Typography>Cor Secundária: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background:
                    colors.secondary ??
                    alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{colors?.secondary ?? ''}</Typography>
              </Box>
            </Grid>

            {/* qtdeDigitosCampoQtde */}
            {/* <Grid item xs={1}>
              <Typography>Número de Dígitos Campo Quantidade:</Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.qtdeDigitosCampoQtde &&
                      formik.errors.qtdeDigitosCampoQtde
                  )}>
                  <TextField
                    name="qtdeDigitosCampoQtde"
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.qtdeDigitosCampoQtde}
                  />
                  {formik.touched.qtdeDigitosCampoQtde &&
                    formik.errors.qtdeDigitosCampoQtde && (
                      <FormHelperText error id="error-from">
                        {String(formik.errors.qtdeDigitosCampoQtde)}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
            </Grid> */}

            {/* qtdeDigitosCampoUnitário */}
            {/* <Grid item xs={1}>
              <Typography>Número de Dígitos Campo Unitário:</Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.qtdeDigitosCampoUnitário &&
                      formik.errors.qtdeDigitosCampoUnitário
                  )}>
                  <TextField
                    name="qtdeDigitosCampoUnitário"
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.qtdeDigitosCampoUnitário}
                  />
                  {formik.touched.qtdeDigitosCampoUnitário &&
                    formik.errors.qtdeDigitosCampoUnitário && (
                      <FormHelperText error id="error-from">
                        {formik.errors.qtdeDigitosCampoUnitário as string}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      )}
    </div>
  );
}
