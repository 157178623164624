import { Download, NavigateNext } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import UploadIcon from '@mui/icons-material/Upload';

import { Link } from 'react-router-dom';

import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  PRODUTO_INITIAL_FORM,
  PRODUTO_VALIDATION_FORM,
  ProdutoCadastro
} from '../models';
import { OUTLET_HEIGHT } from '../../../config/theme';
import { useAddProdutoLoteMutation, useAddProdutoMutation } from '../apiSlice';
import AppConfig from '../../../config/app';
import { ProdutoForm } from '../components/form';
import { writeFile, utils, read } from 'xlsx';
import { useGetCategoriasQuery } from '../../categoria/apiSlice';
import { ReactComponent as ExcelLogo } from '../../../assets/svg/excel.svg';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

const steps = [
  'Passo 1 - Baixar modelo(excel)',
  'Passo 2 - Fazer upload do arquivo'
];

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export default function ProdutoInsert() {
  const { enqueueSnackbar } = useSnackbar();
  const [createProduto, status] = useAddProdutoMutation();

  const [addLote] = useAddProdutoLoteMutation();

  const { data: categoriasData } = useGetCategoriasQuery();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  const [listProdutoUpload, setListProdutoUpload] = useState<any[]>([]);

  const [fileUploadName, setFileUploadName] = useState<string>();

  const [activeStep, setActiveStep] = useState(0);

  function handleDownloadModelo() {
    // campos da tabela
    // codigoSgi: string;
    // descricao: string;
    // descricaoCompleta: null;
    // referencia: string;
    // unidade: string;
    // preco: number;
    // peso: number;
    // exibeNaPesquisa: number;
    // classe: Classe;
    const produtos = utils.json_to_sheet([
      {
        codigo: '1111',
        descricao: 'Descrição do produto ou serviço 1',
        descricaoCompleta: 'Descrição detahada do produto 1',
        referencia: 'ref1',
        unidade: 'UN',
        peso: 1.1,
        preco: 10.5,
        categoriaCodigo: '1'
      }
    ]);

    const categorias = utils.json_to_sheet(
      categoriasData?.map((categoria) => ({
        codigo: categoria.id,
        nome: categoria.descricao
      })) || []
    );
    const wb = utils.book_new();
    utils.book_append_sheet(wb, produtos, 'Produtos');
    utils.book_append_sheet(wb, categorias, 'Categorias');
    writeFile(wb, 'CotacaoModeloUploadProdutos.xlsx');
  }

  async function handleUploadModelo(event: any) {
    try {
      const file = event.target.files[0];
      if (!file) {
        setFileUploadName(undefined);
        return;
      }

      setFileUploadName(file.name);

      const ab = await file.arrayBuffer();

      const reader = new FileReader();
      reader.onload = (e) => {
        const wb = read(ab);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const data = utils.sheet_to_json(ws);
        setListProdutoUpload(data);
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Erro ao fazer upload do arquivo', { variant: 'error' });
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
      setFileUploadName(undefined);
    }

    if (activeStep === 1 && !fileUploadName) {
      enqueueSnackbar('Selecione um arquivo para continuar', {
        variant: 'error'
      });
    } else {
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 2) {
      setActiveStep(2);
      // criar produtos
      const produtos = listProdutoUpload.map((produto) => ({
        codigoSgi: produto.codigo,
        descricao: produto.descricao,
        descricaoCompleta: produto.descricaoCompleta,
        referencia: produto.referencia,
        unidade: produto.unidade,
        preco: produto.preco,
        peso: produto.peso,
        categoria: {
          id: produto.categoriaCodigo
        }
      }));

      addLote(produtos)
        .then((resp: any) => {
          if (resp.error) {
            enqueueSnackbar('Erro ao inserir produtos', {
              variant: 'error'
            });
            return;
          }
          enqueueSnackbar('Produtos inseridos com sucesso', {
            variant: 'success'
          });
          setOpen(false);
          setActiveStep(0);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao inserir produtos', {
            variant: 'error'
          });
          setOpen(true);
          setActiveStep(2);
        });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // const handleLoteInsert = useCallback(() => {
  //   const produtos = listProdutoUpload.map((produto) => ({
  //     codigoSgi: produto.codigo,
  //     descricao: produto.descricao,
  //     descricaoCompleta: produto.descricaoCompleta,
  //     referencia: produto.referencia,
  //     unidade: produto.unidade,
  //     preco: produto.preco,
  //     peso: produto.peso,
  //     categoria: {
  //       id: produto.categoriaCodigo
  //     }
  //   }));

  //   addLote(produtos)
  //     .then(() => {
  //       enqueueSnackbar('Produtos inseridos com sucesso', {
  //         variant: 'success'
  //       });
  //     })
  //     .catch(() => {
  //       enqueueSnackbar('Erro ao inserir produtos', {
  //         variant: 'error'
  //       });
  //     });
  // }, [addLote, listProdutoUpload]);

  const formik = useFormik({
    initialValues: PRODUTO_INITIAL_FORM,
    validationSchema: PRODUTO_VALIDATION_FORM,
    onSubmit: (values: any) => {
      delete values.id;
      createProduto(values);
    }
  });

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  useEffect(() => {
    if (status.isSuccess) {
      enqueueSnackbar('Produto criado com sucesso', { variant: 'success' });
      formik.resetForm();
    }
    if (status.error) {
      enqueueSnackbar('Error ao criar Produto', { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, status.error, status.isSuccess]);

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadProduto}>
              <Typography>Produtos</Typography>
            </Link>
            <Typography>Inserir</Typography>
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadProduto}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            <Button
              type="button"
              onClick={handleClick('bottom-end')}
              startIcon={<UploadIcon />}
              color="info"
              variant="contained">
              Inserir Por Lote
            </Button>
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Paper>
          <Box height="80vh" padding="10px 0">
            <ProdutoForm formik={formik} isLoading={status.isLoading} />
          </Box>
        </Paper>
      </form>
      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box sx={{ px: 4, py: 2 }}>
                <Stepper
                  sx={{ pt: 2 }}
                  activeStep={activeStep}
                  alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Fragment>
                  {activeStep === steps.length ? (
                    <Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        Confira os produtos inseridos
                      </Typography>
                      <TableContainer
                        component={Paper}
                        sx={{ width: '65vw', height: 350 }}>
                        <Table
                          stickyHeader
                          size="small"
                          aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Item</TableCell>
                              <TableCell>Código</TableCell>
                              <TableCell>Descrição</TableCell>
                              <TableCell>Descrição Completa</TableCell>
                              <TableCell>Referência</TableCell>
                              <TableCell>Unidade</TableCell>
                              <TableCell>Preço</TableCell>
                              <TableCell>Peso</TableCell>
                              <TableCell>Categ.</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {listProdutoUpload.map((produto, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center">
                                  {index + 2}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {produto.codigo}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  width={200}>
                                  {produto.descricao}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  width={250}>
                                  {produto.descricaoCompleta}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {produto.referencia}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center">
                                  {produto.unidade}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="right">
                                  {produto.preco.toLocaleString('pt-BR', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maxiumFractionDigits: 2
                                  })}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center">
                                  {produto.peso}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center">
                                  {produto.categoriaCodigo}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Fragment>
                  ) : (
                    <Box py={3}>
                      {activeStep === 0 && (
                        <Box display={'flex'} gap={4}>
                          {/* svg excel files */}
                          <ExcelLogo style={{ width: '150px' }} />

                          <Box>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              Clique no botão abaixo
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              para baixar o modelo em excel!
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              Preencha os produtos na planilha.
                            </Typography>
                            {/* botao de download */}
                            <Button
                              fullWidth
                              onClick={handleDownloadModelo}
                              startIcon={<Download />}
                              variant="contained">
                              Baixar Modelo
                            </Button>
                          </Box>
                        </Box>
                      )}

                      {activeStep === 1 && (
                        <Box>
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            Após preencher os produtos na planilha.
                          </Typography>
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            Faça o upload do arquivo e clique em próximo.
                          </Typography>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={4}
                            border={1}
                            p={1}>
                            <Button
                              onChange={handleUploadModelo}
                              component="label"
                              size="small"
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<UploadIcon />}>
                              Upload Arquivo Excel
                              <VisuallyHiddenInput type="file" />
                            </Button>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              {fileUploadName || 'Nenhum arquivo selecionado'}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {activeStep === steps.length ? (
                      <Button onClick={handleReset}>Reiniciar</Button>
                    ) : (
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}>
                        Voltar
                      </Button>
                    )}
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext}>
                      {activeStep === steps.length ? 'Finalizar' : 'Próximo'}
                    </Button>
                  </Box>
                </Fragment>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Inner>
  );
}
