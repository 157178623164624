/* eslint-disable prettier/prettier */
import { Box, Breadcrumbs, Button, styled, Typography } from '@mui/material';

import { Link, useSearchParams } from 'react-router-dom';
import AppConfig from '../../../config/app';
import { OUTLET_HEIGHT } from '../../../config/theme';

import { useLocalStorage } from '../../../hooks/useLocalStorage';
import RightDrawer from '../../../components/RightDrawer';
import { Add, NavigateNext } from '@mui/icons-material';

import CustomSearch from '../../../components/CustomSearch';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../auth/authSlice';
import { EnumPermissao } from '../../auth/models';
import Table from '../components/table';
import { useProduto } from '../context';

const drawerWidth = 240;
const Inner = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: OUTLET_HEIGHT,
  flexGrow: 1,
  position: 'relative',
  zIndex: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: 60,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}));

export default function ProdutoList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    produtos,
    loading: isLoading,
    searchFor,
    setSearchFor,
    setType
  } = useProduto();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlerFilter = (filter: Record<string, any>) => {
    //
  };

  const handleSearch = (value: string) => {
    // get all params from searchParams
    const params = Object.fromEntries(searchParams.entries());
    // set page to 1
    params.page = '1';
    setType('search');
    setSearchFor(value);
    setSearchParams(params);
  };

  const [openDrawer, setOpenDrawer] = useLocalStorage(
    'requisicao-right-drawer',
    false
  );

  const user = useSelector(selectCurrentUser);
  function authorization(action: string) {
    return user.permissoes[EnumPermissao.REQUISICAO].includes(action);
  }

  async function toFilter() {
    //
  }

  return (
    <Inner open={openDrawer}>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }} gap={1}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography fontWeight={'bold'}>Produtos</Typography>
        </Breadcrumbs>

        <Box display={'flex'} gap={2}>
          <CustomSearch
            value={searchFor}
            handleSearch={handleSearch}
            onFocus={() => {
              setType('search');
            }}
          />

          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={AppConfig.routes.cadProdutoInsert}
            aria-label="edit"
            title="Novo Produto"
            startIcon={<Add />}>
            Novo Produto
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={toFilter}
            aria-label="edit"
            title="Atualizar Filtros">
            <RefreshIcon amplitude={10} />
          </Button> */}
        </Box>
      </Box>
      <Table data={produtos} isLoading={isLoading} />
      <RightDrawer
        caption="Filtros"
        drawerWidth={drawerWidth}
        openDrawer={openDrawer}
        setOpen={setOpenDrawer}
        onFocus={() => {
          setType('filter');
        }}>
        {/* filtros */}
        <Box
          display="flex"
          flexDirection={'column'}
          gap={1}
          sx={{ overflow: 'hidden', overflowY: 'auto' }}></Box>

        {/* ação de filtrar */}
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[50]
          })}
          py={1}
          display={'flex'}
          justifyContent={'space-between'}>
          {/* <Typography
            onClick={clearFilters}
            sx={(theme) => ({
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'underline'
              }
            })}
            variant="overline">
            Limpar Filtros
          </Typography> */}
          {/* <Button
            fullWidth
            onClick={toFilter}
            variant="contained"
            color="primary"
            size={'small'}>
            <FilterAltOutlined amplitude={8} /> Filtrar
          </Button> */}
        </Box>
      </RightDrawer>
    </Inner>
  );
}
