import { Box, Paper, Typography, Stack } from '@mui/material';
import CotacaoCanceladaImg from '../../../assets/svg/cotacao_cancelada.min.svg';
import { useLocation } from 'react-router-dom';
import { useGetCotacoesFornecedorQuery } from '../apiSlice';
import PageLoading from '../../../components/PageLoading';
import { CotacaoFornecedor } from '../models';
import TableFornecedoreExterno from './tableFornecedoreExterno';

export default function CotacoesFornecedor() {
  const location = useLocation();

  const { fornecedorCotacao } = location.state as {
    fornecedorCotacao: CotacaoFornecedor;
  };

  const { data, isLoading } = useGetCotacoesFornecedorQuery({
    fornecedorId: Number(fornecedorCotacao.fornecedor.id)
  });

  if (isLoading) return <PageLoading />;

  return (
    <Box width={'98vw'} margin={'0 auto'}>
      <Paper sx={{ p: 2, my: 1 }}>
        {/* fornecedor */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography fontSize={12} variant="h6" component="span" gutterBottom>
            Fornecedor
          </Typography>
          {/* <Typography fontSize={12} variant="h6" component="span" gutterBottom>
            Validade
          </Typography> */}
        </Box>
        {/* expiracao */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Stack gap={0}>
            <Typography
              fontSize={16}
              fontWeight={'bold'}
              variant="h6"
              component="span"
              gutterBottom>
              {fornecedorCotacao?.fornecedor.nome}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={'bold'}
              variant="h6"
              component="span"
              gutterBottom>
              CNPJ: {fornecedorCotacao?.fornecedor.cnpj} -{' '}
              {fornecedorCotacao?.fornecedor.cidade}/
              {fornecedorCotacao?.fornecedor.uf}
            </Typography>
          </Stack>

          {/* <Typography
            fontSize={16}
            fontWeight={'bold'}
            variant="h6"
            component="span"
            gutterBottom>
            {new Date(limitTime ?? 0).toLocaleDateString('pt-BR', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </Typography> */}
        </Box>
      </Paper>

      <TableFornecedoreExterno
        data={data ?? []}
        isLoading={isLoading}
        height={'70vh'}
      />
    </Box>
  );
}
