import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { Situacao } from './models';
import { FormikProps } from 'formik';
import { MuiColorInput } from 'mui-color-input';
import Paper from '@mui/material/Paper';

type Props = {
  formik: FormikProps<Situacao>;
  isLoading?: boolean;
};

export function SituacaoForm({ formik, isLoading = false }: Props) {
  return (
    <Paper>
      <Box height="78vh" p={2}>
        <Grid container gap={3}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.nome && formik.errors.nome)}>
              <TextField
                name="nome"
                label="Descrição"
                disabled={true}
                inputProps={{ 'data-testid': 'nome' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nome}
              />
              {formik.touched.nome && formik.errors.nome && (
                <FormHelperText error id="error-nome">
                  {formik.errors.nome}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.corFundo && formik.errors.corFundo
                  )}>
                  <MuiColorInput
                    name="corFundo"
                    label="Cor do Fundo"
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'corFundo' }}
                    onChange={(color) =>
                      formik.setFieldValue('corFundo', color)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.corFundo || '#ffffff'}
                    variant="outlined"
                    format="hex"
                  />
                  {formik.touched.corFundo && formik.errors.corFundo && (
                    <FormHelperText error id="error-corFundo">
                      {formik.errors.corFundo}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.corLetra && formik.errors.corLetra
                  )}>
                  <MuiColorInput
                    name="corLetra"
                    label="Cor da Letra"
                    disabled={isLoading}
                    inputProps={{ 'data-testid': 'corLetra' }}
                    onChange={(color) =>
                      formik.setFieldValue('corLetra', color)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.corLetra || '#ffffff'}
                    variant="outlined"
                    format="hex"
                  />
                  {formik.touched.corLetra && formik.errors.corLetra && (
                    <FormHelperText error id="error-corLetra">
                      {formik.errors.corLetra}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                width: '100%',
                borderRadius: '15px',
                backgroundColor: formik.values.corFundo,
                color: formik.values.corLetra,
                px: 2,
                py: 1,
                textAlign: 'center'
              }}>
              {formik.values.nome}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
