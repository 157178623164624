import {
  Avatar,
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../app/hooks';
import { selectCurrentUser, logOut } from '../features/auth/authSlice';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import AppConfig from '../config/app';
import UserIcon from '@mui/icons-material/AccountCircle';

interface Props {
  menuOpen: boolean;
}

export default function Profile({ menuOpen }: Props) {
  const navigate = useNavigate();
  const myTheme = useTheme();
  const dispatch = useAppDispatch();
  const user = useSelector(selectCurrentUser);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const logout = () => {
    console.log('logout');
    dispatch(logOut());
  };

  return (
    <Box display={'flex'} justifyContent={menuOpen ? 'start' : 'center'}>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          transition: 'all .2s ease-in-out',
          cursor: 'pointer',
          border: 0,
          py: 2,
          color: 'inherit',
          backgroundColor: alpha(myTheme.palette.common.white, 0.2)
        }}
        icon={
          <Avatar
            sx={{
              backgroundColor: myTheme.palette.primary.main
            }}>
            <UserIcon />
          </Avatar>
        }
        label={
          menuOpen && (
            <Stack direction={'column'} spacing={0}>
              <Typography
                sx={{ color: 'inherit', fontWeight: 'bold', fontSize: 13 }}
                noWrap>
                {String(user.nome).toUpperCase()}
              </Typography>
              <Typography
                sx={{ color: 'inherit', fontWeight: 'bold', fontSize: 8 }}
                noWrap>
                {String(user.email).toLowerCase()}
              </Typography>
            </Stack>
          )
        }
        variant="outlined"
        // ref={anchorEl}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ minWidth: 200 }}
              onClick={() => {
                navigate(AppConfig.routes.cadUserEdit(user.id));
                handleClose();
              }}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Meu Perfil" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ minWidth: 200 }} onClick={logout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
}
