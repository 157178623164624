import { Fornecedor } from '../fornecedor/models';
import { Requisicao } from '../requisicao/models';
import { Situacao } from '../situacao/models';

export interface IPedidoFilters {
  situacaoId: number[];
  fornecedorId?: string;
  created_at: [string | null, string | null];
}

export interface Pedido {
  id: number;
  previsao: Date;
  transmitido: boolean;
  transmissao: string | null;
  movimento: string | null;
  fornecedor: Fornecedor;
  situacao: Situacao;
  logs: Log[];
  reqs: Requisicao[];
  createdAt: Date;
  updatedAt: Date;
}

export interface Log {
  id: number;
  obs: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ResultPedidos {
  info: Info;
  pedidos: Pedido[];
}

interface Info {
  registros: number;
  pagina: string;
  qtdePorPagina: string;
  orderBy: string;
  sort: string;
  pesquisaPor: PesquisaPor;
}

interface PesquisaPor {
  situacao: number[];
  prioridade: number[];
  solicitante: number[];
  departamento: number[];
  createdAt: string[];
  searchFor: string;
  tipo: string[];
}

export interface PedidoFilters {
  situacaoId: number[];
  fornecedorId?: string;
  created_at: [string | null, string | null];
}

export const PEDIDO_INITIAL_FILTERS: PedidoFilters = {
  situacaoId: [],
  fornecedorId: '',
  created_at: [null, null]
};

// filtros
// created_at: Date
// fornecedorId: string
// situacaoId: string[]
