import React from 'react';
import {
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListSubheader,
  Switch
} from '@mui/material';
import { TPermissao } from '../features/auth/models';

interface Props {
  caption: string;
  value: string[];
  onChange: (value: string[]) => void;
  visibles: TPermissao;
}

export default function ManagerPermissaoSimple({
  caption,
  onChange,
  value,
  visibles
}: Props) {
  function handlerChange(option: string) {
    // crudac
    const newOptions = value;
    switch (option) {
      // create
      case '+c': {
        newOptions[0] = 'c';
        break;
      }
      case '-c': {
        newOptions[0] = '_';
        break;
      }

      // read
      case '+r': {
        newOptions[1] = 'r';
        break;
      }
      case '-r': {
        newOptions[1] = '_';
        break;
      }

      // update
      case '+u': {
        newOptions[2] = 'u';
        break;
      }
      case '-u': {
        newOptions[2] = '_';
        break;
      }

      // delete
      case '+d': {
        newOptions[3] = 'd';
        break;
      }
      case '-d': {
        newOptions[3] = '_';
        break;
      }

      // approve
      case '+a': {
        newOptions[4] = 'a';
        break;
      }
      case '-a': {
        newOptions[4] = '_';
        break;
      }

      // cancel
      case '+x': {
        newOptions[5] = 'x';
        break;
      }
      case '-x': {
        newOptions[5] = '_';
        break;
      }

      // cancel
      case '+f': {
        newOptions[6] = 'f';
        break;
      }
      case '-f': {
        newOptions[6] = '_';
        break;
      }
    }

    onChange(newOptions);
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="span" id="nested-list-subheader">
          {caption}
        </ListSubheader>
      }>
      <Divider variant="middle" />
      {visibles.includes('r') && (
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                checked={value.includes('r')}
                value={'r'}
                onChange={(_, checked) => {
                  if (checked) {
                    handlerChange('+'.concat(_.target.value));
                  } else {
                    handlerChange('-'.concat(_.target.value));
                  }
                }}
              />
            }
            label="Visualizar"
          />
        </ListItem>
      )}
      {visibles.includes('c') && (
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                checked={value.includes('c')}
                value={'c'}
                onChange={(_, checked) => {
                  if (checked) {
                    handlerChange('+'.concat(_.target.value));
                  } else {
                    handlerChange('-'.concat(_.target.value));
                  }
                }}
              />
            }
            label="Criar"
          />
        </ListItem>
      )}
      {visibles.includes('u') && (
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                checked={value.includes('u')}
                value={'u'}
                onChange={(_, checked) => {
                  if (checked) {
                    handlerChange('+'.concat(_.target.value));
                  } else {
                    handlerChange('-'.concat(_.target.value));
                  }
                }}
              />
            }
            label="Alterar"
          />
        </ListItem>
      )}
      {visibles.includes('d') && (
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                checked={value.includes('d')}
                value={'d'}
                onChange={(_, checked) => {
                  if (checked) {
                    handlerChange('+'.concat(_.target.value));
                  } else {
                    handlerChange('-'.concat(_.target.value));
                  }
                }}
              />
            }
            label="Excluir"
          />
        </ListItem>
      )}
      {visibles.includes('a') && (
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                checked={value.includes('a')}
                value={'a'}
                onChange={(_, checked) => {
                  if (checked) {
                    handlerChange('+'.concat(_.target.value));
                  } else {
                    handlerChange('-'.concat(_.target.value));
                  }
                }}
              />
            }
            label="Aprovar"
          />
        </ListItem>
      )}
      {visibles.includes('x') && (
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                checked={value.includes('x')}
                value={'x'}
                onChange={(_, checked) => {
                  if (checked) {
                    handlerChange('+'.concat(_.target.value));
                  } else {
                    handlerChange('-'.concat(_.target.value));
                  }
                }}
              />
            }
            label="Cancelar"
          />
        </ListItem>
      )}
      {visibles.includes('f') && (
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                checked={value.includes('f')}
                value={'f'}
                onChange={(_, checked) => {
                  if (checked) {
                    handlerChange('+'.concat(_.target.value));
                  } else {
                    handlerChange('-'.concat(_.target.value));
                  }
                }}
              />
            }
            label="Finalizar"
          />
        </ListItem>
      )}
    </List>
  );
}
