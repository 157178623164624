/* eslint-disable prettier/prettier */
import { useCallback, useState } from 'react';
import {
  DataGrid,
  GridCellParams,
  GridColumnVisibilityModel,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRowModel,
  GridRowModesModel,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  MuiEvent
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LinkIcon from '@mui/icons-material/Link';
import { useSnackbar } from 'notistack';
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { CotacaoFornecedor } from '../models';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { Cancel, Send } from '@mui/icons-material';
import GridActionConfirmModal from '../../../components/gridActionConfirmModal';
import { Empresa } from '../../empresa/models';
import { selectEmpresa } from '../../empresa/storeSlice';
import { useAppSelector } from '../../../app/hooks';
import { CustomNoTableRowsOverlay } from '../../../components/NoCustomRowOverlay';
import GridEditCheck from '../../../components/GridEditCheck';
import GridRenderCheck from '../../../components/GridRenderCheck';
import { CotacaoFornecedorMap } from '../edit';
import ChipPedido from './chipPedido';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { writeFile, utils } from 'xlsx';
import { GetApp } from '@mui/icons-material';

interface Props {
  data?: Partial<CotacaoFornecedor | CotacaoFornecedorMap>[];
  actionDelete: (id: string | number) => void;
  actionUpdate: (
    row: Partial<CotacaoFornecedor>
  ) => Promise<Partial<CotacaoFornecedor>>;
  actionEnviarEmail: (row: Partial<CotacaoFornecedor>) => void;
  editable?: boolean;
  loading?: boolean;
}

export default function TableFornecedoresExterno({
  data,
  actionDelete,
  actionUpdate,
  actionEnviarEmail,
  editable = false,
  loading = false
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const empresa: Empresa = useAppSelector(selectEmpresa);
  // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  function handleSendClick(row: any) {
    const data = {
      ...row,
      fornecedor: {
        id: row.fornecedorId,
        nome: row.nome,
        email: row.email
      },
      path: row.link
    };
    if (row) actionEnviarEmail(data);
  }

  // const processRowUpdate = (
  //   newRow: GridValidRowModel
  //   // oldRow: GridValidRowModel
  // ): GridValidRowModel => {
  //   // if (!newRow.validade || !newRow.email) {
  //   //   enqueueSnackbar('Email e validade devem ser preenchidos', {
  //   //     variant: 'error'
  //   //   });
  //   //   return { ...oldRow };
  //   // }

  //   const updatedRow: GridValidRowModel = {
  //     ...newRow,
  //     isNew: false,
  //     isEditing: true
  //   };
  //   actionUpdate(newRow);
  //   // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
  //   return { ...updatedRow };
  // };

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      // if (!newRow.validade) {
      //   enqueueSnackbar('Validade deve ser preenchida', {
      //     variant: 'warning'
      //   });
      //   return oldRow;
      // }
      // Make the HTTP request to save in the backend
      const response = await actionUpdate(newRow);

      if ((response as any).affected > 0) {
        return newRow;
      } else {
        return oldRow;
      }
    },
    [actionUpdate]
  );

  const handleProcessRowUpdateError = useCallback(
    (error: any) => {
      enqueueSnackbar(error?.data?.message ?? 'Erro ao atualizar registro', {
        variant: 'error'
      });
    },
    [enqueueSnackbar]
  );

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          py: 1,
          gap: 2,
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Box sx={{ display: 'flex' }}>
          <GridToolbarColumnsButton variant="text" />
          <IconButton onClick={handleExport}>
            <GetApp color="primary" />
          </IconButton>
        </Box>
      </GridToolbarContainer>
    );
  };

  const mapToExcel = ((data as any[]) ?? []).map((d) => {
    return {
      Validade: d.validade
        ? new Date(d.validade).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
        : '',
      Ativo: d.isAtivo ? 'Sim' : 'Não',
      Pedido: d.pedido ?? '',
      Nome: d.nome ?? '',
      Fantasia: d.apelido ?? '',
      CNPJ: d.cnpj ?? '',
      Email: d.email,
      Cotados: `${d.reqCotado}/${d.reqTotal}`,
      Cidade: d.cidade ?? '',
      UF: d.uf ?? '',
      EmailEnviado: d.emailEnviado ? 'Sim' : 'Não'
    };
  });

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'Cotacao-Fornecedores.xlsx');
  }

  const columns = [
    {
      field: 'validade',
      headerName: 'Validade',
      type: 'date',
      width: 70,
      sortable: true,
      editable: true,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { value } = params;
        return value
          ? new Date(value).toLocaleDateString('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })
          : '';
      }
    },
    {
      field: 'isAtivo',
      type: 'boolean',
      headerName: 'Ativo',
      width: 50,
      sortable: true,
      editable: true,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
        const fornecedor = data?.find((row) => row.id === params.id);

        const hasError = fornecedor?.pedidoGerado ?? false;
        if (hasError) {
          enqueueSnackbar(
            'Não é possível desativar fornecedor com pedido gerado',
            {
              variant: 'error'
            }
          );
        }

        return { ...params.props, error: hasError };
      },
      renderCell: GridRenderCheck,
      renderEditCell: GridEditCheck
    },
    {
      field: 'pedido',
      headerName: 'Pedido',
      width: 100,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        if (params.value) return <ChipPedido pedidoId={String(params.value)} />;
      }
    },
    {
      field: 'nome',
      headerName: 'Nome',
      minWidth: 250,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'apelido',
      headerName: 'Fantasia',
      minWidth: 250,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 120,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'reqCotado',
      headerName: 'Cotados(%)',
      width: 85,
      sortable: true,
      editable: true,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      renderCell(params: any) {
        const row = params.row as CotacaoFornecedorMap;
        const percent = Math.round((row.reqCotado / row.reqTotal) * 100);
        return (
          <Box display={'flex'} gap={1}>
            <Tooltip title={`${row.reqCotado}/${row.reqTotal}`} arrow>
              <Chip
                icon={
                  percent > 0 ? (
                    <CircularProgress
                      size={24}
                      variant="determinate"
                      color="success"
                      value={percent}
                    />
                  ) : (
                    <Cancel />
                  )
                }
                label={`${Math.round((row.reqCotado / row.reqTotal) * 100)}%`}
                sx={{ fontSize: 10 }}
              />
            </Tooltip>
          </Box>
        );
      }
    },
    {
      field: 'email',
      headerName: 'E-Mail',
      width: 200,
      sortable: true,
      editable: true,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'cidade',
      headerName: 'Cidade',
      width: 120,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'uf',
      headerName: 'UF',
      width: 30,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'emailEnviado',
      type: 'boolean',
      headerName: 'Email Enviado',
      width: 30,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title="Email Enviado" arrow>
          <MarkEmailReadIcon />
        </Tooltip>
      ),
      renderCell: GridRenderCheck
    },
    // actions
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      minWidth: 110,
      flex: 1,
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      cellClassName: 'actions',
      getActions: ({ id, row }: any) => {
        // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        // if (!editable) return [<></>];
        const link = row.link;

        return [
          // negar exclusão
          // 1 - se tem pedido gerado
          // 2 - se tem item cotado
          editable && row.reqCotado === 0 && !row.pedidoGerado ? (
            <GridActionConfirmModal
              icon={
                <Tooltip title="Excluir fornecedor" arrow>
                  <DeleteIcon />
                </Tooltip>
              }
              label="Exclusão de fornecedor"
              descriptions={
                <>
                  <Typography variant="body2" color={'red'} gutterBottom>
                    Excluir fornecedor?
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.nome}
                  </Typography>
                </>
              }
              className="textError"
              action={() => {
                if (!data) return;

                const row = data.filter((row) => {
                  return row.id === id;
                })[0];
                const fornecedorId = (row as any)?.fornecedorId;
                fornecedorId && actionDelete(fornecedorId);
              }}
              key={0}
            />
          ) : (
            <div key={4}></div>
          ),
          link ? (
            <Tooltip
              key={1}
              title="Copiar LINK para área de transferência"
              arrow>
              <IconButton
                aria-label="link"
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(empresa.endereco + link);
                  enqueueSnackbar('Link copiado para área de transferência', {
                    variant: 'success'
                  });
                }}>
                <LinkIcon />
              </IconButton>
            </Tooltip>
          ) : (
            // <a
            //   key={4}
            //   href={link}
            //   target="_blank"
            //   rel="noopener noreferrer"
            //   style={{ textDecoration: 'none' }}>
            //   <Tooltip title="Link para pagina externa de cotação" arrow>
            //     <IconButton aria-label="link" size="small">
            //       <LinkIcon />
            //     </IconButton>
            //   </Tooltip>
            // </a>
            <div key={7}></div>
          ),
          <GridActionConfirmModal
            icon={
              <Tooltip title="Enviar email" arrow>
                <Send />
              </Tooltip>
            }
            label="Enviar email para fornecedor"
            descriptions={
              <>
                <Typography variant="body2" gutterBottom>
                  Enviar email para o fornecedor?
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {row.nome}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Email: {row.email}
                </Typography>
              </>
            }
            className="textError"
            action={() => {
              handleSendClick(row);
            }}
            key={2}
          />
        ];
      }
    }
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>(
      'columns-visibles-cotacao-edtit-fornecedor',
      columns.reduce((acc, column) => {
        Object.assign(acc, { [column.field]: true });
        return acc;
      }, {})
    );

  return (
    <div style={{ overflow: 'hidden', overflowX: 'auto', height: '62vh' }}>
      <DataGrid
        sx={(style) => ({
          fontSize: 13,
          '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            fontSize: 13,
            backgroundColor: style.palette.secondary.main,
            color: style.palette.secondary.contrastText
          },
          '& .MuiDataGrid-columnSeparator': {
            color: style.palette.secondary.contrastText
          },
          '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
            opacity: 1,
            color: style.palette.secondary.contrastText
          },
          overflow: 'hidden',
          '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
            color: style.palette.secondary.contrastText,
            borderRadius: 0
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0
          },
          '& .super-app-theme--pedido': {
            color: (theme) => theme.palette.grey[500],
            '&:hover': {
              color: (theme) => theme.palette.grey[800]
            }
          }
        })}
        loading={loading}
        rows={data ?? []}
        disableIgnoreModificationsIfProcessingProps
        columns={columns}
        getRowClassName={(params) =>
          params.row.pedidoGerado && `super-app-theme--pedido`
        }
        pageSize={10}
        rowsPerPageOptions={[10]}
        density="compact"
        editMode="cell"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooter
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: CustomNoTableRowsOverlay
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            showColumnsButton: true,
            quickFilterProps: { debounceMs: 500 },
            csvOptions: {
              delimiter: ';',
              utf8WithBom: true,
              fileName: 'sistema-cotação'
            }
          }
          // footer: {
          //   sx: {
          //     backgroundColor: 'secondary.main',
          //     color: 'secondary.contrastText',
          //     '& .MuiPaginationItem-root': {
          //       fontSize: 12
          //     },
          //     '& *': {
          //       color: 'secondary.contrastText'
          //     }
          //   }
          // }
        }}
        localeText={{
          toolbarColumnsLabel: 'Colunas',
          toolbarColumns: '',
          toolbarExport: '',
          toolbarExportLabel: 'Exportar',
          toolbarExportCSV: 'Exportar para CSV',
          toolbarExportPrint: 'Imprimir',
          detailPanelToggle: 'Expandir',
          columnsPanelHideAllButton: 'Ocultar tudo',
          columnsPanelShowAllButton: 'Mostrar tudo',
          columnsPanelTextFieldLabel: 'Localizar coluna',
          columnsPanelTextFieldPlaceholder: 'Título da coluna'
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
    </div>
  );
}
