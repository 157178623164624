import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

export function useCallbackPrompt(when = false) {
  // const [showPrompt, setShowPrompt] = useState(false);

  const [isBlocked, setIsBlocked] = useState(false);

  const block = useBlocker(({ currentLocation, nextLocation }) => {
    if (when && currentLocation.pathname !== nextLocation.pathname) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    const showPrompt = block.state === 'blocked';
    setIsBlocked(showPrompt);
    // setShowPrompt(showPrompt);
    // mostrar janele de dialogo
    // if (showPrompt) {
    //   setShowPrompt(true);
    // }
    if (showPrompt) {
      // eslint-disable-next-line no-restricted-globals
      const resp = window.confirm(
        'Você tem alterações não salvas. Tem certeza que deseja sair?'
      );

      if (resp) {
        block.proceed();
      } else {
        block.reset();
      }
    }
  }, [block, block.proceed, block.state]);

  useEffect(() => {
    initBeforeUnLoad(when);
  }, [when]);

  return [isBlocked];
}
