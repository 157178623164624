import { useContext, useEffect, useState } from 'react';
// import { darkTheme, lightTheme } from '../config/theme';
import { useLocalStorage } from './useLocalStorage';
import { createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';
import { ContextConfig } from '..';

export function useAppTheme() {
  const { themeDark, themeLigth } = useContext(ContextConfig);
  const darkColor = JSON.parse(themeDark ?? '{}');

  const lightColor = JSON.parse(themeLigth ?? '{}');

  const darkTheme = createTheme(
    {
      palette: {
        mode: 'dark',
        primary: {
          // light: will be calculated from palette.primary.main,
          main: darkColor?.colors?.primary || '#cacaca'
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
          main: darkColor?.colors?.secondary || '#ffffff'
          // dark: will be calculated from palette.secondary.main,
        }
        // background: {
        //   default: '#334155'
        // }
      },
      typography: {
        fontFamily: 'Poppins'
      },
      shape: {
        borderRadius: 8
      }
    },
    ptBR
  );

  const lightTheme = createTheme(
    {
      palette: {
        mode: 'light',
        primary: {
          // light: will be calculated from palette.primary.main,
          main: lightColor?.colors?.primary || '#cacaca'
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
          main: lightColor?.colors?.secondary || '#000000'
          // dark: will be calculated from palette.secondary.main,
        },
        background: {
          default: '#e4f0e2'
        }
      },
      typography: {
        fontFamily: 'Poppins'
      },
      shape: {
        borderRadius: 12
      }
    },
    ptBR
  );

  // const [theme, setTheme] = useState(darkTheme);
  // const [storedThemeMode, setStoredThemeMode] = useLocalStorage<
  //   'dark' | 'light'
  // >('themeMode', 'light');

  // const toggleTheme = () => {
  //   const currentTheme = theme.palette.mode === 'dark' ? lightTheme : darkTheme;
  //   setTheme(currentTheme);
  //   setStoredThemeMode(currentTheme.palette.mode);
  // };

  // useEffect(() => {
  //   const currentTheme = storedThemeMode === 'dark' ? darkTheme : lightTheme;
  //   if (currentTheme) {
  //     setTheme(currentTheme);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const theme = lightTheme;

  return [theme] as const;
}
