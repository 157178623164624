/* eslint-disable prettier/prettier */
import { numberRound } from '../../utils/accouting';
import { apiSlice } from '../api/apiSlice';
import { Cotacao, CotacaoFornecedor, CotacaoItem } from './models';

export interface CreateCotacao {
  obs: string;
  fornecedores: number[];
  requisicoes: {
    requisicaoId: number;
    updated_at: string;
  }[];
}

export interface AddCotacaoFornecedor {
  cotacaoId: number;
  updated_at: string;
  fornecedorId: number;
  validade?: string;
  isAtivo?: boolean;
}

export interface CotacaoFornecedorDelete {
  cotacaoId: number;
  updated_at: string;
  fornecedorId: number;
}

export interface AddCotacaoRequisicao {
  cotacaoId: number;
  updated_at: string;
  requisicao: {
    requisicaoId: number;
    updated_at: string;
  };
}

export interface CotacaoRequisicaoDelete {
  cotacaoId: number;
  updated_at: string;
  requisicao: {
    requisicaoId: number;
    updated_at: string;
  };
}

export interface CotacaoItemUpdate {
  itemId?: number;
  cotacaoId: number;
  updated_at: string;
  qtde: number;
  preco: number;
  desconto: number;
  icms: number;
  ipi: number;
  frete: number;
  outrasDespesas: number;
  seuCodigo: string;
  previsao: string | null;
  obs: string;
}

export interface CotacaoFornecedorUpdate {
  fornecedorId: number;
  updated_at: string;
  path?: string;
  email?: string | null;
  validade: string;
  isAtivo: boolean;
  emailEnviado?: boolean;
}

export interface CotacaoItemSelecionar {
  cotacaoId: number;
  updated_at: string;
  cotacaoItem: {
    cotacaoItemId: number;
    requisicaoId: number;
    updated_at: string;
  };
}
export interface CotacaoItemDesselecionar {
  cotacaoItemId: number;
  updated_at: string;
}

interface CotacaoItemLoteSelecionar {
  cotacaoId: number;
  updated_at: string;
  cotacaoItens: {
    cotacaoItemId: number;
    requisicaoId: number;
    updated_at: string;
  }[];
}

export interface FindCotacao {
  situacaoId: number[];
  created_at: [string | null, string | null];
}

export interface FinishCotacao {
  cotacao: {
    id: number;
    updated_at: string;
    pedidos: {
      fornecedorId: number;
      requisicoes: {
        requisicaoId: number;
        updated_at: string;
        obs: string;
        previsao: string;
      }[];
    }[];
  };
}

type FindParams = {
  take: number;
  skip: number;
  orderBy: string;
  sort: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: Partial<FindCotacao>;
};

export interface ResultCotacoes {
  cotacoes: Cotacao[];
}

const endpointUrl = '/cotacoes';

function getCotacoes() {
  return `${endpointUrl}`;
}

function getCotacao({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function getCotacaoExterno({ uuid }: { uuid: string }) {
  return `${endpointUrl}/externo/${uuid}`;
}

function createCotacao(createCotacao: CreateCotacao) {
  return { url: endpointUrl, method: 'POST', body: createCotacao };
}

function deleteCotacao({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

function updateCotacao(user: Cotacao) {
  return {
    url: `${endpointUrl}/${user.id}`,
    method: 'PUT',
    body: user
  };
}

function addFornecedor(args: AddCotacaoFornecedor) {
  return {
    url: `${endpointUrl}/fornecedor`,
    method: 'POST',
    body: args
  };
}

function deleteFornecedor(args: CotacaoFornecedorDelete) {
  return {
    url: `${endpointUrl}/fornecedor`,
    method: 'DELETE',
    body: args
  };
}

function addRequisicao(args: AddCotacaoRequisicao) {
  return {
    url: `${endpointUrl}/requisicao`,
    method: 'POST',
    body: args
  };
}

function deleteRequisicao(args: CotacaoRequisicaoDelete) {
  return {
    url: `${endpointUrl}/requisicao`,
    method: 'DELETE',
    body: args
  };
}

function cotacaoFornecedorUpdate(args: CotacaoFornecedorUpdate) {
  if (args.emailEnviado === undefined || args.emailEnviado === null) {
    delete args.emailEnviado;
  }

  if (!args.email) {
    args.email = null;
  }

  const { fornecedorId, ...body } = args;

  return {
    url: `${endpointUrl}/fornecedor/` + fornecedorId,
    method: 'PUT',
    body
  };
}

function cotacaoItemUpdate(args: CotacaoItemUpdate) {
  const itemId = args.itemId;
  delete args.itemId;
  const body: Record<string, any> = args;

  body.preco = numberRound(body.preco);

  body.vFrete =
    body.frete && args.preco ? numberRound(args.preco * (args.frete / 100)) : 0;

  body.vIcms =
    body.icms && body.preco ? numberRound(body.preco * (body.icms / 100)) : 0;

  body.vIpi =
    body.ipi && body.preco ? numberRound(body.preco * (body.ipi / 100)) : 0;

  body.vOutrasDespesas =
    body.outrasDespesas && body.preco
      ? numberRound(body.preco * (body.outrasDespesas / 100))
      : 0;

  body.vDesconto =
    body.desconto && body.preco
      ? numberRound(body.preco * (body.desconto / 100))
      : 0;

  body.precoLiquido = body.preco - body.vDesconto;

  body.precoComImposto = numberRound(
    body.precoLiquido +
    body.vIcms +
    body.vIpi +
    body.vFrete +
    body.vOutrasDespesas
  );

  body.totalItem = body.qtde
    ? numberRound(body.precoComImposto * body.qtde)
    : 0;

  body.situacaoId = 220;

  return {
    url: `/cotacaoitens/` + itemId,
    method: 'PUT',
    body
  };
}

function cotacaoItemSelecionar(args: CotacaoItemSelecionar) {
  return {
    url: `/cotacaoitens/selecionar/` + args.cotacaoId,
    method: 'POST',
    body: {
      cotacaoId: args.cotacaoId,
      updated_at: args.updated_at,
      cotacaoItem: args.cotacaoItem
    }
  };
}

function cotacaoItemDesselecionar(args: CotacaoItemDesselecionar) {
  return {
    url: `/cotacaoitens/` + args.cotacaoItemId,
    method: 'PUT',
    body: {
      isSelected: false,
      updated_at: args.updated_at,
    }
  };
}

function cotacaoItemLoteSelecionar(args: CotacaoItemLoteSelecionar) {
  return {
    url: `/cotacaoitens/selecionar/lote/` + args.cotacaoId,
    method: 'POST',
    body: {
      cotacaoId: args.cotacaoId,
      updated_at: args.updated_at,
      cotacaoItens: args.cotacaoItens
    }
  }
}

function findCotacoes({ take, skip, orderBy, sort, body }: FindParams) {
  return {
    url: `${endpointUrl}/find/?take=${take}&skip=${skip}&orderBy=${orderBy}&sort=${sort}`,
    method: 'POST',
    body: body
  };
}

function finishCotacao(body: FinishCotacao) {
  return {
    url: `${endpointUrl}/finalizar`,
    method: 'POST',
    body: body
  };
}

function enviarEmailCotacao({ fornecedorCotacaoId, updated_at }: { fornecedorCotacaoId: string, updated_at: string }) {
  return {
    url: `${endpointUrl}/fornecedor/enviaremail/${fornecedorCotacaoId}`,
    body: { updated_at },
    method: 'POST'
  };
}

function getCotacoesFornecedor({ fornecedorId }: { fornecedorId: number }) {
  return {
    url: `${endpointUrl}/externo/fornecedor/${fornecedorId}`,
    method: 'GET'
  };
}

// atualiza itens em lote
interface UpdateItensLote {
  cotacaoId: number;
  updated_at: string;
  lotes: CotacaoItem[];
}

function updateItensLote(args: UpdateItensLote) {
  return {
    url: `/cotacaoitens/lote/${args.cotacaoId}`,
    method: 'PUT',
    body: args
  };
}

function cotacaoRetransmitir(pedidoId: number) {
  return {
    url: `${endpointUrl}/retransmitir/${pedidoId}`,
    method: 'POST'
  };
}

// alterar requisicao qtde
interface ICotacaoItemQtdeUpdate {
  cotacaoId: number;
  updated_at: string;
  requisicao: {
    requisicaoId: number;
    qtde: number;
    updated_at: string;
  }
}
function cotacaoItemQtdeUpdate(args: ICotacaoItemQtdeUpdate) {
  return {
    url: `${endpointUrl}/requisicao/alterarQtde`,
    method: 'PUT',
    body: args
  };
}

export const situacaoApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getCotacoes: query<Cotacao[], void>({
      query: getCotacoes,
      providesTags: ['Cotacoes']
    }),
    getCotacao: query<Cotacao, { id: string }>({
      query: getCotacao,
      providesTags: ['Cotacoes']
    }),
    getCotacoesFornecedor: query<Cotacao[], { fornecedorId: number }>({
      query: getCotacoesFornecedor,
      providesTags: ['CotacoesFornecedor']
    }),
    getCotacaoExterno: query<Cotacao, { uuid: string }>({
      query: getCotacaoExterno,
      providesTags: ['CotacoesExterno']
    }),
    findCotacoes: mutation<ResultCotacoes, FindParams>({
      query: findCotacoes,
      invalidatesTags: ['Cotacoes']
    }),
    createCotacao: mutation<Cotacao, CreateCotacao>({
      query: createCotacao,
      invalidatesTags: ['Cotacoes']
    }),
    deleteCotacao: mutation<Cotacao, { id: string }>({
      query: deleteCotacao,
      invalidatesTags: ['Cotacoes']
    }),
    updateCotacao: mutation<Cotacao, Cotacao>({
      query: updateCotacao,
      invalidatesTags: ['Cotacoes']
    }),
    addCotacaoFornecedor: mutation<void, AddCotacaoFornecedor>({
      query: addFornecedor,
      invalidatesTags: ['Cotacoes']
    }),
    deleteCotacaoFornecedor: mutation<void, CotacaoFornecedorDelete>({
      query: deleteFornecedor,
      invalidatesTags: ['Cotacoes']
    }),
    addCotacaoRequisicao: mutation<void, AddCotacaoRequisicao>({
      query: addRequisicao,
      invalidatesTags: ['Cotacoes']
    }),
    deleteCotacaoRequisicao: mutation<void, CotacaoRequisicaoDelete>({
      query: deleteRequisicao,
      invalidatesTags: ['Cotacoes']
    }),
    cotacaoItemUpdate: mutation<void, CotacaoItemUpdate>({
      query: cotacaoItemUpdate,
      invalidatesTags: ['Cotacoes', 'CotacoesExterno']
    }),
    cotacaoFornecedorUpdate: mutation<CotacaoFornecedor, CotacaoFornecedorUpdate>({
      query: cotacaoFornecedorUpdate,
      invalidatesTags: ['Cotacoes']
    }),
    cotacaoItemSelecionar: mutation<void, CotacaoItemSelecionar>({
      query: cotacaoItemSelecionar,
      invalidatesTags: ['Cotacoes']
    }),
    cotacaoItemLoteSelecionar: mutation<void, CotacaoItemLoteSelecionar>({
      query: cotacaoItemLoteSelecionar,
      invalidatesTags: ['Cotacoes']
    }),
    cotacaoItemDesselecionar: mutation<void, CotacaoItemDesselecionar>({
      query: cotacaoItemDesselecionar,
      invalidatesTags: ['Cotacoes']
    }),
    cotacaoFinalizar: mutation<void, FinishCotacao>({
      query: finishCotacao,
      invalidatesTags: ['Cotacoes']
    }),
    enviarEmailCotacao: mutation<void, { fornecedorCotacaoId: string, updated_at: string }>({
      query: enviarEmailCotacao,
      invalidatesTags: ['Cotacoes']
    }),
    updateItensLote: mutation<void, UpdateItensLote>({
      query: updateItensLote,
      invalidatesTags: ['Cotacoes', 'CotacoesExterno']

    }),
    cotacaoRetransmitir: mutation<void, number>({
      query: cotacaoRetransmitir,
      invalidatesTags: ['Cotacoes', 'Pedidos']
    }),
    cotacaoItemQtdeUpdate: mutation<void, ICotacaoItemQtdeUpdate>({
      query: cotacaoItemQtdeUpdate,
      invalidatesTags: ['Cotacoes', 'CotacoesExterno']
    })
  })
});

export const {
  useGetCotacoesQuery,
  useGetCotacaoQuery,
  useGetCotacoesFornecedorQuery,
  useGetCotacaoExternoQuery,
  useFindCotacoesMutation,
  useCreateCotacaoMutation,
  useUpdateCotacaoMutation,
  useDeleteCotacaoMutation,
  useAddCotacaoFornecedorMutation,
  useDeleteCotacaoFornecedorMutation,
  useAddCotacaoRequisicaoMutation,
  useDeleteCotacaoRequisicaoMutation,
  useCotacaoItemUpdateMutation,
  useCotacaoFornecedorUpdateMutation,
  useCotacaoItemSelecionarMutation,
  useCotacaoItemLoteSelecionarMutation,
  useCotacaoItemDesselecionarMutation,
  useEnviarEmailCotacaoMutation,
  useCotacaoFinalizarMutation,
  useUpdateItensLoteMutation,
  useCotacaoRetransmitirMutation,
  useCotacaoItemQtdeUpdateMutation
} = situacaoApiSlice;
