/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { OUTLET_HEIGHT } from '../../config/theme';

import { Box, Breadcrumbs, Button, styled, Typography } from '@mui/material';
import Table from './tableNotPaginated';

import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FilterAltOutlined, NavigateNext } from '@mui/icons-material';

import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../auth/authSlice';
import { Requisicao } from '../requisicao/models';
import { EnumPermissao } from '../auth/models';
// import { useFindAprovacoesQuery } from './apiSlice';
import SelectButtonSitacao from './selectButtonSituacao';
import SelectFilter from '../../components/filters/selectFilter';
import {
  useAprovarRequisicaoLoteMutation,
  useDesaprovarRequisicaoLoteMutation,
  useFindRequisicoesMutation
} from '../requisicao/apiSlice';
import RightDrawer from '../../components/RightDrawer';
import PeriodFilter from '../../components/filters/periodFilter';

const drawerWidth = 240;
const Inner = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: OUTLET_HEIGHT,
  flexGrow: 1,
  position: 'relative',
  zIndex: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: 60,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}));

export default function Aprovacao() {
  const [openDrawer, setOpenDrawer] = useLocalStorage(
    'requisicao-right-drawer',
    false
  );

  // filtro situação
  const [situacoes, setSituacoes] = useLocalStorage<[boolean, boolean]>(
    'aprovacao-situacoes-filter',
    [true, false]
  );

  // filtros
  const [filters, setFilters] = useLocalStorage<any>('aprovacao-filtros', {
    situacaoId: [1]
  });
  const handlerFilter = (filter: Record<string, any>) => {
    // verifica se a data inicial é maior que a data final
    const oldFilters = filters;
    const [dt1, dt2] = filter.created_at ?? [null, null];
    const dtIni = dt1 ? new Date(dt1) : null;
    const dtFim = dt2 ? new Date(dt2) : null;
    if (dtIni && dtFim && dtIni > dtFim) {
      enqueueSnackbar('A data inicial não pode ser maior que a data final', {
        variant: 'warning'
      });
      setFilters(oldFilters);
      return;
    }
    const newfilters = { ...filters, ...filter, created_at: filter.created_at };
    setFilters(newfilters);
  };

  const [aprovarRequisicaoLote, statusAprov] =
    useAprovarRequisicaoLoteMutation();
  const [desaprovarRequisicaoLote, statusDesaprov] =
    useDesaprovarRequisicaoLoteMutation();

  // const rows = useRef<Requisicao[]>([]);

  // const getSituacoes = useCallback(() => {
  //   if (situacoes[0] && situacoes[1]) {
  //     return [1, 2];
  //   }
  //   if (situacoes[0] && !situacoes[1]) {
  //     return [1];
  //   }
  //   if (!situacoes[0] && situacoes[1]) {
  //     return [2];
  //   }
  //   if (!situacoes[0] && !situacoes[1]) {
  //     return [0];
  //   }
  //   return [0];
  // }, [situacoes]);

  // const { data, isLoading, isFetching } = useFindAprovacoesQuery(
  //   {
  //     take: -1,
  //     skip: 0,
  //     orderBy: '',
  //     sort: '1',
  //     body: {
  //       situacaoId: getSituacoes()
  //     }
  //   },
  //   {
  //     refetchOnMountOrArgChange: true
  //   }
  // );

  const [findRequisicao] = useFindRequisicoesMutation({
    fixedCacheKey: 'requisicao'
  });

  const [data, setData] = useState<Requisicao[]>([]);
  const [loading, setLoading] = useState(false);
  // filtro de classes
  const [dataClasses, setDataClasses] = useState<any[]>([]);
  // const [filter, setFilter] = useState<string[]>([]);

  async function toFilter() {
    filterRequisicoes();
  }

  const filterRequisicoes = useCallback(async () => {
    try {
      setLoading(true);

      const entries = Object.entries(filters);

      const args = {};
      entries.forEach(([key, value]: [string, any]) => {
        if (value && value.length > 0) {
          if (key === 'searchFor' || key === 'createdAt') {
            return;
          }

          if (key === 'tipoReq') {
            Object.assign(args, {
              [key]: value.reduce((acc: string[], cur: string) => {
                acc.push(`'${cur}'`);
                return acc;
              }, [])
            });
            return;
          }

          if (key === 'created_at') {
            if (!value[0] && !value[1]) return;

            const ini = value[0];
            const end = value[1];

            Object.assign(args, {
              [key]: [ini, end]
            });
            return;
          }

          Object.assign(args, { [key]: value });
        }
      });
      const params = { take: -1, skip: 0, orderBy: '', sort: '1', body: args };

      const result = await findRequisicao(params);

      if (!result) {
        setData([]);
        setDataClasses([]);
        return;
      } else {
        const current = (result as any).data.requisicoes;
        const classes = current
          .map(
            (el: any) => el.produtoServico?.classe && el.produtoServico.classe
          )
          .filter((el: any) => el !== null)
          .reduce((acc: any, cur: any) => {
            if (cur === null) return acc;
            const found = acc.find((el: any) => el?.id === cur?.id);
            if (!found) {
              acc.push(cur);
            }
            return acc;
          }, [])
          .filter((el: any) => el);
        setDataClasses(classes ?? []);
        setData(current);
      }
    } catch (error) {
      setData([]);
      setDataClasses([]);
    } finally {
      setLoading(false);
    }
  }, [filters, findRequisicao]);

  // useEffect(() => {
  //   if (data?.requisicoes) {
  //     rows.current = data.requisicoes
  //       .filter((el) => {
  //         if (filter.length === 0) return true;
  //         return filter.includes(
  //           el.produtoServico?.classe?.id as unknown as string
  //         );
  //       })
  //       .filter((el) =>
  //         el.situacao?.id ? getSituacoes().includes(el.situacao.id) : false
  //       );

  //     const classes = data.requisicoes
  //       .map((el) => el.produtoServico?.classe && el.produtoServico.classe)
  //       .filter((el) => el !== null)
  //       .reduce((acc: any, cur: any) => {
  //         if (cur === null) return acc;
  //         const found = acc.find((el: any) => el?.id === cur?.id);
  //         if (!found) {
  //           acc.push(cur);
  //         }
  //         return acc;
  //       }, [])
  //       .filter((el: any) => el);
  //     setDataClasses(classes ?? []);
  //   }
  //   console.log('useEffect dataClasses');
  // }, [data, filter, getSituacoes]);

  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState<Requisicao[]>([]);

  const user = useSelector(selectCurrentUser);
  function authorization(action: string) {
    return user.permissoes[EnumPermissao.REQUISICAO].includes(action);
  }

  async function handlerAprovar() {
    if (!authorization('a')) {
      enqueueSnackbar('Usuário não autorizado', {
        variant: 'error'
      });
      return;
    }
    if (selectedRows.length === 0) {
      enqueueSnackbar('Selecione pelo menos uma requisição', {
        variant: 'warning'
      });
      return;
    }
    const selecteds = selectedRows.map((selected) => ({
      id: selected.id ?? 0,
      updated_at: selected.updatedAt ?? new Date()
    }));

    await aprovarRequisicaoLote(selecteds).then(() => {
      filterRequisicoes();
    });
    // setFilter([]);
  }

  async function handlerDesaprovar() {
    if (!authorization('a')) {
      enqueueSnackbar('Usuário não autorizado', {
        variant: 'error'
      });
      return;
    }
    if (selectedRows.length === 0) {
      enqueueSnackbar('Selecione pelo menos uma requisição', {
        variant: 'warning'
      });
      return;
    }
    const selecteds = selectedRows.map((selected) => ({
      id: selected.id ?? 0,
      updated_at: selected.updatedAt ?? new Date()
    }));

    await desaprovarRequisicaoLote(selecteds).then(() => {
      filterRequisicoes();
    });
    // setFilter([]);
  }

  useEffect(() => {
    filterRequisicoes();
  }, [filterRequisicoes]);

  return (
    <Inner open={openDrawer}>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }} gap={1}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography fontWeight={'bold'}>Aprovação de Requisições</Typography>
        </Breadcrumbs>

        <Box display={'flex'} gap={2}>
          {/* <SelectFilter
            dataKey="classeId"
            value={filter ?? []}
            data={dataClasses
              .filter((c) => c?.ativo === 1)
              .map((el) => ({
                id: el.id,
                desc: el.descricao.toUpperCase()
              }))}
            onChange={(value) => {
              setFilter([...value.classeId]);
            }}
            caption="Selecionar Classes"
            width={200}
          /> */}

          {/* <SelectButtonSitacao
            situacoes={situacoes}
            setSituacoes={setSituacoes}
          /> */}
        </Box>
      </Box>
      {
        <Table
          count={data.length ?? 0}
          data={data ?? []}
          isLoading={
            loading || statusAprov.isLoading || statusDesaprov.isLoading
          }
          selectedRows={setSelectedRows}
          handlerAprovar={handlerAprovar}
          handlerDesaprovar={handlerDesaprovar}
          isAprovacao={situacoes[0]}
        />
      }
      <RightDrawer
        caption="Filtros"
        drawerWidth={drawerWidth}
        openDrawer={openDrawer}
        setOpen={setOpenDrawer}>
        {/* filtros */}
        <Box
          display="flex"
          flexDirection={'column'}
          gap={1}
          sx={{ overflow: 'hidden', overflowY: 'auto' }}>
          {/* filtro de data */}
          <PeriodFilter
            dataKey="created_at"
            value={filters.created_at ?? [null, null]}
            onChange={handlerFilter}
            caption="Período"
          />
          {/* filtro situacao */}
          {/* <SelectFilter
            dataKey="situacaoId"
            value={filters.situacaoId ?? []}
            data={[
              {
                id: 1,
                nome: 'Aprovação'
              },
              {
                id: 2,
                nome: 'Cotação'
              }
            ].map((el) => ({
              id: el.id,
              desc: el.nome.toUpperCase()
            }))}
            onChange={handlerFilter}
            caption="Status da Requisição"
            width={drawerWidth - 20}
          /> */}

          {/* filtro de classes */}
          <SelectFilter
            dataKey="classeId"
            value={filters.classeId ?? []}
            data={dataClasses
              .filter((c) => c?.ativo === 1)
              .map((el) => ({
                id: el.id,
                desc: el.descricao.toUpperCase()
              }))}
            onChange={handlerFilter}
            caption="Classes"
            width={drawerWidth - 20}
          />

          {/* filtro situacao */}
          <SelectButtonSitacao
            situacoes={situacoes}
            setSituacoes={(value) => {
              setSituacoes(value);
              if (value[0] && !value[1]) {
                handlerFilter({ situacaoId: [1] });
              }
              if (!value[0] && value[1]) {
                handlerFilter({ situacaoId: [2] });
              }
            }}
          />
        </Box>
        {/* ação de filtrar */}
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[50]
          })}
          py={1}
          display={'flex'}
          justifyContent={'space-between'}>
          {/* <Typography
            onClick={clearFilters}
            sx={(theme) => ({
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'underline'
              }
            })}
            variant="overline">
            Limpar Filtros
          </Typography> */}
          <Button
            fullWidth
            onClick={toFilter}
            variant="contained"
            color="primary"
            size={'small'}>
            <FilterAltOutlined amplitude={8} /> Filtrar
          </Button>
        </Box>
      </RightDrawer>
    </Inner>
  );
}
