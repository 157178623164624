import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCotacaoQuery } from '../apiSlice';
import { CotacaoFornecedor, CotacaoItem } from '../models';
import { Box, Button, Chip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsSidebarOpen,
  setCotacaoItensChanged
} from '../../../layout/layoutSlice';
import AppConfig from '../../../config/app';
import { ReplyAll } from '@mui/icons-material';
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt';
import DataGridItemsGerarPedido from './dataGridItensGerarPedido';
import {
  isPedidoGerado,
  isRequisicaoComPedido
} from './utils/funcoesTabResumo';

export default function TabPanelResumeListGerarPedido() {
  const drawerOpen = useSelector(selectIsSidebarOpen);
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ id: string; fornecedorId: string }>();

  const { id, fornecedorId } = params;
  const cotacaoId = id ?? '';

  const {
    data: cotacao,
    isLoading,
    isFetching
  } = useGetCotacaoQuery({ id: cotacaoId });

  const fornecedor = cotacao?.fornecedores.find(
    (fornecedor) => fornecedor.fornecedor.id === parseInt(fornecedorId ?? '0')
  );

  const goBack = () => {
    const { path, tab } = state as { path: string; tab: number };
    if (!path) {
      return;
    }
    const to = path + '?tab' + tab;

    navigate(to, { replace: true, state: { tab } });
  };

  // filtro para mostrar apenas os itens do fornecedor selecionado
  const filterItensVencedores = useCallback(
    (item: CotacaoItem) => {
      const i = parseInt(fornecedorId ?? '0');
      return item.fornecedor.id === i && item.situacao.id === 230;
    },
    [fornecedorId]
  );

  // filtro para mostrar apenas os itens do fornecedor selecionado
  const filterItensSelecionados = useCallback(
    (item: CotacaoItem) => {
      const i = parseInt(fornecedorId ?? '0');
      return (
        item.fornecedor.id === i &&
        item.isSelected &&
        // item.situacao.id === 220 &&
        isPedidoGerado(i, cotacao?.fornecedores ?? []) === false &&
        isRequisicaoComPedido(
          item?.requisicao?.id ?? 0,
          cotacao?.itens ?? []
        ) === false
      );
    },
    [cotacao?.fornecedores, cotacao?.itens, fornecedorId]
  );

  const items: CotacaoItem[] =
    cotacao?.itens.filter(
      fornecedor?.pedidoGerado ? filterItensVencedores : filterItensSelecionados
    ) ?? [];

  const loading = isLoading || isFetching;
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={(theme) => ({
        maxWidth: drawerOpen
          ? `calc(97.5vw - ${AppConfig.drawerWidth}px)`
          : `calc(97vw - ${theme.spacing(7)})`
      })}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          justifyContent: 'center'
        }}>
        <Typography variant="h6" component="span" sx={{ flexGrow: 1 }}>
          {fornecedor?.pedidoGerado ? 'Visualizar Pedido' : 'Gerar Pedido'}
          {': '}
          <Chip label={fornecedor?.fornecedor.nome} />
        </Typography>
        <Button
          type="button"
          onClick={goBack}
          startIcon={<ReplyAll />}
          variant="outlined">
          Voltar
        </Button>
      </Box>
      <Box>
        {fornecedor && (
          <DataGrid
            disabled={fornecedor?.pedidoGerado ?? false}
            loading={loading}
            items={items}
            cotacaoId={cotacao?.id ?? 0}
            updated_at={cotacao?.updatedAt ?? ''}
            cotacaoItems={cotacao?.itens ?? []}
            fornecedor={fornecedor}
          />
        )}
      </Box>
    </Box>
  );
}

const DataGrid = ({
  items,
  cotacaoId,
  updated_at,
  loading,
  cotacaoItems,
  disabled = false,
  fornecedor
}: {
  items: CotacaoItem[];
  cotacaoId: number;
  updated_at: string;
  loading: boolean;
  disabled: boolean;
  cotacaoItems: CotacaoItem[];
  fornecedor: CotacaoFornecedor;
}) => {
  const dispatch = useDispatch();
  // previne refresh da pagina sem estar salvo
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isBlocked] = useCallbackPrompt(showDialog);

  useEffect(() => {
    dispatch(setCotacaoItensChanged(showDialog));
  }, [showDialog, dispatch]);

  return (
    <>
      <DataGridItemsGerarPedido
        height="calc(100vh - 200px)"
        disabled={disabled}
        loading={loading}
        items={items}
        cotacaoId={cotacaoId}
        updated_at={updated_at}
        isChanged={(value) => setShowDialog(value)}
        fornecedor={fornecedor}
      />
      {/* <ComponentModal
        open={showPrompt as boolean}
        close={cancelNavigation as () => void}
        title={'Existem alterações não salvas.'}
        description={'Sair sem salvar?'}
        confirmActionTitle="Não"
        confirmAction={cancelNavigation as () => void}
        degreeActionTitle="Sim"
        degreeAction={confirmNavigation as () => void}
      /> */}
    </>
  );
};
