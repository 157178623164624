import { useState, useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import { CotacaoFornecedor, CotacaoItem, CotacaoRequisicao } from '../models';
import {
  CotacaoItemSelecionar,
  useCotacaoItemDesselecionarMutation,
  useCotacaoItemLoteSelecionarMutation,
  useCotacaoItemSelecionarMutation
} from '../apiSlice';
import { getItensPorMenorPreco, selectMenorPreco } from './cotacaoFunctions';
import { useSnackbar } from 'notistack';
import DataGridSelection from './dataGridSelection';
import { CustomNoTableRowsOverlay } from '../../../components/NoCustomRowOverlay';
import { exportToCsv, exportToPdf } from '../../../utils/exportUtils';
// import { exportToCsv } from '../../../utils/exportUtils';

interface Props {
  cotacaoId: number;
  cotacaoUpdateAt: string;
  value: number;
  index: number;
  requisicoes: CotacaoRequisicao[];
  fornecedores: CotacaoFornecedor[];
  situacao: number;
  items: CotacaoItem[];
}

export default function TabPanelSelection({
  cotacaoId,
  cotacaoUpdateAt,
  index,
  value,
  requisicoes,
  fornecedores,
  items,
  situacao
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [cotacaoItemSelecionar] = useCotacaoItemSelecionarMutation();
  const [cotacaoItemDeselecionar] = useCotacaoItemDesselecionarMutation();
  const [cotacaoItemLoteSelecionar] = useCotacaoItemLoteSelecionarMutation();

  const [data, setData] = useState<CotacaoItem[]>([]);

  const [loadingSelectionLote, setLoadingSelectionLote] = useState(false);

  // menor preco
  const [selectedMenorPreco, setSelectedMenorPreco] = useState<CotacaoItem[]>(
    []
  );

  // elemento grid para compor o export to csv e pdf
  const [grid, setGrid] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const { itens } = getItensPorMenorPreco(requisicoes, items);
    setSelectedMenorPreco(itens);
  }, [items, requisicoes]);

  const handleChangeItem = async (row: any) => {
    setLoadingSelectionLote(true);
    //     {
    //     "itemId": 246,
    //     "fornecedorId": 10243,
    //     "preco": 1.5,
    //     "precoComImposto": 1.5,
    //     "isSelected": false,
    //     "diferenca": 1.5,
    //     "percentual": 100,
    //     "fornecedor": "LAMINACAO VALE JAGUARIBE LTDA"
    // }
    const item = data.find((i) => i.id === row.itemId);
    if (!item) {
      enqueueSnackbar('Item não encontrado ou removido!', {
        variant: 'error'
      });
      setLoadingSelectionLote(false);
      return;
    }

    if (item.precoComImposto === 0 || item.qtde === 0) {
      enqueueSnackbar('Item sem preço ou quantidade!', {
        variant: 'error'
      });
      setLoadingSelectionLote(false);
      return;
    }

    const cotacaoItemId = item.id;
    const cotacaoItemUpdateAt = String(item.updatedAt);
    const requisicaoId = item?.requisicao?.id;

    if (!requisicaoId) {
      enqueueSnackbar('Item sem requisição!', {
        variant: 'error'
      });
      setLoadingSelectionLote(false);
      return;
    }

    const args: CotacaoItemSelecionar = {
      cotacaoId,
      updated_at: cotacaoUpdateAt,
      cotacaoItem: {
        cotacaoItemId: cotacaoItemId,
        requisicaoId: requisicaoId,
        updated_at: cotacaoItemUpdateAt
      }
    };

    await cotacaoItemSelecionar(args)
      .unwrap()
      .then((data) => {
        console.log('🚀 ~ file: index.tsx:126 ~ data', data);
      })
      .catch(({ data }: any) => {
        enqueueSnackbar(data.message ?? 'Erro ao selecionar item', {
          variant: 'error'
        });
      });

    setLoadingSelectionLote(false);
  };

  async function handleSelectItems() {
    setLoadingSelectionLote(true);
    const reqs = selectMenorPreco(requisicoes, items, fornecedores);
    const reduce = reqs.reduce((acc, item) => {
      if (item && item?.requisicao?.id) {
        acc.push({
          cotacaoItemId: item.id,
          requisicaoId: item.requisicao.id as number,
          updated_at: String(item.updatedAt)
        });
      }
      return acc;
    }, [] as CotacaoItemSelecionar['cotacaoItem'][]);

    await cotacaoItemLoteSelecionar({
      cotacaoId,
      updated_at: cotacaoUpdateAt,
      cotacaoItens: reduce
    });
    setLoadingSelectionLote(false);
  }

  function handleDeselectItem(row: any) {
    const item = data.find((i) => i.id === row.itemId);
    if (!item) {
      enqueueSnackbar('Item não encontrado ou removido!', {
        variant: 'error'
      });
      return;
    }
    const args = {
      cotacaoItemId: item.id,
      updated_at: String(item.updatedAt)
    };

    cotacaoItemDeselecionar(args)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Item desselecionado com sucesso!', {
          variant: 'success'
        });
      })
      .catch(({ data }: any) => {
        enqueueSnackbar(data.message ?? 'Erro ao desselecionar item', {
          variant: 'error'
        });
      });
  }

  async function choiceMenorPreco() {
    try {
      await handleSelectItems();
      enqueueSnackbar('Itens selecionados com sucesso!', {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar('Erro ao selecionar itens!', {
        variant: 'error'
      });
    }
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    // handleChangePage(value + 1);
  }

  function exportCsv() {
    if (grid) {
      exportToCsv(grid, 'cotacao-precos');
    }
  }

  function exportPDF() {
    if (grid) {
      exportToPdf(grid, 'cotacao-precos');
    }
  }

  useEffect(() => {
    const data = items.map((item) => ({ ...item, selected: false }));
    setData(data);
  }, [items]);

  const loading = loadingSelectionLote;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        p={1}
        overflow="hidden"
        gap={1}>
        {requisicoes && requisicoes.length > 0 ? (
          <Paper sx={{ height: `69vh`, overflow: 'hidden' }}>
            <DataGridSelection
              items={items}
              fornecedoresCotacao={fornecedores}
              requisicoes={requisicoes}
              actionSelectItem={handleChangeItem}
              actionDeselectItem={handleDeselectItem}
              loading={loading}
              exportGrid={(grid) => setGrid(grid ?? null)}
              height={61}
              exportCsv={exportCsv}
              exportPdf={exportPDF}
              choiceMenorPreco={choiceMenorPreco}
            />
          </Paper>
        ) : (
          <Paper sx={{ height: `60vh` }}>
            <CustomNoTableRowsOverlay />
          </Paper>
        )}
      </Box>
    </div>
  );
}
