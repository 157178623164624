import { MouseEvent, useState } from 'react';
import { Box, IconButton, Popover, Switch, Typography } from '@mui/material';
import { GridColumnIcon } from '@mui/x-data-grid';
import columns from './columns';
import { useEmpresa } from '../../../../hooks/useEmpresa';

interface PopoverColumnsProps {
  value: string[];
  onChange: (value: string[]) => void;
  activeColumns?: string[];
}

export default function PopoverColumns({
  value,
  onChange,
  activeColumns = []
}: PopoverColumnsProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { qtdeDigitosCampoQtde, qtdeDigitosCampoUnitário } = useEmpresa();
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (activeColumns.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        title="Exibir e ocultar colunas ">
        <GridColumnIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <Box
          maxHeight="300px"
          sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          {columns({
            qtdeDigts: qtdeDigitosCampoQtde,
            precoDigts: qtdeDigitosCampoUnitário
          }).map((col) => {
            if (col.name === '') return null;
            if (!activeColumns.includes(col.key)) return null;
            return (
              <Box
                display="inline-flex"
                justifyContent="space-between"
                key={col.key}>
                <Typography>{col.name}</Typography>
                <Switch
                  checked={value.includes(col.key)}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onChange([...value, col.key]);
                    } else {
                      onChange(value.filter((v) => v !== col.key));
                    }
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </Popover>
    </div>
  );
}
