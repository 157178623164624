import { Box, TextField, FormControl } from '@mui/material';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const StepDescricao = ({ value, onChange }: Props) => {
  return (
    <Box sx={{ height: '70vh', p: 2 }}>
      <FormControl fullWidth>
        <TextField
          fullWidth
          name="obs"
          label="Descrição"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value}
        />
      </FormControl>
    </Box>
  );
};

export default StepDescricao;
