import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { alpha } from '@mui/material';

enum EnumSituacao {
  Aprovacao = 0,
  Cotacao = 1
}

interface Props {
  situacoes: [boolean, boolean];
  setSituacoes: (value: [boolean, boolean]) => void;
}

export default function SelectButtonSitacao({
  situacoes,
  setSituacoes
}: Props) {
  function selectButtonSituacao(index: number) {
    const situacoesAux: [boolean, boolean] = [...situacoes];
    if (index === 0) {
      situacoesAux[0] = true;
      situacoesAux[1] = false;
    }

    if (index === 1) {
      situacoesAux[0] = false;
      situacoesAux[1] = true;
    }
    // situacoesAux[index] = !situacoesAux[index];
    setSituacoes(situacoesAux);
  }

  return (
    <ButtonGroup variant="contained">
      <Button
        startIcon={
          situacoes[EnumSituacao.Aprovacao] ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        sx={(theme) => ({
          fontSize: 13,
          backgroundColor: situacoes[EnumSituacao.Aprovacao]
            ? theme.palette.primary.main
            : theme.palette.grey[400],
          '&:hover': {
            //you want this to be the same as the backgroundColor above
            backgroundColor: situacoes[EnumSituacao.Aprovacao]
              ? alpha(theme.palette.primary.main, 0.8)
              : alpha(theme.palette.primary.main, 0.1),
            color: situacoes[EnumSituacao.Aprovacao]
              ? alpha(theme.palette.common.white, 1)
              : alpha(theme.palette.primary.main, 1)
          }
        })}
        onClick={() => {
          selectButtonSituacao(EnumSituacao.Aprovacao);
        }}>
        Aprovação
      </Button>
      <Button
        startIcon={
          situacoes[EnumSituacao.Cotacao] ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        sx={(theme) => ({
          fontSize: 13,
          backgroundColor: situacoes[EnumSituacao.Cotacao]
            ? theme.palette.primary.main
            : theme.palette.grey[400],
          '&:hover': {
            //you want this to be the same as the backgroundColor above
            backgroundColor: situacoes[EnumSituacao.Cotacao]
              ? alpha(theme.palette.primary.main, 0.8)
              : alpha(theme.palette.primary.main, 0.1),
            color: situacoes[EnumSituacao.Cotacao]
              ? alpha(theme.palette.common.white, 1)
              : alpha(theme.palette.primary.main, 1)
          }
        })}
        onClick={() => selectButtonSituacao(EnumSituacao.Cotacao)}>
        Cotação
      </Button>
    </ButtonGroup>
  );
}
