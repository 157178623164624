import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from './authSlice';
import { Typography, Box, CircularProgress } from '@mui/material';
import { EnumPermissao } from './models';
import { ReactComponent as ImgNotAuthorized } from '../../assets/svg/no-authorized.svg';

interface Props {
  children: JSX.Element;
  permissoes: string;
  feature: EnumPermissao;
}

const RequirePermissao = ({ children, permissoes, feature }: Props) => {
  const user = useSelector(selectCurrentUser);
  const [authorization, setAuthorization] = useState<boolean | null>(null);

  useEffect(() => {
    const permissoesSplit = permissoes.split('');
    permissoesSplit.forEach((permissao) => {
      const authorization =
        user.permissoes[feature]?.includes(permissao) ?? false;
      setAuthorization(authorization);
    });
  }, [feature, permissoes, user.permissoes]);

  if (authorization === null) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '80vh'
        }}>
        <CircularProgress />
      </Box>
    );
  }

  if (authorization) {
    return children;
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '80vh'
        }}>
        <ImgNotAuthorized height={300} />
        <Typography color={'red'} variant="h3">
          Acesso não permitido!
        </Typography>
      </Box>
    );
  }
};

export default RequirePermissao;
