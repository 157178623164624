import {
  Box,
  Modal,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import PasswordIcon from '@mui/icons-material/Password';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface Props {
  action: (newSenha: string) => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
};

export default function ModalAlrerarSenha({ action }: Props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [senha, setSenha] = useState('');
  const [senha2, setSenha2] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  function handleAction() {
    if (senha.length < 6) {
      setError(true);
      setHelperText('A senha deve ter no mínimo 6 caracteres');
      return;
    }

    if (!senha || !senha2) {
      setError(true);
      setHelperText('Preencha os campos');
      return;
    }

    if (senha !== senha2) {
      setError(true);
      setHelperText('As senhas não conferem');
      return;
    }
    action && action(senha);
    handleClose();
  }

  useEffect(() => {
    setError(false);
    setHelperText('');
    setSenha('');
    setSenha2('');
  }, [open]);

  return (
    <Box>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
        aria-label="password"
        title="Alterar Senha"
        startIcon={<PasswordIcon />}>
        Alterar Senha
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Grid item xs={12}>
            <FormControl fullWidth required error={error}>
              <InputLabel htmlFor="senha">Senha</InputLabel>
              <OutlinedInput
                autoFocus
                sx={{ backgroundColor: '#fff' }}
                label="Senha"
                inputProps={{ autoComplete: 'new-passord' }}
                onChange={(e) => setSenha(e.target.value)}
                value={senha}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth error={error}>
              <InputLabel htmlFor="senha">Confirmar Senha</InputLabel>
              <OutlinedInput
                sx={{ backgroundColor: '#fff' }}
                label="Confirmar Senha"
                inputProps={{ autoComplete: 'new-passord' }}
                onChange={(e) => setSenha2(e.target.value)}
                value={senha2}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {error && <Typography color="error">{helperText}</Typography>}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAction}
              fullWidth>
              Alterar Senha
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}
