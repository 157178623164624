import { RenderCellProps, RenderEditCellProps } from 'react-data-grid';
// import CurrencyInput from 'react-currency-input-field';
import IntlCurrencyInput, {
  CurrencyInputOnChangeValues
} from 'react-currency-input-field';

type TRow = Record<string, any>;

export const numberEditor = (
  props: RenderEditCellProps<TRow, unknown>,
  numDigits = 2
) => {
  return (
    <div className="rgd-cell">
      <input
        style={{
          width: '90%',
          height: '100%',
          border: 'none',
          outline: 'none',
          padding: '5px',
          textAlign: 'right',
          fontSize: '1rem',
          backgroundColor: '#ecf575'
        }}
        // defaultValue={Number(0).toLocaleString('pt-Br', {
        //   minimumFractionDigits: numDigits,
        //   maximumFractionDigits: numDigits
        // })}
        autoFocus
        onFocus={(e) => e.target.select()}
        type="number"
        min="0"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={props.row[`${props.column.key}` as keyof TRow]}
        onChange={(e) =>
          props.onRowChange({
            ...props.row,
            [`${props.column.key}` as keyof TRow]: isNaN(e.target.valueAsNumber)
              ? 0
              : e.target.valueAsNumber
          })
        }
        onKeyDown={(e) => {
          if (
            e.key === 'Tab' ||
            e.key === 'ArrowUp' ||
            e.key === 'ArrowDown' ||
            e.key === 'ArrowLeft' ||
            e.key === 'ArrowRight'
          ) {
            e.stopPropagation();
            e.preventDefault();
            props.onClose?.(true);
          }
        }}
      />
    </div>
  );
};

export const numberEditor2 = (
  props: RenderEditCellProps<TRow, unknown>,
  numDigits = 2
) => {
  const value = props.row[`${props.column.key}` as keyof TRow] ?? 0;

  const setValue = (value: string | undefined) => {
    if (!value) {
      props.onRowChange({
        ...props.row,
        [`${props.column.key}` as keyof TRow]: 0
      });
    }
    if (Number.isNaN(Number(value))) {
      props.onRowChange({
        ...props.row,
        [`${props.column.key}` as keyof TRow]: 0
      });
    }
    props.onRowChange({
      ...props.row,
      [`${props.column.key}` as keyof TRow]: value
    });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    // eslint-disable-next-line react/no-unknown-property

    <IntlCurrencyInput
      value={Number.isNaN(Number(value)) ? 0 : Number(value)}
      decimalSeparator=","
      groupSeparator="."
      onValueChange={(
        _value: string | undefined,
        _name?: string | undefined,
        values?: CurrencyInputOnChangeValues | undefined
      ) => {
        setValue(values?.float?.toFixed(numDigits));
      }}
      defaultValue={value}
      max={0}
      autoFocus
      onFocus={(e: any) => {
        e.target.select();
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      style={{
        width: '90%',
        height: '100%',
        border: 'none',
        outline: 'none',
        padding: '5px',
        textAlign: 'right',
        fontSize: '1rem',
        backgroundColor: '#ecf575'
      }}
    />
  );
};

// render text center
export const renderTextCenter = (props: RenderCellProps<TRow, unknown>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <center>{props.row[`${props.column.key}` as keyof TRow]}</center>;
};

// render currency
export const renderCurrency = (
  props: RenderCellProps<TRow, unknown>,
  backgroundColor = '#ffffff',
  numDigits = 2
) => {
  return (
    <div
      style={{
        textAlign: 'right',
        backgroundColor,
        padding: '0px 2px 0px 2px'
      }}>
      {props.row[`${props.column.key}` as keyof TRow]?.toLocaleString('pt-Br', {
        minimumFractionDigits: numDigits,
        maximumFractionDigits: numDigits
      }) ?? '0,000'}
    </div>
  );
};

// render number
export const renderNumber = (
  props: RenderCellProps<TRow, unknown>,
  backgroundColor = '#FFFFFF',
  numDigits = 2
) => {
  return (
    <div
      style={{
        textAlign: 'right',
        backgroundColor
      }}>
      {props.row[`${props.column.key}` as keyof TRow]?.toLocaleString('pt-Br', {
        minimumFractionDigits: numDigits > 2 ? numDigits : 0,
        maximumFractionDigits: numDigits
      })}
    </div>
  );
};

// render date
export const renderDate = (
  props: RenderCellProps<TRow, unknown>,
  backgroundColor = '#FFFFFF'
) => {
  if (!props.row[`${props.column.key}` as keyof TRow]) return null;
  return (
    <div
      style={{
        textAlign: 'center',
        backgroundColor
      }}>
      {new Date(
        props.row[`${props.column.key}` as keyof TRow].replace(/-/g, '/')
      )?.toLocaleDateString('pt-Br')}
    </div>
  );
};

// date Date() editor
export const dateEditor = (props: RenderEditCellProps<TRow, unknown>) => {
  return (
    <div className="rdg-cell">
      <input
        style={{
          width: '90%',
          height: '100%',
          border: 'none',
          outline: 'none',
          padding: '5px',
          textAlign: 'center',
          fontSize: '1rem',
          backgroundColor: '#ecf575'
        }}
        autoFocus
        onFocus={(e) => e.target.select()}
        type="date"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={props.row[`${props.column.key}` as keyof TRow] ?? ''}
        onChange={(e) =>
          props.onRowChange({
            ...props.row,
            [`${props.column.key}` as keyof TRow]: e.target.value
          })
        }
        onKeyDown={(e) => {
          if (
            e.key === 'Tab' ||
            e.key === 'ArrowUp' ||
            e.key === 'ArrowDown' ||
            e.key === 'ArrowLeft' ||
            e.key === 'ArrowRight'
          ) {
            e.stopPropagation();
            e.preventDefault();
            props.onClose?.(true);
          }
        }}
      />
    </div>
  );
};
