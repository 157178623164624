import { Fornecedor } from '../../fornecedor/models';
import { Box, Button, Stack } from '@mui/material';
import { Cotacao, CotacaoFornecedor, CotacaoRequisicao } from '../models';
import {
  useAddCotacaoFornecedorMutation,
  useCotacaoFornecedorUpdateMutation,
  useDeleteCotacaoFornecedorMutation,
  useEnviarEmailCotacaoMutation
} from '../apiSlice';
import SearchFornecedorModal from '../../fornecedor/searchFornecedorModal';
import { useSnackbar } from 'notistack';
import TableFornecedorExterno from '../common/tableFornecedorExterno';
import { Email } from '@mui/icons-material';
import DefinirValidadeModal from '../common/definirValidadeModal';
import { Empresa } from '../../empresa/models';
import { useAppSelector } from '../../../app/hooks';
import { selectEmpresa } from '../../empresa/storeSlice';
import { CotacaoFornecedorMap } from '.';
import moment from 'moment';

interface Props {
  value: number;
  index: number;
  situacao: number;
  cotacaoId: number;
  updated_at: string;
  data: CotacaoFornecedorMap[];
  loading?: boolean;
  requisicoes: CotacaoRequisicao[];
}

const pathLink = '/cotacao/externo/valida/';

export default function TabPanelFornecedoresExterno({
  value,
  index,
  cotacaoId,
  updated_at,
  situacao,
  data,
  requisicoes,
  loading: isLoading = false
}: Props) {
  const empresa: Empresa = useAppSelector(selectEmpresa);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteCotacaoFornecedor, deleteCotacaoFornecedorStatus] =
    useDeleteCotacaoFornecedorMutation();
  const [addCotacaoFornecedor, addCotacaoFornecedorStatus] =
    useAddCotacaoFornecedorMutation();

  const [cotacaoFornecedorUpdate, cotacaoFornecedorUpdateStatus] =
    useCotacaoFornecedorUpdateMutation();

  const [enviarEmailCotacao, enviarEmailCotacaoStatus] =
    useEnviarEmailCotacaoMutation();

  function mapFornecedoresToGridRows(
    data: CotacaoFornecedorMap[]
  ): Partial<CotacaoFornecedorMap | CotacaoFornecedor>[] {
    const result = data.map((item) => ({
      id: item.id,
      fornecedorId: item.fornecedor.id,
      nome: item.fornecedor.nome,
      apelido: item.fornecedor.apelido,
      cnpj: item.fornecedor.cnpj,
      cidade: item.fornecedor.cidade,
      uf: item.fornecedor.uf,
      email: item.email,
      validade: item.validade,
      link: pathLink + String(item.uuid),
      isAtivo: item.isAtivo,
      uuid: item.uuid,
      updatedAt: item.updatedAt,
      emailEnviado: item.emailEnviado,
      pedidoGerado: item.pedidoGerado,
      reqTotal: item.reqTotal,
      reqCotado: item.reqCotado,
      pedido: item.pedido
    }));

    return result;
  }

  function getPathLink(uuid: string) {
    return empresa.endereco + pathLink + uuid;
  }

  function defineValidade(validade: string) {
    const map = data.filter((item) => !item.pedidoGerado);

    map.forEach((item) => {
      cotacaoFornecedorUpdate({
        fornecedorId: item.id as number,
        email: item?.email ?? '',
        isAtivo: true,

        path: getPathLink(item.uuid),
        updated_at: item.updatedAt as unknown as string,
        validade: new Date(validade).toISOString()
      });
    });
  }

  async function enviarEmail(data: CotacaoFornecedor) {
    try {
      if (!data.uuid) {
        throw new Error(
          // eslint-disable-next-line prettier/prettier
          `O fornecedor: ${
            (data as any)?.nome || data.fornecedor?.nome
          } error ao enviar email`
        );
      }

      if (data.isAtivo === false) {
        throw new Error(
          // eslint-disable-next-line prettier/prettier
          `O fornecedor: ${
            (data as any)?.nome || data.fornecedor?.nome
          } não esta ativo`
        );
      }

      if (!data.email) {
        throw new Error(
          // eslint-disable-next-line prettier/prettier
          `O fornecedor: ${
            (data as any)?.nome || data.fornecedor?.nome
          } esta sem email cadastrado`
        );
      }

      return enviarEmailCotacao({
        fornecedorCotacaoId: data.id as unknown as string,
        updated_at: data.updatedAt as unknown as string
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar(`Email enviado para: ${data?.fornecedor?.nome}`, {
            variant: 'success'
          });
          // cotacaoFornecedorUpdate({
          //   fornecedorId: data.id as number,
          //   emailEnviado: true,
          //   path: data.path,
          //   updated_at: data.updatedAt as unknown as string,
          //   validade: data.validade,
          //   isAtivo: data.isAtivo
          // })
          //   .unwrap()
          //   .catch((err) => {
          //     enqueueSnackbar(err.data.message, { variant: 'error' });
          //   });
        })
        .catch((err) => {
          enqueueSnackbar(err.data.message + ' - ' + data.fornecedor?.nome, {
            variant: 'error'
          });
        });
    } catch (error: any) {
      enqueueSnackbar(error.toString(), { variant: 'error' });
    }
  }

  async function enviarEmails() {
    const map = data.filter((item) => !item.pedidoGerado);

    for await (const item of map) {
      await enviarEmail(item);
    }
  }

  async function addFornecedor(fornecedor: Fornecedor) {
    const id = fornecedor.id as number;
    // validade = hoje = 10 dias em iso string
    const defaultValidade = moment().add(10, 'days').toISOString();
    // console.log('🚀 ~ addFornecedor ~ defaultValidade:', defaultValidade);
    // selecionar fornecedor com maior de validade
    const fornecedorValidade = data.reduce(
      (prev, current) => (prev.validade > current.validade ? prev : current),
      // validade = hoje = 10 dias
      { validade: defaultValidade }
    ).validade as string;
    console.log('fornecedorValidade', fornecedorValidade);
    // quando a api estiver pronta, inserir a validade do fornecedor
    // aqui ....

    await addCotacaoFornecedor({
      cotacaoId,
      updated_at,
      fornecedorId: id,
      validade: fornecedorValidade,
      isAtivo: true
    })
      .unwrap()
      .then((res: any) => {
        const cotacao = res as Cotacao;
        if (!cotacao) return;
        const existenteFornecedor = cotacao.fornecedores.filter(
          (f) => f.id !== id
        )[0];

        const adicionadoFornecedor = cotacao.fornecedores.find((fornecedor) => {
          return fornecedor.fornecedor.id === id;
        });

        if (existenteFornecedor && adicionadoFornecedor) {
          cotacaoFornecedorUpdate({
            fornecedorId: adicionadoFornecedor.id as number,
            emailEnviado: false,
            path: getPathLink(adicionadoFornecedor.uuid),
            updated_at: adicionadoFornecedor.updatedAt as unknown as string,
            validade: existenteFornecedor.validade,
            isAtivo: true
          })
            .unwrap()
            .then(() => {
              enqueueSnackbar('Fornecedor adicionado', { variant: 'success' });
            })
            .catch((err: any) => {
              enqueueSnackbar(err.data.message, { variant: 'error' });
              return;
            });
        } else {
          enqueueSnackbar('Fornecedor adicionado', { variant: 'success' });
        }
      })
      .catch((err: any) => {
        enqueueSnackbar(err.data.message, { variant: 'error' });
        return;
      });
  }

  // verifica se pode adicionar fornecedor
  // so pode adicionar fornecedor se ainda tem requisicao sem pedido
  const notAddFornecedor = !requisicoes.some(
    (req) => !req.requisicao?.pedido?.id
  );

  const loading =
    isLoading ||
    addCotacaoFornecedorStatus.isLoading ||
    deleteCotacaoFornecedorStatus.isLoading ||
    cotacaoFornecedorUpdateStatus.isLoading ||
    enviarEmailCotacaoStatus.isLoading;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      <Box height={'60vh'} p={1}>
        <Stack justifyContent={'space-between'} direction={'row'} my={1}>
          <SearchFornecedorModal
            action={addFornecedor}
            disabled={situacao === 120 || notAddFornecedor}
          />
          <Stack direction={'row'} gap={1}>
            <DefinirValidadeModal
              action={defineValidade}
              disabled={situacao === 120}
            />
            <Button
              startIcon={<Email />}
              variant={'contained'}
              size={'small'}
              onClick={enviarEmails}>
              Enviar Emails
            </Button>
          </Stack>
        </Stack>
        {data && (
          <TableFornecedorExterno
            data={mapFornecedoresToGridRows(data as CotacaoFornecedorMap[])}
            editable={situacao !== 120}
            loading={loading}
            actionDelete={(id) => {
              deleteCotacaoFornecedor({
                cotacaoId,
                updated_at,
                fornecedorId: Number(id)
              })
                .unwrap()
                .then(() => {
                  enqueueSnackbar('Fornecedor removido', {
                    variant: 'success'
                  });
                })
                .catch((err) => {
                  enqueueSnackbar(err.data.message, {
                    variant: 'error'
                  });
                  return;
                });
            }}
            actionUpdate={(row) =>
              cotacaoFornecedorUpdate({
                fornecedorId: row.id as number,
                email: row.email as string,
                isAtivo: row.isAtivo as boolean,
                path: empresa.endereco + pathLink + String(row.uuid),
                updated_at: row.updatedAt as unknown as string,
                validade: row.validade as string,
                emailEnviado: false
              }).unwrap()
            }
            actionEnviarEmail={(row) => {
              enviarEmail(row as CotacaoFornecedor)
                .then((data: any) => {
                  if (data)
                    enqueueSnackbar(`Email enviado para ${data}`, {
                      variant: 'success'
                    });
                })
                .catch((error) => {
                  enqueueSnackbar(
                    error.message || 'Error ao enviar email para fornecedor',
                    { variant: 'error' }
                  );
                });
            }}
          />
        )}
      </Box>
    </div>
  );
}
