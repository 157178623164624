import { ExitToApp, Check } from '@mui/icons-material';
import { createElement, useEffect, useState } from 'react';
import {
  Box,
  Modal,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1
};

interface Dados {
  situacao?: number;
  previsaoEntrega?: Date;
}

interface SelectSituacaoModalProps {
  component: JSX.Element;
  data: Dados;
  action: (data: Dados) => void;
  situacoes: {
    id: number;
    desc: string;
    corFundo: string;
    corLetra: string;
  }[];
}

export default function SelectSituacaoModal({
  component,
  action,
  situacoes,
  data
}: SelectSituacaoModalProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = useState<number | undefined>();
  const [previsaoEntrega, setPrevisaoEntrega] = useState<Date | null>(null);

  const sendAction = () => {
    if (!selected) {
      //
    }
    action({ situacao: selected, previsaoEntrega: previsaoEntrega! });
    handleClose();
  };

  useEffect(() => {
    setSelected(data.situacao);
    setPrevisaoEntrega(data.previsaoEntrega ?? null);
  }, [data]);

  return (
    <Box>
      {createElement(component.type, {
        ...component.props,
        onClick: handleOpen
      })}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <LocalizationProvider
          localeText={{ clearButtonLabel: 'Limpar', todayButtonLabel: 'Hoje' }}
          dateAdapter={AdapterMoment}
          adapterLocale="pt-br">
          <Box sx={style}>
            <Typography
              bgcolor={'primary.main'}
              color={'primary.contrastText'}
              px={2}
              py={1}
              id="modal-modal-title"
              variant="h6"
              component="h4">
              {'Alterar Situação Pedido'}
            </Typography>
            <Box p={2} display={'flex'} flexDirection={'column'}>
              <Typography variant="body1" gutterBottom>
                Selecione a situação do pedido:
              </Typography>
              <Box p={2} display={'flex'} flexDirection={'column'}>
                {situacoes.map((situacao, idx) => (
                  <FormControlLabel
                    key={idx}
                    control={
                      <Checkbox
                        value={situacao.id}
                        checked={situacao.id === selected ?? 0}
                        onChange={(e) => {
                          setSelected(Number(e.target.value));
                        }}
                        size="medium"
                      />
                    }
                    label={situacao.desc}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        width: '100%',
                        px: 2,
                        py: 1,
                        color: situacao.corLetra,
                        bgcolor: situacao.corFundo,
                        borderRadius: 1
                      }
                    }}
                  />
                ))}
              </Box>
              <Typography variant="body1" gutterBottom>
                Previsão de Entrega:
              </Typography>
              <DatePicker
                value={previsaoEntrega}
                onChange={(date) => setPrevisaoEntrega(date)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
              {/* <DatePicker
              label="Inicial"
              openTo="day"
              onChange={(newValue: any) => {
                if (!newValue) {
                  const newPeriod = period.current;
                  newPeriod[0] = null;
                  period.current = newPeriod;
                  setUpdateData((prev) => prev + 1);
                  return;
                }
                if (newValue.format('YYYY-MM-DD') === 'Invalid date') return;
                if (newValue) {
                  const newPeriod = period.current;
                  newPeriod[0] = newValue.format('YYYY-MM-DD');
                  period.current = newPeriod;
                  return;
                }
              }}
              value={value && value[0] ? value[0] : null}
              renderInput={(params: any) => (
                <TextField {...params} size="small" />
              )}
              inputFormat="DD/MM/YYYY"
              componentsProps={{
                actionBar: {
                  actions: ['clear', 'today']
                }
              }}
              onAccept={() => {
                setUpdateData((prev) => prev + 1);
              }}
            /> */}
            </Box>
            <Box p={2} display={'flex'} justifyContent={'space-between'}>
              <Button
                startIcon={<ExitToApp />}
                variant="contained"
                color="inherit"
                onClick={() => {
                  setSelected(undefined);
                  handleClose();
                }}>
                Fechar
              </Button>
              <Button
                variant="contained"
                startIcon={<Check />}
                color="success"
                onClick={sendAction}>
                Confirmar
              </Button>
            </Box>
          </Box>
        </LocalizationProvider>
      </Modal>
    </Box>
  );
}
