import { update } from 'lodash';
import { boolean, object, string } from 'yup';

export const CATEGORIA_INITIAL_STATE: Categoria = {
  id: undefined,
  descricao: '',
  ativo: true,
  updated_at: undefined
};

export const formCategoriaValidationSchemaInsert = object({
  descricao: string().required('Campo Requerido'),
  ativo: boolean().required('Campo Requerido')
});

export const formCategoriaValidationSchemaEdit = object({
  descricao: string().required('Campo Requerido'),
  ativo: boolean().required('Campo Requerido'),
  updated_at: string()
});

export interface Categoria {
  id?: number;
  descricao: string;
  ativo: boolean;
  created_at?: Date;
  updated_at?: Date;
}
